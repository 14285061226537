import React, { useEffect ,useState } from 'react';
import { useParams, Redirect,withRouter  } from "react-router-dom";
import { getTanks } from "../actions/actions";

const RedirectDairiesPages = withRouter((props) => {
  const { dairyID } = useParams();
  const [tanks, setTanks] = useState([]);
  const [gettingData , gotData] = useState(false)
  useEffect(() => {
    getTanks(dairyID).then((data) => {
      setTanks(data.data);
      gotData(true)
    });
  }, []);
  return (
    <div>
      {gettingData ?
        (tanks.length > 0 && (
          <Redirect to={`${props.location.pathname}/tank/${tanks[0].id}`}>
          </Redirect>
        ) )||
        tanks.length === 0 && (
          <Redirect to={`${props.location.pathname}/tank`}>
          </Redirect>
        ) : null
      }
    </div>
  );
}
)

export default RedirectDairiesPages
