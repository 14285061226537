import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Icon = styled.svg`
  fill: none;
  stroke: ${(props) => (props.color ? props.color : "black")};
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 3px;
  border: ${(props) =>
    props.color ? `0.5px solid ${props.color}` : "0.5px solid black"};
  position: relative;
  top: 3px;

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    position: relative;
    top: -6px;
  }
`;

const Checkbox = ({ checked, color, ...props }) => (
  <div className="d-inline-block align-middle">
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} color={color}>
      <Icon viewBox="0 0 24 24" color={color}>
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </div>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  color: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
  className: "",
  checked: false,
};

export default Checkbox;
