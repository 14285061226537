import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { deleteDairy, getIndependentDairiesList } from "../../actions/actions";
import Card from "../../components/Card";
import Search from "../../components/Search";
import EditIndependentDairyForm from "forms/IndependentDairyForm/EditIndependentDairyForm";
import PromptModel from "components/PromptModel/PromptModel";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { useHistory } from 'react-router-dom';
import { useBreadcrumbContext } from '../../context/breadcrumbContext';
import CardViewLoader from "../../components/CardViewLoader";

const IndependentDairiesTab = memo(({ independentDairy, setIndependentDairy }) => {
  const { t } = useTranslation();
  /* set searchTerm data with useState */
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectId, setSelectedId] = useState(null);
  const [dairy, setDairy] = useState(null);
  const [display, setDisplay] = useState(false);
  const [independentDairyData, setIndependentDairyData] = useState([]);
  const history = useHistory();
  const { setBreadcrumbDairyFarm } = useBreadcrumbContext();
  const [cardViewLoader, setCardViewLoader] = useState(false);

  const IndependentDairyList = () => {
    setCardViewLoader(true);
    getIndependentDairiesList().then((response) => {
      setCardViewLoader(false);
      setIndependentDairy(response.data);
    });
  };
  useEffect(() => {
    IndependentDairyList();
  }, []);

  const deleteIndependentDairy = (id) => {
    deleteDairy(id)
      .then((response) => {
        if (response.status === 200) {
          setSelectedId(null);
          setOpen(false);
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("independent_dairy_deleted_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
          IndependentDairyList();
        }
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response?.message ?? t("something_went_wrong")}
          />
        );
      });
  };

  /** Added and update data after update state data */
  const updatedList = (item, action) => {
    if (action === "edit") {
      setIndependentDairy(
        independentDairy.map((i) => (i.id === item.id ? item : i))
      );
    }
  };

  const results = independentDairy?.filter((card) => {
    return card.name.toLowerCase().includes(searchTerm.toLowerCase());
  });
  return (
    <>
      <Row>
        <Col xs={12} lg={3} md={8} sm={7} className="mb-4">
          <Search
            placeholder={t("search")}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Col>
      </Row>
      {cardViewLoader ? (
        <CardViewLoader />
      ) : (
          results.length < 1 ? (
            <div className="text-center lead">{t("data_not_available")}</div>
          ) : (
              <Row>
                {results?.map((item, i) => {
                  return (
                    <Col lg={3} md={4} className="mb-4" key={i}>
                      <Card
                        key={item.id}
                        title={item.name}
                        subtitle={`${item.city}, ${item.state}`}
                        rightIcon={false}
                        onClick={() => {
                          history.push(`/regions/dairies/${item.id}/tank`);
                          setBreadcrumbDairyFarm(item.name);
                        }
                        }
                        // to={`/regions/dairies/${item.id}/tanks`}
                        deleteHandler={() => {
                          setSelectedId(item.id);
                          setOpen(true);
                        }}
                        editHandler={() => {
                          setDisplay(true);
                          setDairy(item.id);
                          setIndependentDairyData(item);
                        }}
                      >
                        {item.tanks
                          ? item.tanks.length === 1
                            ? item.tanks.length + " Tank"
                            : item.tanks.length === 0
                              ? "--"
                              : item.tanks.length + " Tanks"
                          : ""}
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            )
        )}

      {display && (
        <EditIndependentDairyForm
          showModel={display}
          closeModel={() => setDisplay(false)}
          dairyId={dairy}
          updatedList={updatedList}
          independentDairyData={independentDairyData}
        />
      )}
      <PromptModel
        open={open}
        closeAlert={() => {
          setOpen(false);
        }}
        modelContent={t("delete_record_msg")}
        callback={() => deleteIndependentDairy(selectId)}
      />
    </>
  );
});

export default IndependentDairiesTab;
