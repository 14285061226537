import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Tabs, Tab, Container } from "react-bootstrap";
import Breadcrumb from "../components/Breadcrumb";
// import { useParams } from "react-router-dom";
import admin from "../assets/images/user.svg";
import DealerTab from "../containers/Dealer/DealerTab";

const DealersPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Row className="mt-4 mb-3">
          <Col lg={12} className="pl-0">
            <Breadcrumb />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Tabs
              defaultActiveKey="dealers"
              className="main-tab text-capitalize mb-4"
            >
              <Tab eventKey="dealers" title={t("dealers")}>
                <DealerTab />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DealersPage;
