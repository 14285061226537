import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useApiContext } from "../../context/apiContext";
import * as yup from "yup";
import { addRegion, getRegionalManagers } from "../../actions/actions";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";

const AddRegionForm = memo(({ showModel, closeModel, updateList }) => {
  const { t } = useTranslation();
  const apiRegion = useApiContext();
  const schema = yup.object().shape({
    regionId: yup.string().required(t("region_id_required")),
    name: yup.string().required(t("region_name_error")),
    regionManager: yup.string().required(t("regional_managers_error")),
  });

  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
  });
  const [isRegionalManagerAssigned, setIsRegionalManagerAssigned] = useState(false)

  const postRegion = (data) => {
    const checkRegionalManagerAssigned = apiRegion.regionApi.filter((region) => (region.regionManager?.id === data.regionManager));
    if (checkRegionalManagerAssigned.length > 0) {
      setIsRegionalManagerAssigned(true);
      return;
    }
    setIsRegionalManagerAssigned(false);
    addRegion(data)
      .then((result) => {
        if (result.status === 200) {
          closeModel();
          updateList(result.data, "add");
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("region_added_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
        }
      })
      .catch((e) => {
        console.log(e.message);
        toast.dark(
          <Toaster
            icon="error"
            message={e?.result?.message ?? t("something_went_wrong")}
          />
        );
      });
  };
  // useEffect(() => {
  //   getRegionalManagers().then((response) => {
  //     setRegionalManagers(response.data);
  //   });
  // }, []);

  return (
    <Modal
      show={showModel}
      onHide={closeModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Form onSubmit={handleSubmit(postRegion)}>
        <Modal.Header className="bg-primary" closeButton>
          <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
            {t("region")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Row>
            <Col lg={6}>
              <Form.Group controlId="regionId">
                <Form.Label className="text-capitalize" size="lg">
                  {t("regionId")} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="regionId"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.regionId}
                />
                {errors.regionId && (
                  <Form.Control.Feedback type="invalid">
                    {errors.regionId.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="text-capitalize" size="lg">
                  {t("region_name")} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="name"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.name}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label className="text-capitalize">
                  {t("regional_managers")}{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="regionManager"
                  size="lg"
                  ref={register()}
                  isInvalid={!!errors.name}
                >
                  {apiRegion.regionalManager.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
                {errors.regionManager && (
                  <Form.Control.Feedback type="invalid">
                    {errors.regionManager.message}
                  </Form.Control.Feedback>
                )}
                {isRegionalManagerAssigned && (
                  <Row>
                    <Col>
                      <div className="text-danger mt-2">{t("Can't assign same regional manager to multiple region")}</div>
                    </Col>
                  </Row>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button
            variant="primary"
            type="submit"
            className="text-capitalize"
            size="lg"
          >
            {t("create")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

AddRegionForm.propTypes = {
  showModel: PropTypes.bool,
  closeModel: PropTypes.func.isRequired,
  updateList: PropTypes.any,
};
AddRegionForm.defaultProps = {
  showModel: false,
};

export default AddRegionForm;
