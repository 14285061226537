import React, { memo, useEffect, useState } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  getDairyList,
  updateDairy,
  getDairyAdmin,
} from "../../actions/actions";
import PropTypes from "prop-types";
import { useApiContext } from "../../context/apiContext";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";

const EditIndependentDairyForm = memo(
  ({ showModel, closeModel, dairyId, updatedList, independentDairyData }) => {
    const { t } = useTranslation();
    const apiRegion = useApiContext();
    const schema = yup.object().shape({
      name: yup.string().required(t("dairy_name_error")),
      city: yup.string().required(t("dairy_city")),
      state: yup.string().required(t("dairy_state")),
      address: yup.string().required(t("dairy_address")),
      createdBy: yup.string(),
      user: yup.string(),
    });
    const [dairy, setDairy] = useState(independentDairyData);
    const [dairyAdmin, setDairyAdmin] = useState([]);
    const [dealers, setDealers] = useState([]);
    const { register, handleSubmit, errors, reset } = useForm({
      mode: "onSubmit",
      validationSchema: schema,
    });

    useEffect(() => {
      if (dairy) {
        reset({
          name: dairy.name,
          city: dairy.city,
          state: dairy.state,
          address: dairy.address,
          createdBy: dairy?.createdBy?.id,
          user: dairy?.user?.id,
        });
      }
    }, [dairy]);

    const postDealerData = (data) => {
      if (data?.createdBy === "no-dealer") {
        data.createdBy = null;
      }
      if (data?.user === "no_dairy_admin") {
        data.user = null;
      }
      updateDairy(dairyId, data)
        .then((result) => {
          if (result.status === 200) {
            closeModel();
            updatedList(result.data, "edit");
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("independent_dairy_updated_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
          }
        })
        .catch((e) => {
          toast.dark(
            <Toaster
              icon="error"
              message={e?.result?.message ?? t("something_went_wrong")}
            />
          );
        });
    };

    useEffect(() => {
      if (apiRegion.dealerApi.length < 1) {
        getDairyList().then((response) => {
          setDealers(response.data);
          apiRegion.setDealerApi(response.data);
        });
      } else {
        setDealers(apiRegion.dealerApi);
      }
    }, []);

    useEffect(() => {
      if (apiRegion.dairyAdminApi.length < 1) {
        getDairyAdmin().then((response) => {
          setDairyAdmin(response.data);
          apiRegion.setDairyAdminApi(response.data);
        });
      } else {
        setDairyAdmin(apiRegion.dairyAdminApi);
      }
    }, []);

    return (
      <Modal
        show={showModel}
        onHide={closeModel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form onSubmit={handleSubmit(postDealerData)}>
          <Modal.Header className="bg-primary" closeButton>
            <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
              {t("independent_dairy")}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("dairy_name")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="name"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.name}
                  />
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("address")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="address"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.address}
                  />
                  {errors.address && (
                    <Form.Control.Feedback type="invalid">
                      {errors.address.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("city")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="city"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.city}
                  />
                  {errors.city && (
                    <Form.Control.Feedback type="invalid">
                      {errors.city.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("state")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="state"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.city}
                  />
                  {errors.state && (
                    <Form.Control.Feedback type="invalid">
                      {errors.state.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("dealer")}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="createdBy"
                    size="lg"
                    ref={register()}
                  >
                    <option value="no-dealer">No Dealer</option>
                    {dealers.map((item) => (
                      <option
                        value={item.id}
                        defaultValue={dairy?.createdBy?.id === item.id}
                        key={item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("dairy_admin")}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="user"
                    size="lg"
                    ref={register()}
                  >
                    <option value="no_dairy_admin">No Dairy admin</option>
                    {dairyAdmin.map((item) => (
                      <option
                        value={item.id}
                        defaultValue={dairy?.user?.id === item.id}
                        key={item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="pt-0">
            <Button
              variant="primary"
              type="submit"
              className="text-capitalize"
              size="lg"
            >
              {t("update")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
);

EditIndependentDairyForm.propTypes = {
  showModel: PropTypes.bool,
  closeModel: PropTypes.func.isRequired,
  dairyId: PropTypes.string.isRequired,
  updatedList: PropTypes.any,
};

EditIndependentDairyForm.defaultProps = {
  showModel: false,
};

export default EditIndependentDairyForm;
