import React, { memo } from "react";
import { Row, Col } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const tmparray = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
const RawRegisterLoader = memo(() => {
  return (<SkeletonTheme color="#f3f3f3" highlightColor="#ecebeb">
    <Row className="m-2">
      {tmparray.map(element => {
        return (
          <>
            <Skeleton count={1} height={50} width={75} style={{ marginRight: "5px" }} />
          </>
        )
      })}
    </Row>
  </SkeletonTheme >
  );
});

export default RawRegisterLoader;