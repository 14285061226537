import React, { memo, useEffect, useState } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CountryPhoneInput from "../../components/CountryPhoneInput";
import {
  addRegionalManagers as addFarmUsers,
  getUser,
  checkIfUserExistsByEmail,
  addAlreadyRegisteredFarmUser,
} from "../../actions/actions";
import { farmUsersRole } from "../../actions/passwordRegex";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";

const AddFarmUserForm = memo(({ showModel, closeModel, updateList, hasDairyAdmin }) => {
  const { regionID, dairyID, dealerID } = useParams();

  const { t } = useTranslation();
  const user = getUser();
  const [contact, setContact] = useState("");
  const [selectedContact, setSelectedContact] = useState("");

  const farmSchema = yup.object().shape({
    role: yup.string(),
    name: yup.string().required(t("name_error")),
    email: yup.string().max(50).email(t('invalid_email_error')).required(t("email_error")),
    contact: yup.lazy((value) => {
      if (
        value &&
        Object.values(value).some(
          (v) => !(v === null || v === undefined || v === "")
        )
      ) {
        return yup.string().min(10, t("contact_digit"));
      }
      return yup.mixed().notRequired();
    }),
  });
  const milkSchema = yup.object().shape({
    role: yup.string(),
    email: yup.string().max(50).email(t('invalid_email_error')).required(t("email_error")),
  });
  const [schema, setSchema] = useState(farmSchema);
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onSubmit",
    validationSchema: schema,
  });

  const [showUser, setShowUser] = useState(true);
  const [registered, setRegistered] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [farmUsersRoles, setFarmUsersRoles] = useState(farmUsersRole)

  const onChanged = (e) => {
    setErrorMessage(false);
    const { value } = e.target;
    if (value === "MilkHauler" || value === "Sanitarian") {
      setShowUser(false);
      setSchema(milkSchema);
    } else {
      setShowUser(true);
      setSchema(farmSchema);
      setDisabledButton(false);
    }
  };

  useEffect(() => {
    // Filter farmUsersRole and remove dairy admin option if logged in user is Dairy Operator
    if (user.role === 'DairyOperator') {
      const result = farmUsersRole.filter((userRole) => {
        return userRole.value !== "DairyAdmin"
      })
      setFarmUsersRoles(result);
    }
  }, []);

  const postFarmUsers = (user) => {
    if (
      (user.role === "Sanitarian" && !user.name) ||
      (user.role === "MilkHauler" && !user.name)
    ) {
      checkIfUserExistsByEmail(user.email)
        .then((response) => {
          setRegistered(response.data[0]?.id);
          if (response.data.length === 0) {
            setErrorMessage(false);
            setShowUser(true);
            setSchema(farmSchema);
          } else {
            if (response.data[0].role === user.role) {
              setErrorMessage(t("user_create_different_dairy_to_assign"));
              setHideButton(true);
              setDisabledButton(false);
            } else {
              setErrorMessage(t("user_create_different_role_error"));
              setDisabledButton(false);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      if (hasDairyAdmin && user.role === "DairyAdmin") {
        toast.dark(
          <Toaster icon="error" message={t("dairy_admin_exists")} />
        );
        return;
      }
      let changedContact = selectedContact.trim();
      user.contact = changedContact === "" ? changedContact : contact;
      addFarmUsers({
        ...user,
        createdBy: dealerID,
        region: regionID,
        farmUserOf: dairyID,
      })
        .then((result) => {
          if (result.status === 200) {
            closeModel();
            setErrorMessage(null);
            setShowUser(true);
            updateList(result.data, "add");
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("farm_users_added_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.status === 400 &&
            err.response.data &&
            err.response.data.code === "E_UNIQUE"
          ) {
            toast.dark(
              <Toaster icon="error" message={t("email_already_exists")} />
            );
          } else {
            toast.dark(
              <Toaster icon="error" message={t("something_went_wrong")} />
            );
          }
        });
    }
  };

  const alreadyRegisteredUser = () => {
    addAlreadyRegisteredFarmUser(dairyID, registered)
      .then((response) => {
        setErrorMessage(false);
        if (response.status === 200) {
          closeModel();
          setShowUser(true);
          updateList(response.data, "add");
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("farm_users_added_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Modal
      show={showModel}
      onHide={() => {
        closeModel();
        setShowUser(true);
        setHideButton(false);
        setDisabledButton(true);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Form onSubmit={handleSubmit(postFarmUsers)}>
        <Modal.Header className="bg-primary" closeButton>
          <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
            {t("farm_user")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Row>
            <Col lg={12}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label size="lg">{t("user_role")}</Form.Label>
                <Form.Control
                  as="select"
                  name="role"
                  size="lg"
                  ref={register()}
                  onChange={onChanged}
                >
                  {farmUsersRoles.map((item) => (
                    <option value={item.value} key={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
                {errors.region && (
                  <Form.Control.Feedback type="invalid">
                    {errors.region.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>

            <Col lg={12}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-capitalize" size="lg">
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="email"
                  size="lg"
                  type="email"
                  ref={register()}
                  isInvalid={!!errors.email}
                />
                {errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            {showUser && (
              <>
                <Col lg={6}>
                  <Form.Group controlId="formBasicName">
                    <Form.Label className="text-capitalize" size="lg">
                      {t("name")} <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      name="name"
                      size="lg"
                      type="text"
                      ref={register()}
                      isInvalid={!!errors.name}
                    />
                    {errors.name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.name.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="formBasicPhone">
                    <Form.Label className="text-capitalize" size="lg">
                      {t("phone_number")}
                    </Form.Label>

                    <CountryPhoneInput
                      onChangeVal={(val, country, e, formattedValue) => {
                        let contactNumber =
                          formattedValue &&
                          formattedValue.slice(country.dialCode.length + 1);
                        setContact(formattedValue);
                        setSelectedContact(contactNumber);
                        let formContactVal =
                          val &&
                          val
                            .match(/\d/g)
                            .join("")
                            .slice(country.dialCode.length);
                        setValue("contact", formContactVal, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }}
                      phoneInputValue={contact}
                    />
                    <Form.Control
                      name="contact"
                      size="lg"
                      type="text"
                      ref={register()}
                      isInvalid={!!errors.contact}
                      className="d-none"
                    />
                  </Form.Group>
                  {errors.contact && (
                    <div className="custom-invalid-feedback text-danger">
                      {errors.contact.message}
                    </div>
                  )}
                </Col>
              </>
            )}
          </Row>

          <Row>
            <Col>
              <div className="text-danger">{!showUser && errorMessage}</div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          {hideButton !== true || disabledButton ? (
            <Button
              variant="primary"
              type="submit"
              className="text-capitalize model-button-primary"
              size="lg"
              disabled={disabledButton}
            >
              {t("create")}
            </Button>
          ) : (
              <>
                <Button
                  variant="bg-none"
                  className="border-dark text-dark-gray"
                  size="lg"
                  onClick={() => {
                    closeModel();
                    setShowUser([]);
                    setErrorMessage(null);
                    setHideButton(false);
                    setDisabledButton(true);
                  }}
                >
                  {t("close")}
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  className="text-capitalize"
                  size="lg"
                  onClick={() => {
                    alreadyRegisteredUser();
                    setHideButton(false);
                    setDisabledButton(true);
                  }}
                >
                  {t("assign")}
                </Button>
              </>
            )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

AddFarmUserForm.propTypes = {
  showModel: PropTypes.bool,
  closeModel: PropTypes.func.isRequired,
  updateList: PropTypes.any,
};

AddFarmUserForm.defaultProps = {
  showModel: false,
};
export default AddFarmUserForm;
