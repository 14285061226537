import React, { memo, useEffect, useState } from "react";
import { Row, Col, Button, Form, Modal, Badge, Image } from "react-bootstrap";
import _ from "lodash";

import { useTranslation } from "react-i18next";
import CountryPhoneInput from "../../components/CountryPhoneInput";
import { updateAlert, getUser } from "../../actions/actions";
import {
  tankStateArr,
  conditionArr,
  getAlertValueBasedOnUnitPreference,
} from "../../actions/passwordRegex";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Close from "../../assets/images/close_Icon.svg";

import * as yup from "yup";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";

const EditAlertForm = memo(
  ({ showModel, closeModel, alertData: user, updateList, sensorData, tankData, tankStates }) => {
    const { t } = useTranslation();
    const { dairyID } = useParams();
    const [contact, setContact] = useState(null);
    const [emailList, setEmailList] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [config, setConfig] = useState([user.config]);
    const init = {
      button: "ADD",
      error: false,
      index: false,
      value: "",
    };
    const initContact = {
      button: "ADD",
      error: false,
      index: false,
      value: "",
    };

    const [emailAction, setEmailAction] = useState(init);
    const [contactAction, setContactAction] = useState(initContact);

    const schema = yup.object().shape({
      tank: yup.string(),
      config: yup.string(),
      tankState: yup.string(),
      condition: yup.string(),
      delay: yup.number().typeError(t("delay_value_error")),
      duration: yup.number().typeError(t("duration_value_error")),
      value: yup.number(),
    });

    const { register, handleSubmit, errors, reset } = useForm({
      validationSchema: schema,
    });
    useEffect(() => {
      if (user) {
        reset({
          tank: user?.tank?.id,
          config: user?.config?.id,
          tankState: user.tankState,
          condition: user.condition,
          duration: user.duration,
          delay: user.delay,
          contactNumberList: user?.contactNumberList,
          value: getAlertValueBasedOnUnitPreference(
            user?.value,
            user?.config.unit,
            "value"
          ),
          specialInstruction: user?.specialInstruction
        });
        setContactList(user?.contactNumberList ?? []);
        setEmailList(user?.mailingList ? user?.mailingList.split(",") : "");
      }
    }, [user]);



    /** Add and delete email functions */

    const handleChange = (e) => {
      setEmailAction({ ...emailAction, value: e.target.value, error: false });
    };

    const addEmailList = () => {
      let value = emailAction.value.trim();
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailAction.value === "") {
        return setEmailAction({ ...emailAction, error: t("email_error") });
      }
      let item = [...emailList];
      if (item.length >= 10) {
        return setEmailAction({
          ...emailAction,
          error: t("maximum_email"),
        });
      } else if (!re.test(String(emailAction.value).toLowerCase())) {
        return setEmailAction({ ...emailAction, error: t("invalid_email") });
      } else if (item.includes(emailAction.value)) {
        setEmailAction({
          ...emailAction,
          error: t("duplicate_email"),
        });
      } else if (emailAction.value !== "") {
        setEmailList([...emailList, value]);
        setEmailAction(init);
      }
    };

    const onKeyPressInputMail = (e) => {
      if (["Enter", "Tab", ","].includes(e.key)) {
        e.preventDefault();
        addEmailList();
      }
    };

    const updateEmailList = () => {
      let value = emailAction.value.trim();
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let item = [...emailList];
      if (emailAction.value === "") {
        setEmailAction({ ...emailAction, error: t("email_error") });
      } else if (item.length >= 10) {
        setEmailAction({
          ...emailAction,
          error: t("maximum_email"),
        });
      } else if (!re.test(String(emailAction.value).toLowerCase())) {
        setEmailAction({ ...emailAction, error: t("invalid_email") });
      } else if (item) {
        delete item[emailAction.index];
        if (!item.includes(emailAction.value)) {
          let tempItems = [...emailList];
          tempItems[emailAction.index] = emailAction.value;
          setEmailList(tempItems);
          setEmailAction(init);
        } else {
          setEmailAction({
            ...emailAction,
            error: t("duplicate_email"),
          });
        }
      } else if (item !== "") {
        let tempItems = [...emailList];
        tempItems[emailAction.index] = value;
        setEmailList(tempItems);
        setEmailAction(init);
      }
    };

    const handleDelete = (item, type) => {
      if (type === "EMAIL") {
        let tempItems = [...emailList];
        setEmailList(tempItems.filter((val) => val !== item));
        setEmailAction(init);
      } else {
        let items = [...contactList];
        setContactList(items.filter((val) => val !== item));
        setContactAction(initContact);
      }
    };

    const handleUpdate = (item, i, type) => {
      if (item && type === "EMAIL") {
        setEmailAction({
          ...emailAction,
          index: i,
          value: item,
          button: "EDIT",
        });
      } else {
        setContact(item);
        setContactAction({
          ...contactAction,
          index: i,
          value: item,
          button: "EDIT",
        });
      }
    };

    /** Add and delete Delete functions */

    const addContactNumber = () => {
      let contactItem = [...contactList];
      if (contact === null) {
        return setContactAction({
          ...contactAction,
          error: t("contact_number_error"),
        });
      } else if (contact?.match(/\d/g)?.length <= 10) {
        return setContactAction({
          ...contactAction,
          error: t("contact_digit"),
        });
      } else if (contactItem.length >= 10) {
        return setContactAction({
          ...contactAction,
          error: t("maximum_contact_number"),
        });
      } else if (contactItem.includes(contact)) {
        return setContactAction({
          ...contactAction,
          error: t("duplicate_contact_number"),
        });
      } else if (contact !== "") {
        setContactList([...contactList, contact]);
        setContact(null);
        setContactAction(initContact);
      }
    };

    const onKeyPressInputContact = (e) => {
      if (["Enter", "Tab"].includes(e.key)) {
        e.preventDefault();
        addContactNumber();
      }
    };

    const updateContactNumber = () => {
      let contactItem = [...contactList];
      if (contact === null) {
        setContactAction({
          ...contactAction,
          error: t("contact_number_error"),
        });
      } else if (contact?.match(/\d/g)?.length <= 10) {
        setContactAction({
          ...contactAction,
          error: t("contact_digit"),
        });
      } else if (contactItem.length >= 10) {
        setContactAction({
          ...contactAction,
          error: t("maximum_contact_number"),
        });
      } else if (contactItem) {
        delete contactItem[contactAction.index];
        if (!contactItem.includes(contact)) {
          let tempItems = [...contactList];
          tempItems[contactAction.index] = contact;
          setContactList(tempItems);
          setContactAction(init);
          setContact(null);
        } else {
          setContactAction({
            ...contactAction,
            error: t("duplicate_contact_number"),
          });
        }
      } else if (contact !== "") {
        let tempItems = [...contactList];
        tempItems[contactAction.index] = contact;
        setContactList(tempItems);
        setContactAction(initContact);
        setContact(null);
      }
    };

    const getAlertValueBasedOnDefaultUnits = (value, config) => {
      const { temperatureUnit, volumeUnit, pressureUnit } = getUser();
      if (config.key.includes("TEMP")) {
        switch (temperatureUnit) {
          case "C":
            value = (value * 9) / 5 + 32;
            break;
          case "F":
            value = value;
            break;
          case "K":
            value = ((value - 273.15) * 9) / 5 + 32;
            break;
        }
      } else if (config.key === "HPF_INPUT_REG_VOLUME_4TO20MA_READING") {
        switch (volumeUnit) {
          case "L":
            value = value / 3.78;
            break;
          case "gal":
            value = value;
            break;
          case "lb":
            value = value / 8.6;
            break;
          case "kg":
            value = value / 8.6 / 0.45;
            break;
        }
      } else if (config.key.includes("PRESSURE")) {
        switch (pressureUnit) {
          case "psig":
            value = value;
            break;
          case "bar":
            value = value / 0.069;
            break;
          case "MPa":
            value = value / 0.0069;
            break;
        }
      }

      return Number(value);
    };

    useEffect(() => {
      if (emailList.length === 0 && contactList.length < 1) {
        setEmailAction({
          ...emailAction,
          error: t("config_alert"),
        });
      } else {
        setEmailAction({ ...emailAction, error: false });
      }
      if (Object.keys(errors).length > 0) {
        if (emailList.length < 1 || contactList.length < 1) {
          setEmailAction({
            ...emailAction,
            error: t("config_alert"),
          });
        } else {
          setEmailAction({ ...emailAction, error: false });
        }
      }
    }, [errors, emailList, contactList]);

    const postAlert = (data) => {
      if (emailList !== "") {
        if (emailList.length === 1) {
          data.mailingList = emailList.toString();
        } else {
          data.mailingList = emailList.join(",");
        }
      }
      data.value = getAlertValueBasedOnDefaultUnits(
        data.value,
        _.find(sensorData, { id: config[0].id })
      );
      data.contactNumberList = contactList;
      updateAlert(user.id, data)
        .then((result) => {
          if (result.status === 200) {
            closeModel();
            updateList(result.data, "edit");
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("alert_added_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
          }
        })
        .catch((e) => {
          toast.dark(
            <Toaster
              icon="error"
              message={e?.result?.message ?? t("something_went_wrong")}
            />
          );
        });
    };

    return (
      <Modal
        show={showModel}
        onHide={closeModel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form onSubmit={handleSubmit(postAlert)}>
          <Modal.Header className="bg-primary" closeButton>
            <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
              {t("alert")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <Row>
              <Col lg={12}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("tank")}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="tank"
                    size="lg"
                    ref={register()}
                    disabled
                  >
                    {tankData.map((item) => (
                      <option
                        value={item.id}
                        key={item.id}
                        selected={user?.tank?.id === item.id}
                      >
                        {item.tankName}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.tank && (
                    <Form.Control.Feedback type="invalid">
                      {errors.tank.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("sensor_name")}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="config"
                    size="lg"
                    disabled
                    ref={register()}
                  >
                    {sensorData.map((item) => (
                      <option
                        value={item.id}
                        key={item.id}
                        defaultValue={item.id === user?.config?.id}
                      >
                        {item.displayName}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.config && (
                    <Form.Control.Feedback type="invalid">
                      {errors.config.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("tank_state")}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="tankState"
                    size="lg"
                    ref={register()}
                  >
                    {Object.keys(tankStates).map((tankStateVal, index) => {
                      return (
                        <option
                          value={tankStateVal}
                          key={index}
                          defaultValue={
                            tankStates[tankStateVal] === user.tankState
                          }
                        >
                          {tankStates[tankStateVal]}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {errors.tankState && (
                    <Form.Control.Feedback type="invalid">
                      {errors.tankState.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("condition")}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="condition"
                    size="lg"
                    ref={register()}
                  >
                    {Object.keys(conditionArr).map((conditionVal, index) => {
                      return (
                        <option
                          value={conditionVal}
                          key={index}
                          defaultValue={
                            conditionArr[conditionVal] === user.condition
                          }
                        >
                          {conditionArr[conditionVal]}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {errors.condition && (
                    <Form.Control.Feedback type="invalid">
                      {errors.condition.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("sensor_value")} (
                    {getAlertValueBasedOnUnitPreference(
                      null,
                      _.find(sensorData, {
                        id: user?.config.id,
                      })["unit"],
                      "unit"
                    )}
                    {""} )<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="value"
                    size="lg"
                    type="number"
                    ref={register()}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicDuration">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("duration")} ({t("minutes")}){" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="duration"
                    size="lg"
                    type="number"
                    ref={register()}
                    isInvalid={!!errors.duration}
                  />
                  {errors.duration && (
                    <Form.Control.Feedback type="invalid">
                      {errors.duration.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicDelay">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("delay")} ({t("minutes")}){" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="delay"
                    size="lg"
                    type="number"
                    ref={register()}
                    defaultValue="0"
                    isInvalid={!!errors.delay}
                  />
                  {errors.delay && (
                    <Form.Control.Feedback type="invalid">
                      {errors.delay.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={6}>
                <Form.Group controlId="formBasicPhone">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("phone_number")}
                  </Form.Label>
                  <CountryPhoneInput
                    onChangeVal={(val, country, e, formattedValue) =>
                      setContact(formattedValue)
                    }
                    phoneInputValue={contact}
                    inputRef={register}
                    onKeyDown={onKeyPressInputContact}
                  />
                </Form.Group>
                {contactAction.error && (
                  <div className="custom-invalid-feedback text-danger">
                    {contactAction.error}
                  </div>
                )}
              </Col>

              <Col lg={6}>
                {contactAction.button === "ADD" ? (
                  <Button
                    variant="primary"
                    type="button"
                    className="text-capitalize email-button"
                    onClick={addContactNumber}
                  >
                    {t("add")}
                  </Button>
                ) : (
                    <Button
                      variant="primary"
                      type="button"
                      className="text-capitalize email-button"
                      onClick={updateContactNumber}
                    >
                      {t("update_form_button")}
                    </Button>
                  )}
              </Col>
              <Col lg={12}>
                {contactList.length > 0 && (
                  <div className="border p-2 rounded mb-3">
                    {contactList.map((option, i) => {
                      return (
                        <div
                          className="badge-bg-light m-1 p-2 badge badge-pill badge-light global-badge"
                          style={{ cursor: "pointer" }}
                          key={i}
                        >
                          <Badge
                            key={option}
                            variant="none"
                            className="global-badge"
                            onClick={() => handleUpdate(option, i, "CONTACT")}
                          >
                            {option}
                          </Badge>
                          <Image
                            src={Close}
                            className="ml-2"
                            onClick={() => handleDelete(option, "CONTACT")}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("email")}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="mailingList"
                    size="lg"
                    onChange={handleChange}
                    value={emailAction.value}
                    ref={register()}
                    isInvalid={emailAction.error}
                    onKeyPress={onKeyPressInputMail}
                  />
                  {emailAction.error && (
                    <Form.Control.Feedback type="invalid">
                      {emailAction.error}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                {emailAction.button === "ADD" ? (
                  <Button
                    variant="primary"
                    type="button"
                    className="text-capitalize email-button"
                    onClick={addEmailList}
                  >
                    {t("add")}
                  </Button>
                ) : (
                    <Button
                      variant="primary"
                      type="button"
                      className="text-capitalize email-button"
                      onClick={updateEmailList}
                    >
                      {t("update_form_button")}
                    </Button>
                  )}
              </Col>
              <Col lg={12}>
                {emailList.length > 0 && (
                  <div className="border p-2 rounded mb-3">
                    {emailList.map((option, i) => {
                      return (
                        <div
                          className="badge-bg-light m-1 p-2 badge badge-pill badge-light global-badge"
                          style={{ cursor: "pointer" }}
                          key={i}
                        >
                          <Badge
                            variant="none"
                            className="global-badge"
                            key={option}
                            onClick={() => handleUpdate(option, i, "EMAIL")}
                          >
                            {option}
                          </Badge>
                          <Image
                            src={Close}
                            className="ml-2"
                            onClick={() => handleDelete(option, "EMAIL")}
                          />
                        </div>
                      );
                    })}{" "}
                  </div>
                )}
              </Col>
              <Col lg={12}>
                <Form.Group controlId="formBasicDelay">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("special_instruction")}
                  </Form.Label>
                  <Form.Control
                    name="specialInstruction"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.specialInstruction}
                  />
                  {errors.delay && (
                    <Form.Control.Feedback type="invalid">
                      {errors.specialInstruction.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="pt-0">
            <Button
              variant="primary"
              type="submit"
              className="text-capitalize"
              size="lg"
            >
              {t("create")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
);

export default EditAlertForm;
