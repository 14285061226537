/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import timezoneList from "timezones.json";
import downCaret from "../../assets/images/date-box-caret.svg";
import pauseIcon from "../../assets/images/pause-icon.svg";
import moment from "moment-timezone";

const DropdownWrapper = styled.div`
  width: ${({ time }) => (time ? "200px" : "initial")};
  height: 36px;
  padding: 0;
  border-radius: 4px;
  border: solid 1px #adcdff;
  display: inline-block;
  align-self: center;
  position: relative;
  line-height: 36px;
  margin-right: ${({ pause }) => (pause ? "10px" : "0")};
  @media screen and (max-width: 767px) {
    display: none;
  }
  &:hover {
    background: #f0f6ff;
  }
`;

const TitleDiv = styled.div`
  text-align: center;
  padding: ${({ pause }) => (pause ? "0 10px" : "0 5px")};
  cursor: pointer;
  img.caret {
    margin-left: 10px;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    color: #2c61a5;
  }
`;

const DropContainer = styled.div`
  position: absolute;
  top: 40px;
  display: ${({ show }) => (show ? "initial" : "none")};
  span {
    display: block;
    font-size: 14px;
    color: #212f46;
    cursor: pointer;
    padding: 5px 15px;

    &:hover {
      background: #f0f6ff;
    }
  }
  width: ${({ pause }) => (pause ? "80px" : "350px")};
  z-index: 1;
  background: white;
  height: ${({ pause }) => (pause ? "initial" : "170px")};
  overflow-y: scroll;
  border-radius: 4px;
  box-shadow: 4px 3px 12px 0 rgba(0, 0, 0, 0.08);
  left: ${({ pause }) => (pause ? "0px" : "-153px")};
`;

const graphReloadIntervals = [
  {
    title: "None",
    value: null,
  },
  {
    title: "10 sec.",
    value: 10,
  },
  {
    title: "1 min.",
    value: 60,
  },
  {
    title: "5 min.",
    value: 300,
  },
];

const Dropdown = ({ pause, time, handleClick }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [intervalText, setIntervalText] = useState("None");
  const node = useRef();

  var timeDiff = moment.tz.guess();
  var systemAbbr = moment.tz(timeDiff).format("z");
  /** Selected system timezone */
  let selectedTimeZone = timezoneList.findIndex(
    (item) => item.abbr === systemAbbr || item.utc.includes(timeDiff)
  );
  const [timeZone, setTimeZone] = useState(
    selectedTimeZone === -1 ? 0 : selectedTimeZone
  );
  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setShowDropdown(false);
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <DropdownWrapper pause={pause} time={time} ref={node}>
      <TitleDiv
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
        pause={pause}
      >
        {pause ? (
          intervalText === "None" ? (
            <img src={pauseIcon} alt="" className="pause" />
          ) : (
            <span>{intervalText}</span>
          )
        ) : (
          <>
            <span>{timezoneList[timeZone].text.substring(0, 22)}</span>
            <span className="d-none">
              {localStorage.setItem("time", timezoneList[timeZone].text)}
            </span>
          </>
        )}
        <img src={downCaret} alt="" className="caret" />
      </TitleDiv>
      <DropContainer show={showDropdown} pause={pause}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {time
          ? timezoneList.map((zone, index) => {
              return (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    setTimeZone(index);
                    setShowDropdown(false);
                    handleClick(zone.utc[0]);
                  }}
                >
                  {zone.text}
                </span>
              );
            })
          : pause
          ? graphReloadIntervals.map((interval, index) => {
              return (
                <span
                  key={interval.value}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIntervalText(interval.title);
                    setShowDropdown(false);
                    handleClick(interval.value);
                  }}
                >
                  {interval.title}
                </span>
              );
            })
          : null}
      </DropContainer>
    </DropdownWrapper>
  );
};

Dropdown.propTypes = {
  pause: PropTypes.bool,
  time: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
  pause: false,
  time: false,
};

export default Dropdown;
