import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card as BootstrapCard,
  Dropdown as BootstrapDropdown,
  Image,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ContentLoader from "react-content-loader";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import Edit from "../../assets/images/Path 14.svg";
import Delete from "../../assets/images/black-delete.svg";
import Email from "../../assets/images/icon_mail.svg";
import Dot from "../../assets/images/dot.svg";
import EyesIcon from "../../assets/images/EyesIcon.svg";
import BlueEyesIcon from "../../assets/images/BlueEyesIcon.svg";
import { toggleDairyMonitoring, getUser } from "../../actions/actions";
const StyledCard = styled(BootstrapCard)`
  min-height: 86px;
  max-height: 86px;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  .edit-dropdown {
    transform: translate(-117px, 28px) !important;
  }
`;
const ThreeDots = styled.span`
  .dropdown-toggle {
    line-height: 8px;
    padding : 5px 5px 6px 5px;
    &:hover {
      background-color : rgba(33,47,70,0.05);
      border-radius: 2px;
    }
    &:focus{
      background-color : rgba(33,47,70,0.05);
      border-radius: 2px;
    }
  }
  .dropdown-toggle img{
      &:hover {
        opacity : 1 !important;
      }
    }
  }
  .dropdown-toggle::after {
    content: none;
    &:hover {
      background: #d6e6ff;
    }
  }
`;
const Card = memo(
  ({
    title,
    subtitle,
    variant,
    children,
    isLoading,
    animate,
    to,
    deleteHandler,
    editHandler,
    notRegister,
    item,
    isMonitoring,
    isAlert,
    activateLinkHandler,
    onClick,
  }) => {
    const history = useHistory();
    const { regionID } = useParams();
    const cardClicked = (e) => {
      history.push(to);
    };
    const { t } = useTranslation();
    const [isMonitor, setIsMonitor] = useState(isMonitoring);
    const user = getUser();
    const toggleMonitor = (e) => {
      e.stopPropagation();
      toggleDairyMonitoring(isMonitor === 1, user.id, item.id)
        .then((response) => {
          if (response.status === 200) {
            setIsMonitor(isMonitor === 1 ? 0 : 1);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
    //As the component does not unmount isMonitor is set to it's inital value only once when it is mounted that's why we need useEffect to set the value in  re renders
    useEffect(() => {
      setIsMonitor(isMonitoring);
    }, [item]);
    return (
      <>
        {" "}
        {isLoading ? (
          <ContentLoader
            speed={2}
            width={282}
            height={86}
            animate={animate}
            viewBox="0 0 282 86"
            backgroundColor="#fdf8fc"
            foregroundColor="#ecebeb"
          >
            <rect x="14" y="10" rx="2" ry="2" width="139" height="20" />
            <rect x="15" y="35" rx="2" ry="2" width="50" height="20" />
            <rect x="15" y="60" rx="2" ry="2" width="113" height="20" />
            <rect x="223" y="10" rx="2" ry="2" width="25" height="20" />
          </ContentLoader>
        ) : (
          <StyledCard
            style={{ cursor: "pointer" }}
            onClick={onClick ? onClick : cardClicked}
            bg={variant}
            className={
              !isAlert
                ? "internal-card  rounded-0 w-100"
                : "red rounded-0 w-100"
            }
          >
            <BootstrapCard.Body className="py-2 pl-3 pr-2">
              <ThreeDots
                className="border-0 mt-0 float-right text-white"
                onClick={(e) => e.stopPropagation()}
              >
                <BootstrapDropdown>
                  <BootstrapDropdown.Toggle variant="none" id="Dropdown-basic">
                    <Image src={Dot} />
                  </BootstrapDropdown.Toggle>
                  <BootstrapDropdown.Menu
                    menualign="right"
                    flip={false}
                    id="dropdown-menu-align-right"
                    className="edit-dropdown rounded-1 border-0 p-0"
                  >
                    <BootstrapDropdown
                      eventkey="2"
                      onClick={editHandler}
                      className="px-3 edit-button rounded-0  text-dark text-capitalize text-left py-2"
                    >
                      <Image src={Edit} fluid className="align-baseline mr-2" />
                      {t("edit")}
                    </BootstrapDropdown>
                    <BootstrapDropdown
                      eventkey="3"
                      onClick={deleteHandler}
                      className="px-3 edit-button rounded-0  text-dark text-capitalize text-left py-2"
                    >
                      <Image
                        src={Delete}
                        fluid
                        className="align-baseline mr-2"
                      />
                      {t("delete")}
                    </BootstrapDropdown>
                    {notRegister?.props?.children === t("not_register") && (
                      <BootstrapDropdown
                        variant="link"
                        className="edit-button text-dark rounded-0 text-left w-100"
                        onClick={activateLinkHandler}
                        className="px-3 edit-button text-dark rounded-0 text-left py-2"
                      >
                        <Image
                          src={Email}
                          fluid
                          className="align-baseline mr-2"
                        />
                        {t("resend_link")}
                      </BootstrapDropdown>
                    )}
                  </BootstrapDropdown.Menu>
                </BootstrapDropdown>
              </ThreeDots>
              <BootstrapCard.Title>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="tooltip-disabled" className="annotation-tooltip">
                      {title}
                    </Tooltip>
                  }
                >
                <h3
                  className="font-weight-medium mb-1 text-dark text-truncate text-capitalize"
                  style={{ fontSize: "16px" }}
                >
                  {title}
                </h3>
                </OverlayTrigger>
              </BootstrapCard.Title>
              <BootstrapCard.Subtitle
                className={
                  history.location.pathname === `/regions/${regionID}/dealers`
                    ? "w-100 text-truncate my-1"
                    : "w-100 text-truncate"
                }
              >
                <p
                  className="float-left text-truncate font-weight-normal mb-1 text-dark"
                  style={{ fontSize: "15px" }}
                >
                  {subtitle}
                </p>
                <h5 className="text-warning float-right pr-1 font-weight-normal">
                  {notRegister}
                </h5>
              </BootstrapCard.Subtitle>
              {user.role === "SuperAdmin" ||
              user.role === "RegionalManager" ||
              user.role === "Dealer" ||
              user.role === "Technician" ? (
                <span
                  href="#"
                  onClick={toggleMonitor}
                  className="float-right"
                  style={{
                    marginTop: "-1px",
                  }}
                >
                  {/* Show eye symbols only if prop is passed */}
                  {isMonitor !== undefined &&
                    (isMonitor === 1 ? (
                      <Image src={BlueEyesIcon} className="mr-2" />
                    ) : (
                      <Image src={EyesIcon} className="mr-2" />
                    ))}
                </span>
              ) : (
                ""
              )}
              <BootstrapCard.Text className="text-truncate text-muted text-capitalize">
                <small className="font-weight-normal card-bottom-text-color">
                  {children}
                </small>
              </BootstrapCard.Text>
            </BootstrapCard.Body>
          </StyledCard>
          //{" "}
        )}
      </>
    );
  }
);
Card.propTypes = {
  /** Card Title */
  title: PropTypes.string,
  /** Card SubTitle */
  subtitle: PropTypes.string,
  /** Card Color Variant */
  variant: PropTypes.oneOf(["primary", "danger", "white", "info"]),
  /** Card children element */
  children: PropTypes.node,
  /** Load data using true or false  */
  isLoading: PropTypes.bool,
  /** loaded data is animated or not */
  animate: PropTypes.bool,
  /* Eyes icon showing or not */
  // rightIcon: PropTypes.bool,
  /** Link card */
  to: PropTypes.any,
  // border: PropTypes.oneOf(["primary", "danger", "white", "info", "light"]),
  /** Click on delete button */
  deleteHandler: PropTypes.func,
  /** Click on edit button */
  editHandler: PropTypes.func,
  /** User register or not */
  notRegister: PropTypes.any,
  isAlert: PropTypes.any,
  /** Click on resend link button */
  activateLinkHandler: PropTypes.func,
};
Card.defaultProps = {
  title: "Title 1",
  subtitle: "Subtitle",
  variant: "white",
  children: "",
  isLoading: false,
  animate: false,
  deleteHandler: () => {},
  editHandler: () => {},
};
export default Card;