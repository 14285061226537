import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  canadianFrench,
  dutch,
  english,
  german,
  japanese,
  spanish,
} from "./locales";
const defaultlanguage = localStorage.getItem("i18nextLng");

const resources = {
  English: {
    translation: english,
  },
  Dutch: {
    translation: dutch,
  },
  Japanese: {
    translation: japanese,
  },
  CanadianFrench: {
    translation: canadianFrench,
  },
  Spanish: {
    translation: spanish,
  },
  German: {
    translation: german,
  },
};


/* Language Detector Code */
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "English",
    lng: defaultlanguage || "English",
    translateFuncName: "t",
    resources,
    interpolation: {
      escapeValue: false,
    },
    wait: true,
  });
export default i18n;
