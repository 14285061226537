import React, { memo } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import MuellerLogo from "../../assets/images/Mueller_dark_logo.svg";
import backarrow from "../../assets/images/black-md-arrow.svg";

const PrivacyPolicyPageSpanish = memo(() => {
    return(
        <Container>
            
            <div className="mb-5 mt-5  ">
                <Row>
                    <Col
                        xs={{ span: 1, offset: 0 }}
                        sm={{ span: 1, offset: 0 }}
                        md={{ span: 1, offset: 0 }}
                        lg={{ span: 1, offset: 0 }}
                        className="pl-3"
                    ><img src={backarrow} className="mt-2 p-3 text-center back-arrow-hover rounded" style={{cursor: 'pointer'}} onClick={() => {window.history.back()}} /></Col>
                <Col
                    xs={{ span: 8, offset: 1 }}
                    sm={{ span: 10, offset: 0 }}
                    md={{ span: 10, offset: 0 }}
                    lg={{ span: 10, offset: 0 }}
                    className="text-center mr-1"><img  src={MuellerLogo}  /></Col>
                </Row>
                <Row>
                <Col
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                >
                <div className="mt-5 col-12">
                    <h2>Política de privacidad</h2>
                </div>

                <div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3" >
                    <h3 className="text-primary">Este sitio web es administrado por Paul Mueller Company.</h3>
                        <p>Si usa el sitio web de Paul Mueller Company y los productos y servicios que encontrará allí, nos está brindando sus datos. Además, el sitio web almacena una determinada cantidad de cookies del navegador en su computadora. Se las utiliza para hacer que el sitio web funcione mejor en su dispositivo y para brindarle una experiencia de usuario más valiosa.</p>
                        <p>A continuación podrá leer qué datos recopila Paul Mueller Company, por qué lo hacemos y qué podríamos hacer con esos datos. También puede leer qué cookies usamos y por qué. Esta declaración hace referencia a todo nuestro sitio web.</p>
                    <h3 className="text-primary">Cookies</h3>
                        <p>Para que este sitio web funcione mejor y más rápido, y pueda adaptarse a sus preferencias, se recopilará cierta información sobre su uso de este sitio web. Es posible recopilar esta información mediante el uso de lo que suele denominarse "cookies". Se trata de pequeños archivos de texto que la página web visitada almacena automáticamente en su computadora. Puede deshabilitar el uso de "cookies" en las configuraciones de su navegador. Puede eliminar las "cookies" cuando lo desee a través de la funcionalidad de administración de "cookies" de su navegador. No obstante, esto provocará una experiencia de usuario poco óptima en este sitio web. Otros terceros también pueden colocar cookies y recopilar datos personales similares.</p>
                    <h3 className="text-primary">¿Cómo obtenemos sus datos?</h3>
                       <p> Si usa el sitio web de Paul Mueller Company, nos brinda datos personales de las siguientes maneras.</p>
                       <p>Datos recopilados automáticamente a través de la navegación en el sitio web, como identificadores de cookies, tipo de dispositivo, tipo de navegador y páginas del sitio que ha visitado. <br />
                       Información voluntaria que usted nos proporciona al enviar mensajes a través de formularios de contacto o al activar otros formularios de Internet en el sitio web.</p>
                    <h3 className="text-primary">¿Qué hacemos con sus datos?</h3>
                        <p>Paul Mueller Company almacena y procesa sus datos con los siguientes propósitos:</p>
                        <ul>
                            <li>Recopilación y análisis de estadísticas para optimizar el sitio web;</li>
                            <li>Ofrecimiento de productos y servicios que adquirió de nosotros;</li>
                            <li>Creación de un historial de contactos;</li>
                            <li>Capacidad para enviar correos y boletines informativos.</li>
                        </ul>
                    <h3 className="text-primary">¿Cómo protegemos sus datos?</h3>
                        <p>A fin de proteger sus datos, tomaremos todas las medidas pertinentes de conformidad con las leyes y regulaciones aplicables a la protección de datos y seguridad de los datos, lo que incluye solicitarle a nuestros proveedores de servicios que implementen medidas adecuadas para proteger la confidencialidad y seguridad de sus datos.  Según el estado de la cuestión, los costos de la implementación y la naturaleza de los datos que se desee proteger, implementamos medidas técnicas y organizacionales para evitar riesgos como la destrucción, pérdida, alteración, divulgación no autorizada o acceso a sus datos.</p>
                    <h3 className="text-primary">¿Con quién comparte sus datos Paul Mueller Company?</h3>
                        <p>Paul Mueller Company manejará sus datos de forma confidencial. Compartiremos sus datos con nuestra red de confianza de representantes del fabricante y distribuidores autorizados de Paul Mueller Company solo cuando sea necesario para proporcionarle los productos o servicios solicitados. </p>
                        <p>Compartimos un conjunto limitado de datos personales de los usuarios de MilkSecure™ con los transportistas de leche, el distribuidor de equipos de granja lechera local y los inspectores cuando es necesario para la recolección, el mantenimiento del equipo o para garantizar el cumplimiento de las reglamentaciones vigentes. </p>
                        <p>Nunca venderemos sus datos a terceros sin su consentimiento expreso.</p>
                    <h3 className="text-primary">Contacto, correo y/o publicidad</h3>
                        <p>Si recibe correos o anuncios no deseados, puede indicar en cualquier momento que ya no quiere que lo contactemos. No existe ningún costo asociado a la cancelación de la suscripción.</p>
                    <h3 className="text-primary">¿Tiene menos de 16 años?</h3>
                       <p>Si es menor de edad, es decir, si tiene menos de 16 años, solo podrá aceptar el procesamiento de sus datos personales si cuenta con el consentimiento de uno de sus padres o tutor legal. Si es menor de 16 años, es importante que su(s) padre(s)/madre(s) o su tutor legal lean esta declaración. También pueden ejercer los derechos de los que usted goza con respecto a los datos personales que envía, como el derecho a oponerse al (futuro) procesamiento de sus datos personales o el derecho a acceder y corregir sus datos.</p>
                    <h3 className="text-primary">¿Qué puede hacer con sus datos?</h3>
                        <p>Aspiramos a brindarle acceso a sus datos. Por lo general, puede controlar sus datos de forma autónoma (por ejemplo, al iniciar sesión en su cuenta) y actualizarlos, modificarlos o, si es legalmente posible, eliminarlos.  En este caso, le recomendamos encarecidamente que tome el control de sus datos.</p>
                        <p>Puede contactarnos en todo momento si desea:</p>
                        <ul>
                            <li>revisar, cambiar o borrar los datos que nos ha suministrado (en la medida en que Paul Mueller Company no esté autorizada o no tenga la obligación de conservar dichos datos); </li>
                            <li>oponerse a ciertas operaciones de procesamiento de datos (por ejemplo, optar por no recibir comunicaciones de marketing); </li>
                            <li>recibir una copia de sus datos (en un formato común legible por máquina, en la medida en que la ley aplicable lo requiera); </li>
                            <li>hacernos cualquier otra pregunta relacionada con la protección de sus datos en Paul Mueller Company</li>
                            <li>Puede enviar sus solicitudes a  <a href="mailto:privacy@paulmueller.com">PRIVACY@PAULMUELLER.COM</a>.</li>
                        </ul>
                       <p> Haremos nuestro mejor esfuerzo por atender su solicitud de manera oportuna y sin cargo alguno, a excepción de los casos que requieran un esfuerzo desproporcionado. En ciertas circunstancias, podemos pedirle que verifique su identidad antes de que podamos atender su solicitud.</p>
                    <h3 className="text-primary">Sitios web de terceros</h3>
                        <p>Esta Declaración de privacidad no se aplica a los sitios web de terceros a los que se puede acceder a través de los enlaces incluidos en este sitio web.</p>
                    <h3 className="text-primary">Modificaciones a esta declaración de privacidad</h3>
                        <p>Paul Mueller Company se reserva el derecho de realizar cambios a esta Declaración de privacidad. Por lo tanto, le recomendamos que revise esta Declaración de privacidad con regularidad para mantenerse informado sobre estos cambios. Puede consultar la versión más reciente en todo momento a través del sitio web.</p>
                </div>
            </div>
            </Col>
            </Row>
            </div>
        </Container>
  
    );
});

export default PrivacyPolicyPageSpanish;
