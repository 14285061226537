import React, { memo } from "react";
import { Image } from "react-bootstrap";
import PropTypes from "prop-types";
import IconSuccess from "../../assets/images/success.svg";
import IconWarning from "../../assets/images/warning.svg";
import IconDownLoad from "../../assets/images/download.svg";

const Toaster = memo(({ message, icon, className }) => {
  const ShowIcon = () => {
    switch (icon) {
      case "download":
        return <Image src={IconDownLoad} fluid className="px-2 notification" />;
        break;

      case "error":
        return <Image src={IconWarning} fluid className="px-3 notification" />;
        break;

      case "notify":
        return <Image src={IconSuccess} fluid className="px-3 notification" />;
        break;
      default:
        break;
    }
    return null;
  };

  return (
    <div className="notification d-flex align-items-center">
      {ShowIcon()}
      <div className={className}>{message}</div>
    </div>
  );
});

Toaster.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Toaster.defaultProps = {
  className: "text-white",
};
export default Toaster;
