import React, { memo } from "react";
import PropTypes from "prop-types";
import { Breadcrumb as BootstrapBreadcrumb } from "react-bootstrap";
import styled from "styled-components";
import routes from "../../routes";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUser } from "../../actions/actions";

const BreadCrumbIcon = styled.span`
  img {
    position: relative;
    top: -2px;
    margin-right: 8px;
    width: 15px;
  }
`;

const Breadcrumb = ({ breadcrumbs }) => {
  const user = getUser();
  const { t } = useTranslation();

  return (
    <BootstrapBreadcrumb>
      {breadcrumbs.map(
        ({ match, location, breadcrumb, ableToAccess }, index) => {
          if (ableToAccess.includes(user.role)) {
            //To not have the breadcrumb named "Dealer" and instead have the dealer name when user goes inside a dealer
            if (
              match.path === "/regions/:regionID/dealers" &&
              location?.pathname?.split("/").length > 4
            ) {
              return false;
            }
            //To not have the breadcrumb named "Dairy Farms" and instead have the dairy name when user goes inside a tank
            else if (
              match.path === "/regions/:regionID/dealers/:dealerID/dairies" &&
              location?.pathname?.split("/").length > 6
            ) {
              return false;
            }
            //To remove "Dairy Farms" and instead have the dairy name when user goes inside a tank,
            else if (
              match.path === "/regions/dealers/:dealerID/dairies" &&
              location?.pathname?.split("/").length > 5
            ) {
              return false;
            }
            //This situation will be encountered when we go to independent dairies or dealers and in that breadcrumb of region/regionId is also displayed so this condition
            else if (
              match.params.regionID === "dairies" ||
              match.params.regionID === "dealers"
            ) {
              return false;
            } else {
              return (
                <BootstrapBreadcrumb.Item
                  className={
                    index !== breadcrumbs?.length - 1
                      ? "text-truncate text-capitalize"
                      : "text-truncate text-capitalize"
                  }
                  style={{
                    maxWidth: "33%",
                    cursor:
                      index !== breadcrumbs.length - 1 ? "default" : "inherit",
                  }}
                  key={match.url}
                  linkAs={Link}
                  linkProps={{
                    to: index !== breadcrumbs.length - 1 && match.url,
                    className:
                      index !== breadcrumbs.length - 1 ? "" : "text-info",
                  }}
                >
                  {breadcrumb.props.children
                    ? t(breadcrumb.props.children)
                    : breadcrumb}
                </BootstrapBreadcrumb.Item>
              );
            }
          }
        }
      )}
    </BootstrapBreadcrumb>
  );
};

export default withBreadcrumbs(routes.privateRoutes, {
  disableDefaults: true,
})(Breadcrumb);
