import React, { memo, useState } from "react";
import { Carousel } from "react-bootstrap";
import SliderOne from "../../assets/Slider/1.svg";
import SliderTwo from "../../assets/Slider/2.svg";
import SliderThree from "../../assets/Slider/3.svg";

const Slider = memo(() => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <Carousel
      prevIcon={
        <span
          aria-hidden="true"
          className="carousel-control-prev-icon d-none"
        />
      }
      nextIcon={
        <span
          aria-hidden="true"
          className="carousel-control-next-icon d-none"
        />
      }
      className="m-lg-5 p-lg-5"
      activeIndex={index}
      onSelect={handleSelect}
    >
      <Carousel.Item>
        <img
          className="d-block w-100 h-100"
          src={SliderOne}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100  h-100"
          src={SliderTwo}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100  h-100"
          src={SliderThree}
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
});

export default Slider;
