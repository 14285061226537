import React, { memo } from "react";
import { Row, Col } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const tmparray = [1, 1, 1, 1, 1, 1, 1, 1]
const AlertLoader = memo(() => {
  return (
    tmparray.map(element => {
      return (
        <div className="m-0 px-3 pt-3 pb-3">
          <tr>
            <Skeleton count={1} height={25} width={300} />
          </tr>
          <tr>
            <Skeleton count={1} height={25} width={900} />
          </tr>
        </ div >
      )
    })
  );
});

export default AlertLoader;