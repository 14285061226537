import React, { memo } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import MuellerLogo from "../../assets/images/Mueller_dark_logo.svg";
import backarrow from "../../assets/images/black-md-arrow.svg";

const PrivacyPolicyPageGerman = memo(() => {
    return(
        <Container>
            
            <div className="mb-5 mt-5  ">
                <Row>
                    <Col
                        xs={{ span: 1, offset: 0 }}
                        sm={{ span: 1, offset: 0 }}
                        md={{ span: 1, offset: 0 }}
                        lg={{ span: 1, offset: 0 }}
                        className="pl-3"
                    ><img src={backarrow} className="mt-2 p-3 text-center back-arrow-hover rounded" style={{cursor: 'pointer'}} onClick={() => {window.history.back()}} /></Col>
                <Col
                    xs={{ span: 8, offset: 1 }}
                    sm={{ span: 10, offset: 0 }}
                    md={{ span: 10, offset: 0 }}
                    lg={{ span: 10, offset: 0 }}
                    className="text-center mr-1"><img  src={MuellerLogo}  /></Col>
                </Row>
                <Row>
                <Col
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                >
                <div className="mt-5 col-12">
                    <h2>Datenschutz-Bestimmungen</h2>
                </div>

                <div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3" >
                    <h3 className="text-primary">Diese Website wird von Paul Mueller Company verwaltet.</h3>
                        <p>Wenn Sie die Website von Paul Mueller Company nutzen und die Produkte und Dienstleistungen in Anspruch nehmen, die dort zu finden sind, hinterlassen Sie bestimmte Daten bei uns. Zudem speichert die Website diverse Browser-Cookies auf Ihrem Computer. Diese dienen einer verbesserten Funktionalität der Website auf Ihrem Gerät und bieten ein wertvolles Nutzererlebnis.</p>
                        <p>Nachfolgend erfahren Sie, welche Daten von Paul Mueller Company erfasst werden, weshalb sie erfasst werden und was mit diesen Daten geschieht. Sie können zudem einsehen, welche Cookies wir verwenden und aus welchem Grund. Diese Datenschutzerklärung betrifft unsere gesamte Website.</p>
                    <h3 className="text-primary">Cookies</h3>
                        <p>Um die optimale Funktionalität unserer Website zu gewährleisten und die Seite an Ihre Anforderungen anzupassen, werden bestimmte Informationen zu Ihrer Seitennutzung erfasst. Diese Informationen können mithilfe von sogenannten „Cookies“ festgehalten werden. Dabei handelt es sich um kleine Textdateien, die von der besuchten Website automatisch auf Ihrem Computer abgelegt werden. Sie können den Einsatz von „Cookies“ in den Einstellungen Ihres Browsers deaktivieren. Die „Cookies“ können Sie nach eigenem Ermessen über die Cookie-Verwaltung Ihres Browsers entfernen. Dies führt jedoch zu einem weniger optimalen Nutzererlebnis auf unserer Website. Auch Dritte können Cookies verwenden und ähnliche personenbezogene Daten erfassen.</p>
                    <h3 className="text-primary">Wie erhalten wir Ihre Daten?</h3>
                       <p> Wenn Sie die Website von Paul Mueller Company nutzen, hinterlassen Sie folgende personenbezogene Daten bei uns: </p>
                       <p>Automatisch erfasste Daten durch den Besuch der Website, wie Cookie-Kennung, Gerätetyp, Browsertyp und besuchte Seiten <br/>
                        Freiwillige Angaben, die Sie zur Verfügung stellen, wenn Sie Nachrichten per Kontaktformular verschicken oder andere Internetformulare auf der Website aktivieren</p>
                    <h3 className="text-primary">Was machen wir mit Ihren Daten?</h3>
                        <p>Paul Mueller Company speichert und verarbeitet Ihre Daten zu folgenden Zwecken:</p>
                        <ul>
                            <li>Erfassung und Analyse von Statistiken zur Optimierung der Website</li>
                            <li>Bereitstellung von Produkten und Dienstleistungen, die Sie bei uns kaufen</li>
                            <li>Erstellung einer Kontakthistorie</li>
                            <li>Versand von Postsendungen und Newslettern</li>
                        </ul>
                    <h3 className="text-primary">Wie sichern wir Ihre Daten?</h3>
                        <p>Zum Schutz Ihrer Daten ergreifen wir entsprechende Maßnahmen, die den geltenden Datenschutz- und Datensicherheitsbestimmungen entsprechen, wie etwa die Verpflichtung unserer Dienstleister die Vertraulichkeit und Sicherheit Ihrer Daten mit geeigneten Maßnahmen zu schützen. Je nach Stand der Technik, Kosten für die Umsetzung und Art der zu schützenden Daten ergreifen wir technische und organisatorische Maßnahmen zum Schutz Ihrer Daten vor Risiken wie Zerstörung, Verlust, Veränderung, unbefugter Weitergabe oder Zugriff.</p>
                    <h3 className="text-primary">Mit wem teilt die Paul Mueller Company Ihre Daten?</h3>
                        <p>Die Paul Mueller Company wird Ihre Daten vertraulich behandeln. Wir reichen Ihre Daten nur dann an unser vertrauenswürdiges Netzwerk von Herstellervertretern und autorisierten Händlern der Paul Mueller Company weiter, wenn dies erforderlich ist, um Ihnen die gewünschten Produkte oder Services zu liefern. </p>
                        <p>Wir reichen einen begrenzten Satz personenbezogener Daten von MilkSecure™-Benutzern an Milchtransporteure, den örtlichen Händler für Molkereiausrüstungen und Inspektoren weiter, wenn dies für die Abholung, den Ausrüstungsservice oder die Sicherstellung der Einhaltung der geltenden Vorschriften erforderlich ist. </p>
                        <p>Wir werden Ihre Daten niemals ohne Ihre ausdrückliche Zustimmung an Dritte verkaufen. </p>
                    <h3 className="text-primary">Kontakt, Sendungen und/oder Werbung</h3>
                        <p>Wenn Sie Sendungen oder unerwünschte Werbung von uns erhalten, können Sie jederzeit angeben, dass Sie keine weitere Kontaktaufnahme wünschen. Für die Abmeldung fallen keine Kosten an.</p>
                    <h3 className="text-primary">Unter 16 Jahre?</h3>
                       <p>Wenn Sie minderjährig und jünger als 16 Jahre sind, können Sie der Verarbeitung Ihrer personenbezogenen Daten nur mit der Erlaubnis eines Elternteils oder Erziehungsberechtigten zustimmen. Hierbei ist wichtig, dass Ihre Eltern oder Erziehungsberechtigten diese Erklärung lesen. Diese haben ebenfalls das Recht, das Sie in Bezug auf die von Ihnen übermittelten personenbezogenen Daten haben, wie etwa das Recht, die (weitere) Verarbeitung Ihrer personenbezogenen Daten abzulehnen, oder das Recht auf Zugriff und Berichtigung Ihrer Daten.</p>
                    <h3 className="text-primary">Was können Sie mit Ihren Daten machen?</h3>
                        <p>Wir bemühen uns, Ihnen Zugang zu Ihren Daten zu gewähren. Üblicherweise können Sie Ihre Daten selbstständig steuern (z.B. indem Sie sich auf Ihrem Konto anmelden) und diese aktualisieren, ändern oder, soweit rechtlich möglich, löschen. In diesem Fall empfehlen wir dringend, Ihre Daten selbst zu verwalten.</p>
                        <p>In den folgenden Fällen können Sie sich gerne jederzeit mit uns in Verbindung setzen:</p>
                        <ul>
                            <li>Einsicht, Änderung oder Löschung der Daten, die Sie uns zur Verfügung gestellt haben (sofern Paul Mueller Company nicht anderweitig verpflichtet ist, derartige Daten zu behalten)</li>
                            <li>Beanstandung bestimmter Datenverarbeitungsvorgänge (z.B. Abmeldung von Werbung)</li>
                            <li>Erhalt einer Kopie Ihrer Daten (in einem gängigen maschinenlesbaren Format, soweit dies nach geltendem Recht erforderlich ist) </li>
                            <li>Bei sonstigen Fragen zum Schutz Ihrer Daten bei Paul Mueller Company</li>
                            <li>Schicken Sie Ihre Anfragen an <a href="mailto:privacy@paulmueller.com">PRIVACY@PAULMUELLER.COM</a>.</li>
                        </ul>
                       <p>Wir bemühen uns, Ihre Anfrage möglichst zeitnah und kostenfrei zu bearbeiten, sofern dies keinen unverhältnismäßigen Aufwand erfordert. In bestimmten Fällen kann es erforderlich sein, Ihre Identität zu überprüfen, bevor wir Ihre Anfrage beantworten können.</p>
                    <h3 className="text-primary">Websites Dritter</h3>
                        <p>Diese Datenschutzerklärung erstreckt sich nicht auf Websites Dritter, die über Links auf dieser Website geöffnet werden können.</p>
                    <h3 className="text-primary">Änderungen an dieser Datenschutzerklärung</h3>
                        <p>Paul Mueller Company behält sich das Recht vor, Änderungen an dieser Datenschutzerklärung vorzunehmen. Wir empfehlen Ihnen deshalb, diese Datenschutzerklärung regelmäßig einzusehen, um über Änderungen informiert zu sein. Die aktuellste Version kann jederzeit auf der Website eingesehen werden.</p>
                </div>
            </div>
            </Col>
            </Row>
            </div>
        </Container>
  
    );
});

export default PrivacyPolicyPageGerman;
