import React, { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DealerCard from "../../components/DealerCard";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import {
  getIndependentDealersList,
  deleteSuperAdmin,
  postResendUserActivationLink,
  getIndependentDairiesList,
  getRegions,
} from "../../actions/actions";
import Search from "../../components/Search";
import AddDealerForm from "../../forms/DealerForm/AddDealerForm";
import EditDealerForm from "../../forms/DealerForm/EditDealerForm";
import PromptModel from "components/PromptModel/PromptModel";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { useApiContext } from "../../context/apiContext";
import CardViewLoader from "../../components/CardViewLoader";

const IndependentDealerTab = memo(
  ({ dealers, setDealers, setIndependentDairy }) => {
    const { t } = useTranslation();
    /* set searchTerm data with useState */
    const [show, setShow] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [open, setOpen] = useState(false);
    const [selectId, setSelectedId] = useState(null);
    const [user, setUser] = useState(null);
    const [display, setDisplay] = useState(false);
    const [dealerData, setDealerData] = useState([]);
    const apiRegion = useApiContext();
    const [regions, setRegions] = useState([]);
    const [cardViewLoader, setCardViewLoader] = useState(false);

    /** Display dealer list in dealer card */
    const DealerList = () => {
      setCardViewLoader(true);
      getIndependentDealersList()
        .then((response) => {
          setCardViewLoader(false);
          const DealerId = response.data.map((i) => i.id);
          localStorage.setItem("Dealers", JSON.stringify(DealerId));
          setDealers(response.data);
        })
        .catch((err) => {
          toast.dark(
            <Toaster
              icon="error"
              message={err?.response?.message ?? t("something_went_wrong")}
            />
          );
        });
    };

    useEffect(() => {
      DealerList();
    }, []);

    const deleteDealer = (id) => {
      deleteSuperAdmin(id)
        .then((response) => {
          if (response.status === 200) {
            setSelectedId(null);
            getIndependentDairiesList().then((res) =>
              setIndependentDairy(res.data)
            );
            setOpen(false);
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("dealer_deleted_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
            DealerList();
          }
        })
        .catch((err) => {
          console.error(err);
          toast.dark(
            <Toaster
              icon="error"
              message={err?.response?.message ?? t("something_went_wrong")}
            />
          );
        });
    };

    /** User not register when send user activation link functionality */
    const sendUserActivationLink = (email) => {
      postResendUserActivationLink({ email: email })
        .then((response) => {
          if (response.status === 200) {
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("send_invitation_link")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
          }
        })
        .catch((err) => {
          console.error(err);
          toast.dark(
            <Toaster
              icon="error"
              message={err?.response?.message ?? t("something_went_wrong")}
            />
          );
        });
    };

    /** Added and update data after update state data */
    const updatedList = (item, action) => {
      if (action === "edit") {
        if (item.region) {
          const dealer = dealers.filter((i) => i.id !== dealerData.id);
          setDealers(dealer);
        } else {
          setDealers(dealers.map((i) => (i.id === item.id ? item : i)));
        }
      }
    };

    const results = dealers?.filter((card) => {
      return card.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    useEffect(() => {
      if (apiRegion.regionApi.length < 1) {
        getRegions().then((response) => {
          setRegions(response.data);
          apiRegion.setRegionApi(response.data);
        });
      } else {
        setRegions(apiRegion.regionApi);
      }
    }, []);
    return (
      <>
        <Row>
          <Col xs={12} lg={3} md={8} sm={7} className="mb-4">
            <Search
              placeholder={t("search")}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </Col>
        </Row>
        {cardViewLoader ? (
          <CardViewLoader />
        ) : (
            results.length < 1 ? (
              <div className="text-center lead">{t("data_not_available")}</div>
            ) : (
                <Row>
                  {results.map((item, i) => {
                    return (
                      <Col lg={3} md={4} className="mb-4" key={i}>
                        <DealerCard
                          to={`/regions/dealers/${item.id}/dairies`}
                          dealer={item}
                          key={"dealerTab" + item.id}
                          editHandlerEvent={(dealerId) => {
                            setDisplay(true);
                            setUser(dealerId);
                            setDealerData(item);
                          }}
                          deleteHandlerEvent={(dealerId) => {
                            setSelectedId(dealerId);
                            setOpen(true);
                          }}
                          activateLinkHandlerEvent={(email) =>
                            sendUserActivationLink(email)
                          }
                        />
                      </Col>
                    );
                  })}
                </Row>
              )
          )}
        {display && (
          <EditDealerForm
            showModel={display}
            closeModel={() => setDisplay(false)}
            userId={user}
            dealerData={dealerData}
            updateList={updatedList}
            sendUserActivationLink={(email) => sendUserActivationLink(email)}
            regions={regions}
          />
        )}
        <PromptModel
          open={open}
          closeAlert={() => {
            setOpen(false);
          }}
          modelContent={t("delete_record_msg")}
          callback={() => deleteDealer(selectId)}
        />
      </>
    );
  }
);

export default IndependentDealerTab;
