import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PrivacyPolicyLinks = () => {
  const { t } = useTranslation();
  let redirect_to = "#";
  const lang = localStorage.getItem("i18nextLng");

  switch (lang) {
    case "German":
      redirect_to = "/de/privacy-policy";
      break;
    case "Spanish":
      redirect_to = "/es/privacy-policy";
      break;
    case "Japanese":
      redirect_to = "/jp/privacy-policy";
      break;
    case "CanadianFrench":
      redirect_to = "/frca/privacy-policy";
      break;
    case "Dutch":
      redirect_to = "/nl/privacy-policy";
      break;
    default:
      redirect_to = "/en/privacy-policy";
  }

  return (
    <>
      <ul className="pr-0  mb-1 privacy-policy-link" dir="rtl">
        <Link className="text-dark" to={redirect_to}>
          <li className="mx-3 text-capitalize d-inline">
            <u>{t("privacy_policy")}</u>
          </li>
        </Link>
      </ul>
    </>
  );
};
export default PrivacyPolicyLinks;
