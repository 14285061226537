import React, {
  useState,
  useCallback,
  Fragment,
  useRef,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";
import { AsyncTypeahead, MenuItem, Menu } from "react-bootstrap-typeahead";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { rootApi } from "../../axiosConfig";

const Container = styled.div`
  .global-search{
  * {
  scrollbar-width: 3px;
  scrollbar-color: #adcdff transparent;
  }
  *::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  }
  *::-webkit-scrollbar-track {
  background: transparent;        
  }
  *::-webkit-scrollbar-thumb {
  background-color: #adcdff;   
  border-radius: 1px;     
  }
  .dropdown-item :active{
    color: 'black',
    backgroundColor: '#F0F6FF'
  }
  .dropdown-item.active{
      color: black;
      background-color: #F0F6FF;
  }
  .dropdown-divider{
    display : none;
  }
}
`;

const CalibrationChartSearch = ({ handleChange, calibrationData, setSelectCalibration, edit, dealerID }) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setResults] = useState();
  const location = useLocation();
  const [selected, setSelected] = useState(calibrationData);

  useEffect(() => {
    if (location.search === "") {
      ref.current.clear();
    }
  }, [location]);

  const filterByCallback = (option, props) => {
    return option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
  };

  /** Search Mueller chart functions */

  const searchMueller = async (keyword, orderBy = "chartName", order = "asc") => {
    let paramsOptions = `{"chartName":{"contains":"${keyword}"},"isDeleted":false,"manufacturer":"MUELLER","dealer":"${dealerID}"}`
    const url = `/tankChart/search?sort=${orderBy} ${order}&where=${paramsOptions}`
    try {
      const res = await rootApi.get(`${url}`);
      return res.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  };
  /** Search Non-mueller functions */
  const searchNonMueller = async (keyword, orderBy = "chartName", order = "asc") => {
    let paramsOptions = `{"chartName":{"contains":"${keyword}"},"isDeleted":false,"manufacturer":"NON-MUELLER","dealer":"${dealerID}"}`;
    const url = `/tankChart/search?sort=${orderBy} ${order}&where=${paramsOptions}`;
    try {
      const res = await rootApi.get(`${url}`);
      return res.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  const handleSearch = useCallback((query) => {
    setIsLoading(true);
    Promise.all([searchMueller(query), searchNonMueller(query)])
      .then(([muellerChartList, nonMuellerChartList]) => {
        let MuellerListData = [];
        let NonMuellerListData = [];
        if (muellerChartList.length > 0) {
          MuellerListData = muellerChartList.map((i) => ({
            type: "Mueller Charts",
            id: i.id,
            name: i.chartName,
          }));
        }
        if (nonMuellerChartList.length > 0) {
          NonMuellerListData = nonMuellerChartList.map((i) => {
            return {
              type: "Non-Mueller Charts",
              id: i.id,
              name: i.chartName,
            };
          });
        }
        const options = [...MuellerListData, ...NonMuellerListData];
        setResults(options);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  });

  /** Group by functionality */
  const groupBy = (data, key) =>
    data.reduce((acc, item) => {
      (acc[item[key]] = acc[item[key]] || []).push(item);
      return acc;
    }, {});
  /** Render option menu in search functionality */
  const renderMenu = (results, menuProps, state) => {
    let index = 0;
    const groups = groupBy(results, "type");
    const items = Object.keys(groups).map((t) => (
      <Fragment key={t}>
        {index !== 0 && <Menu.Divider className="m-0" />}
        <Menu.Header className="text-primary" style={{ color: "#3f7cca" }}>
          {t}
        </Menu.Header>
        {groups[t].map((i) => {
          const item = (
            <MenuItem key={index} option={i} position={index}>
              <div search={state} className="text-truncate">
                {i.name}
              </div>
            </MenuItem>
          );

          index += 1;
          return item;
        })}
      </Fragment>
    ));

    return <Menu {...menuProps}>{items}</Menu>;
  };

  const onBlur = (e) => {
    if (ref.current.inputNode.value === "") {
    } else {
      ref.current.inputNode.className =
        ref.current.inputNode.className + " " + "text-black";
    }
  };
  const onInputChange = (text) => {
    setSelected([]);
    if (text === "") {
      setResults([]);
    } else {
      setTimeout(handleSearch(text), 200);
    }
    if (edit)
      setSelectCalibration(null);
  };

  useEffect(() => {
    setSelected(calibrationData);
  }, [calibrationData])

  const onSelect = (selected) => {
    setSelected(selected);
    handleChange(selected);
  }

  return (
    <Container>
      <AsyncTypeahead
        id="search-f"
        onSearch={() => setSelected([])}
        className="global-search"
        selected={edit ? selected : null}
        isLoading={isLoading}
        labelKey="name"
        promptText="Searching..."
        options={searchResults}
        onChange={onSelect}
        placeholder={t("search")}
        renderMenu={renderMenu}
        filterBy={filterByCallback}
        minLength={1}
        ref={ref}
        useCache={false}
        onBlur={onBlur}
        onInputChange={onInputChange}
        size="lg"
      />
    </Container>
  );
};
export default CalibrationChartSearch;
