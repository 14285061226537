import React from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import downArrow from "../../assets/images/Language-down-arrow.svg";

const ToggleDropdown = styled(Dropdown)`
  .dropdown-toggle:after {
    background-image: url(${downArrow});
    top: 12px;
  }
`;

const LanguageDropdown = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <ToggleDropdown>
        <Dropdown
          onSelect={(e) => {
            localStorage.setItem("i18nextLng", e);
            i18n.changeLanguage(e);
          }}
        >
          <Dropdown.Toggle variant="" id="dropdown-basic">
            {t(localStorage.getItem("i18nextLng"))}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item className="pt-2 pb-2" eventKey="English">
              {t("English")}
            </Dropdown.Item>
            <Dropdown.Item className="pt-2 pb-2" eventKey="Spanish">
              {t("Spanish")}
            </Dropdown.Item>
            <Dropdown.Item className="pt-2 pb-2" eventKey="Dutch">
              {t("Dutch")}
            </Dropdown.Item>
            <Dropdown.Item className="pt-2 pb-2" eventKey="CanadianFrench">
              {t("CanadianFrench")}
            </Dropdown.Item>
            <Dropdown.Item className="pt-2 pb-2" eventKey="Japanese">
              {t("Japanese")}
            </Dropdown.Item>
            <Dropdown.Item className="pt-2 pb-2" eventKey="German">
              {t("German")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ToggleDropdown>
    </>
  );
};
export default LanguageDropdown;
