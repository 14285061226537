import React, { memo } from "react";
import { Row, Col } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const tmparray = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
const CardViewLoader = memo(() => {
  return (<SkeletonTheme color="#f3f3f3" highlightColor="#ecebeb">
    <Row>
      {tmparray.map(element => {
        return (<Col lg={3} md={4} className="mb-4">
          <Skeleton count={1} height={75} width={225} />
        </Col>)
      })}
    </Row>
  </SkeletonTheme>
  );
});

export default CardViewLoader;