import React, { memo, useState, useEffect } from "react";
import { Row, Col, Button, Form, Modal, Image, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { TankTypes, TankManufactureTypes } from "../../actions/passwordRegex";
import MyMuellerLogo from "../../assets/images/myMuellerLogo.svg";
import CreateMyMuellerChartForm from "./CreateMyMuellerChartForm";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { postTankChart } from "../../actions/actions";
import { CSVReader } from "react-papaparse";
import IconDownLoad from "../../assets/images/exportCsv.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/Icon feather-upload-cloud.svg";
import { CSVLink } from "react-csv";
import $ from "jquery";
const TRANSDUCER_REGEXP = new RegExp(
  "^([0-9]*[.])?[0-9]+$"
);

const AddNewChartForm = memo(({ dealerID, showModel, closeModel, updateList }) => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    chartName: yup.string().required(t("chart_name_required")),
    tankType: yup.string().required(t("tank_type_required")),
    manufacturer: yup.string().required(t("manufacture_required")),
    transducerLimit: yup
      .string()
      .required(t("transducer_limit_required"))
      .matches(TRANSDUCER_REGEXP, t("transducer_limit_invalid")),
    chart: yup.array(),
  });

  const [open, setOpen] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [show, setShow] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadError, setUploadError] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    validationSchema: schema,
  });

  const config = {
    header: true,
    transformHeader: (header) => header,
    skipEmptyLines: true,
    dynamicTyping: true,
  };

  const handleOnDrop = (data) => {
    if (data) {
      let getElement = $(".drag-drop-chart svg:first").parent();
      if (getElement.length > 0) {
        getElement.addClass("removed-button");
      }
      setErrorMessage("");
      const chartValue = data[0].data;
      const excelSequence = Object.keys(chartValue);
      if (
        excelSequence[0] === "Height (inch)" &&
        excelSequence[1] === "Volume Increment (gal US)" &&
        excelSequence[2] === "Accumulated Volume (gal US)"
      ) {
        setShow(false);
        let uploadFileData = data.map((i) => {
          return i.data;
        });
        let fileData = JSON.stringify(uploadFileData);
        setChartData(fileData);
        setUploadError(false);
      } else {
        setUploadError(true);
      }
    }
  };

  const handleOnError = (err, file, inputElem, reason) => {
    setUploadError(true);
  };

  const handleOnRemoveFile = (data) => {
    setChartData([]);
    setShow(true);
    setUploadError(false);
    setErrorMessage("");
  };

  const updatedMuellerData = (item) => {
    console.log(item);
  };

  // Sample CSV data
  const headers = [
    { label: "Height (inch)", key: "accumulate_height_csv_text" },
    { label: "Volume Increment (gal US)", key: "volume_increment_csv_text" },
    { label: "Accumulated Volume (gal US)", key: "accumulate_volume_csv_text" }
  ];

  const data = [
    { accumulate_height_csv_text: 0, volume_increment_csv_text: 0.050, accumulate_volume_csv_text: 0.050 },
    { accumulate_height_csv_text: 1, volume_increment_csv_text: 5.432, accumulate_volume_csv_text: 5.482 },
    { accumulate_height_csv_text: 2, volume_increment_csv_text: 8.027, accumulate_volume_csv_text: 13.509 },
    { accumulate_height_csv_text: 3, volume_increment_csv_text: 10.228, accumulate_volume_csv_text: 23.737 },
    { accumulate_height_csv_text: 4, volume_increment_csv_text: 11.913, accumulate_volume_csv_text: 35.650 },
    { accumulate_height_csv_text: 5, volume_increment_csv_text: 13.300, accumulate_volume_csv_text: 48.950 },
    { accumulate_height_csv_text: 6, volume_increment_csv_text: 14.477, accumulate_volume_csv_text: 63.426 },
    { accumulate_height_csv_text: 7, volume_increment_csv_text: 15.494, accumulate_volume_csv_text: 78.920 },
    { accumulate_height_csv_text: 8, volume_increment_csv_text: 16.381, accumulate_volume_csv_text: 95.301 },
    { accumulate_height_csv_text: 9, volume_increment_csv_text: 17.159, accumulate_volume_csv_text: 112.461 }
  ];



  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      if (chartData.length === 0) {
        setErrorMessage(t("calibration_chart_required"));
      } else {
        setErrorMessage("");
      }
    }
  }, [errors, chartData]);

  /** Post chart data functionality  */

  const postChartData = (data) => {
    if (chartData.length === 0) {
      setErrorMessage(t("calibration_chart_required"));
      return false;
    }
    data.chart = chartData;
    data.dealer = dealerID;
    setSpinner(true);
    postTankChart(data)
      .then((result) => {
        if (result.status === 200) {
          closeModel();
          updateList(result.data, "add");
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("chart_added_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
        }
      })
      .catch((err) => {
        setSpinner(false);
        if (
          err.response &&
          err.response.status === 400 &&
          err.response.data &&
          err.response.data.errors[0].msg === "INVALID_EXCEL"
        ) {
          toast.dark(<Toaster icon="error" message={t("invalid_excel")} />);
        } else {
          toast.dark(
            <Toaster icon="error" message={t("something_went_wrong")} />
          );
        }
      });
  };

  // useEffect(() => {
  //   let getElement = $(".drag-drop-chart svg:first");
  //   console.log(getElement);
  // }, [uploadError]);

  return (
    <>
      <Modal
        show={showModel}
        onHide={closeModel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form onSubmit={handleSubmit(postChartData)}>
          <Modal.Header className="bg-primary" closeButton>
            <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
              {t("add_new_chart")}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("chart_name")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="chartName"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.chartName}
                  />
                  {errors.chartName && (
                    <Form.Control.Feedback type="invalid">
                      {errors.chartName.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("type")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="tankType"
                    size="lg"
                    ref={register()}
                    isInvalid={!!errors.tankType}
                  >
                    <option value="">{t("no_type")}</option>
                    {TankTypes.map((type, i) => {
                      return (
                        <option key={i} value={type.value}>
                          {type.label}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {errors.tankType && (
                    <Form.Control.Feedback type="invalid">
                      {errors.tankType.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("manufacturer")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="manufacturer"
                    size="lg"
                    ref={register()}
                    isInvalid={!!errors.manufacturer}
                  >
                    <option value="">{t("no_manufacturer")}</option>
                    {TankManufactureTypes.map((item, i) => {
                      return (
                        <option key={i} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {errors.manufacturer && (
                    <Form.Control.Feedback type="invalid">
                      {errors.manufacturer.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("transducer_limit")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="transducerLimit"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.transducerLimit}
                  />
                  {errors.transducerLimit && (
                    <Form.Control.Feedback type="invalid">
                      {errors.transducerLimit.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={12}>
                <Form.Label className="text-capitalize" size="lg">
                  {t("calibration_chart")}{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <div className="csv-chart d-lg-flex d-md-flex px-1 py-3 mb-2">
                  <Col
                    lg={show && uploadError === false ? 9 : 12}
                    md={show && uploadError === false ? 8 : 12}
                    sm={12}
                    className="drag-drop-chart"
                  >
                    <CSVReader
                      onDrop={handleOnDrop}
                      onError={handleOnError}
                      addRemoveButton
                      onRemoveFile={handleOnRemoveFile}
                      accept=".csv"
                      config={config}
                      style={{
                        dropArea: {
                          borderColor: "#b4d1ff",
                          borderRadius: 0,
                          // alignItem: "end",
                          padding: 0,
                          // marginTop: "-20px",
                          height:
                            show && uploadError === false ? "70px" : "70px",
                          // Just to hide create with my mueller button
                          //borderStyle: "solid",
                          borderStyle: "none",
                          borderWidth:
                            show && uploadError === false ? "1px" : "0",
                        },
                        dropAreaActive: {
                          borderColor: "red",
                        },
                        dropFile: {
                          width: "100%",
                          background: "none",
                        },
                        fileSizeInfo: {
                          display: "none",
                        },
                        fileNameInfo: {
                          fontSize: 14,
                          marginTop: "12px",
                          marginBottom: "14px",
                        },
                        removeButton: {
                          color: "#212f46",
                        },
                        progressBar: {
                          backgroundColor: uploadError ? "red" : "#81e2a6",
                          width: "100%",
                          borderRadius: "50px",
                          boxShadow: "none",
                          height: "13px",
                        },
                      }}
                    >
                      <div className="d-flex text-info">
                        <div className="d-flex">
                          <UploadIcon className="mr-2" />
                        </div>
                        <div>
                          <span className="d-block">{t("drag_drop_file")}</span>
                          <span>{t("just_click_browse_file")}</span>
                        </div>
                      </div>
                    </CSVReader>
                    {uploadError === true && (
                      <div className="text-center">
                        <div className="text-danger">
                          {t("incorrect_file_format")}
                        </div>
                        <div className="px-lg-4 mx-lg-5">
                          {t("correct_file_format")}
                        </div>
                      </div>
                    )}
                  </Col>
                  {/* {show && uploadError === false && (
                    <Col lg={3} md={4} sm={12}>
                      <Button
                        className="font-weight-medium back-arrow-hover border-primary login-dark-text"
                        variant="outline-none"
                        size="lg"
                        type="button"
                        onClick={() => setOpen(true)}
                      >
                        {t("create_new_with")}
                        <Image src={MyMuellerLogo} className="ml-1 mb-1" />
                      </Button>
                    </Col>
                  )} */}
                </div>
                {errorMessage && (
                  <span type="valid" className="text-danger">
                    {errorMessage}
                  </span>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="pt-3 justify-content-between">
            <div className="sample-csv-download">
              <CSVLink data={data} headers={headers} filename={"Sample calibration file.csv"}>
                <div className="d-flex ">
                  <Image src={IconDownLoad} fluid className="mb-3" />
                  <p className="ml-2">{t("sample_calibration_chart")}</p>
                </div>
              </CSVLink>
            </div>
            <Button
              variant="primary"
              type="submit"
              className="text-capitalize"
              size="lg"
            >
              {spinner ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    role="status"
                    aria-hidden="true"
                    className="align-middle"
                  />
                  <span className="sr-only">Loading...</span>
                </>
              ) : (
                  t("create")
                )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {
        open && (
          <CreateMyMuellerChartForm
            showModel={open}
            closeModel={() => setOpen(false)}
            updatedMuellerData={updatedMuellerData}
          />
        )
      }
    </>
  );
});

export default AddNewChartForm;
