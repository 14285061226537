import React, { memo } from "react";
import ContentLoader from "react-content-loader";

const TankLoader = memo(() => {
  return (
    <div>
      <ContentLoader
        speed={2}
        width={190}
        height={370}
        viewBox="0 0 225 370"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="0" ry="0" width="225" height="75" />
        <rect x="0" y="90" rx="0" ry="0" width="225" height="75" />
        <rect x="0" y="180" rx="0" ry="0" width="225" height="75" />
        <rect x="0" y="270" rx="0" ry="0" width="225" height="75" />
      </ContentLoader>
    </div>
  );
});

export default TankLoader;
