/* Dutch Language */
export default {
  email_address: "E-mail",
  powered_by: "aangedreven door",
  send_a_link: "Stuur de link",
  register_email:
    "Voer uw register e-mail, sturen wij u een link naar u controleren sturen.",
  contact_administrator_text:
    "Uw myMueller gebruiker heeft nog in verband gebracht met de MilkSecure account. Neem contact op met de beheerder door het sturen van een e-mail.",
  home: "Huis",
  page_not_found: "Oeps, de pagina die u zoekt bestaat niet.",
  add_new_region: "toevoegen van nieuwe regio",
  search: "Zoeken",
  add_new_regional_manager: "toevoegen van nieuwe regiomanager",
  data_not_available: "Gegevens niet beschikbaar",
  regionId: "regionId",
  region_name: "regionaam",
  regional_managers: "regiomanagers",
  user_detail: "profielvoorkeuren",
  change_password: "wachtwoord wijzigen",
  region: "Regio",
  phone_number: "telefoonnummer",
  dealers: "dealers",
  independent_dairies: "onafhankelijke zuivelfabrieken",
  add_new_dealer: "toevoegen van nieuwe dealer",
  add_new_super_admin: "add new super admin",
  delete_record_msg:
    "Waarschuwing!! de gegevens van de gebruiker verwijdert, worden alle gegevens van de zuivelfabrieken en tanks in uw bedrijf geïnstalleerd in gevaar brengen. U zult niet in staat zijn om de informatie terug te halen. Weet je zeker dat je door wilt gaan?",
  edit_super_admin: "bewerk super admin",
  edit_region: "bewerkingsregio",
  region_added_successfully: "regio succesvol toegevoegd",
  something_went_wrong: "er is iets fout gegaan",
  region_updated_successfully: "regio met succes bijgewerkt",
  region_deleted_successfully: "regio succesvol verwijderd",
  super_admin_deleted_successfully: "super admin succesvol verwijderd",
  super_admin_added_successfully: "super admin succesvol toegevoegd",
  super_admin_updated_successfully: "super admin bijgewerkt",
  regional_manager_added_successfully: "regiomanager succesvol toegevoegd",
  regional_manager_deleted_successfully: "regiomanager succesvol verwijderd",
  regional_manager_updated_successfully: "regiomanager bijgewerkt",
  edit_regional_manager: "bewerk regiomanager",
  dealer_added_successfully: "dealer succesvol toegevoegd",
  dealer_deleted_successfully: "dealer succesvol verwijderd",
  edit_dealer: "bewerk dealer",
  dealer_updated_successfully: "dealer bijgewerkt",
  edit_independent_dairy: "bewerk onafhankelijke zuivel",
  independent_dairy_deleted_successfully:
    "onafhankelijke zuivel succesvol verwijderd",
  independent_dairy_updated_successfully: "onafhankelijke zuivel bijgewerkt",
  producer_id: "producent id",
  dairy_farm: "zuivel boerderij",
  dairy_farms: "melkveebedrijven",
  technicians: "technici",
  add_new_dairy_farm: "toevoegen van nieuwe melkveebedrijf",
  technician_deleted_successfully: "technicus succesvol verwijderd",
  add_new_technician: "toevoegen van nieuwe technicus",
  technician: "technicus",
  edit_technician: "bewerk technicus",
  technician_name: "technicus naam",
  assign_dairies: "assign zuivelfabrieken",
  technician_added_successfully: "technicus succesvol toegevoegd",
  dairy_updated_successfully: "zuivel bijgewerkt",
  dairy_deleted_successfully: "zuivel succesvol verwijderd",
  technician_updated_successfully: "technicus met succes bijgewerkt",
  old_password: "Oud Wachtwoord",
  re_enter_new_password: "voer nieuw wachtwoord opnieuw in",
  changed_password_message: "wachtwoord succesvol ingesteld.",
  password_reset_link_invalid: "password reset koppeling ongeldig",
  required_field_error: "Dit veld is verplicht.",
  password_length_error: "Het wachtwoord moet 8 tekens lang",
  user_not_registered: "De gebruiker is niet geregistreerd bij deze e-mail",
  region_id_required: "Regio Legitimatie is verplicht.",
  region_name_error: "Region naam is verplicht.",
  name_error: "Naam is vereist.",
  email_error: "E-mail is verplicht.",
  region_error: "Region is vereist.",
  password_error: "Een wachtwoord is verplicht.",
  dealer_name: "Dealer naam is verplicht.",
  dealer_email: "Dealer e-mailadres is verplicht.",
  producer_id_error: "Producer Legitimatie is verplicht.",
  dairy_name_error: "Dairy naam is verplicht.",
  dairy_address: "Dairy-mailadres is verplicht.",
  dairy_city: "Dairy stad is vereist.",
  dairy_state: "Dairy staat is vereist.",
  technician_name_error: "Technician naam is verplicht.",
  technician_email: "Technician e-mailadres is verplicht.",
  login_screen_text: "Log in op jouw account",
  PMC_Login: "Login met",
  forgot_password: "Kan niet inloggen?",
  or: "of",
  privacy_policy: "privacybeleid",
  terms_and_conditions: "Voorwaarden",
  data_policy: "gegevens beleid",
  end_user_license_agreement: "End User License Agreement",
  contact_administrator: "Contact Beheerder",
  are_you_sure: "Weet je zeker dat je door wilt gaan?",
  tank_mode: "Tank's Mode",
  last_agitated: "laatste Agitated",
  live_data: "Actuele gegevens",
  re_register: "Re-Register",
  tank_updated: "Tank bijgewerkt succesvol",
  tank_deleted: "Tank succesvol verwijderd",
  edit_dairy_farm: "Bewerken daity boerderij",
  create: "creëren",
  update: "bijwerken",
  regional_manager: "regiomanager",
  independent_dairy: "onafhankelijke zuivel",
  language_preferences: "Taalvoorkeuren",
  unit_preference: "Unit Voorkeuren",
  temperature_unit_preference: "Temperatuureenheid",
  pressure_unit: "Drukeenheid",
  volume_unit: "Volume-eenheid",
  alert_preference: "Alert Voorkeuren",
  language_dropdown: "Taal Dropdown",
  details_updated_successfully: "Gebruikersgegevens succesvol bijgewerkt",
  regions: "Regio's",
  basic_details: "Basic Details",
  log_out: "Uitloggen",
  login: "Inloggen",
  password: "Wachtwoord",
  enter_email: "E-mail",
  actions: "Acties",
  edit: "Bewerken",
  delete: "Verwijderen",
  add: "Toevoegen",
  super_admin: "Superadmin",
  city: "Stad",
  state: "status",
  county: "County / District",
  address: "Adres",
  new_password: "Nieuw wachtwoord",
  reset_password: "Wachtwoord resetten",
  current_password_wrong: "Huidige wachtwoord is incorrect.",
  link_sent_successfully:
    "De link om het wachtwoord te resetten is naar uw e-mail verstuurd",
  no_data: "Geen data beschikbaar.",
  back_to_login: "Terug naar Inloggen",
  tank_not_registered: "De tank is niet geregistreerd.",
  the_dairy_owner_has_not_registered: "De zuiveleigenaar heeft zich niet geregistreerd.",
  tank_added: "Tank succesvol toegevoegd.",
  name: "Naam",
  email: "E-mail",
  phone: "Telefoon",
  is_registered: "Geregistreerd",
  dairies: "Zuivelfabrieken",
  unable_to_connect_server:
    "Niet mogelijk om te verbinden met de server. Probeer het alstublieft later opnieuw.",
  loading: "Een moment geduld, aan het laden...",
  username_not_found: "Gebruikersnaam niet gevonden.",
  tank_temperature: "Tanktemperatuur",
  tank_volume: "Tankvolume",
  pipeline_temperature: "Pipeline Temperatuur",
  add_new_tank: "Nieuwe tank toevoegen",
  num_evcs: "No. van de EVC(s)",
  dairy_name: "Naam zuivel",
  tank_name: "Naam tank",
  clear: "Doorzichtig",
  sms: "SMS",
  confirm_new_password: "Nieuw wachtwoord bevestigen",
  confirm_password_required: "Bevestig wachtwoord vereist",
  dairy_added_successfully: "Zuivel succesvol toegevoegd.",
  dealer: "handelaar",
  enter_a_new_password: "Voer een nieuw wachtwoord",
  not_register: "Niet geregistreerd",
  password_min: "Wachtwoord moet minimaal 8 tot 16 tekens bevatten",
  password_not_match:
    "Nieuw wachtwoord en Bevestig wachtwoord zijn verschillend.",
  password_regex_message: "Wachtwoord is niet geldig",
  password_required: "Een wachtwoord is verplicht.",
  resend_link: "resend koppeling",
  send_invitation_link: "Accountactivering Link verzonden",
  sent: "verzonden",
  set_password: "stel een wachtwoord in",
  set_validation_message:
    "Gebruik wachtwoord tussen de 8-16 karakters waarvan ten minste 1 numerieke karakter, 1 speciaal karakter, één hoofdletter en één kleine letters zonder ruimte op te nemen.",
  user_has_been_not_registered: "Gebruiker is niet geregistreerd nog",
  milk_pipeline_temperature: "Melk Pipeline Temp.",
  temperature_sensor_3: "Temperatuursensor 3",
  temperature_sensor_4: "Temperatuursensor 4",
  evc_temperature: "EVC Temperatuur",
  subcool_temperature: "Temperatuur sub-koeling",

  Cooling: "Koelen",
  BU_Cool: "Bodem Unit (BU afgekort) Koelen",
  Washing: "Reinigen",
  NULL: "NUL",
  INIT: "INIT",
  Power_Fail_Recovery: "Macht Fail Recovery",
  Standby: "Stand-by",
  Sample_Agitate: "Roeren monstername",
  Editing_Parameters: "Parameters aanpassen",
  Diagnostic: "Diagnostiek",
  Setup: "Instellingen",
  BU_Delay_Start: "Bodem Unit (BU afgekort) koel vertraging",
  AU_Bottom_Delay_Start: "AU Bottom Delay Start",
  AU_Upper_Delay_Start: "AU Upper Delay Start",
  AU_Cool: "Alle Units (AU afgekort) Koelen",
  BU_Delay_BU_Pulsed_Cool:
    "Bodem Unit (BU afgekort) koel vertraging Bodem Unit (BU afgekort) Pulserende koelen",
  AU_Delay_BU_Pulsed_Cool:
    "Alle Units (AU afgekort) koel vertraging Bodem Unit (BU afgekort) Pulserende koelen",
  FW: "FW",
  AC: "AC",
  SC: "SC",
  Rinse_1_WWB: "Spoeling 1 (WWB)",
  Delay_WWB: "Uitstel (WWB)",
  Rinse_2_WWB: "Spoeling 2 (WWB)",
  Detergent_Wash_WWB: "Alkalische reiniging (WWB)",
  Rinse_3_WWB: "Spoeling 3 (WWB)",
  Acid_Rinse_WWB: "Zuur spoeling (WWB)",
  Post_Rinse_WWB: "Naspoelen (WWB)",
  Complete_WWB: "Voltooid (WWB)",
  Rinse_1_ADB: "Spoeling 1 (ADB)",
  Delay_ADB: "Uitstel (ADB)",
  Acid_Wash_ADB: "Säure Reinigung (ADB)",
  Rinse_2_ADB: "Spoeling 2 (ADB)",
  Detergent_Wash_ADB: "Alkalische reiniging (ADB)",
  Rinse_3_ADB: "Spoeling 3 (ADB)",
  Acid_Rinse_ADB: "Zuur spoeling (ADB)",
  Post_Rinse_ADB: "Naspoelen (ADB)",
  Complete_ADB: "Voltooid (ADB)",
  SC_Complete_ADB: "Desinfectie Cyclus (afgekort) Voltooid (ADB)",
  Rinse_1_MMB: "Spoeling 1 (MMB)",
  Delay_MMB: "Uitstel (MMB)",
  Rinse_2_MMB: "Spoeling 2 (MMB)",
  Detergent_Wash_MMB: "Alkalische Reiniging (MMB)",
  Rinse_3_MMB: "Spoeling 3 (MMB)",
  Acid_Rinse_MMB: "Zuur spoeling (MMB)",
  Sanitize_Cycle_MMB: "Desinfectie cyclus (MMB)",
  Pre_Rinse_MMB: "Voorspoelen (MMB)",
  Complete_MMB: "Voltooid (MMB)",
  SC_Complete_MMB: "Desinfectie Cyclus (afgekort) Voltooid (MMB)",

  english: "English",
  Spanish: "Español",
  Dutch: "Nederlands",
  CanadianFrench: "Français canadien",
  Japanese: "日本語",
  German: "Deutsche",
  version: "Versie",
  farm_users: "Farm Gebruikers",
  alerts: "Waarschuwingen",
  add_new_farm_users: "toevoegen van nieuwe farm gebruiker",
  role: "Rol",
  user_role: "Aard van het bedrijf Gebruikers",
  farm_users_added_successfully: "farm gebruiker succesvol toegevoegd.",
  farm_users_deleted_successfully: "farm gebruiker succesvol verwijderd.",
  user_create_different_role_error:
    "Er is al een gebruiker gemaakt met een andere rol. Neem contact op met de site admin om dit op te lossen.",
  farm_users_updated_successfully: "Farm gebruiker zich met succes bijgewerkt.",
  close: "Dichtbij",
  assign: "Toewijzen",
  email_already_exists: "Gebruiker met de genoemde e-ID bestaat al.",
  user_create_different_dairy_to_assign:
    "Er is al een gebruiker aangemaakt die rol. Wil je hem toevoegen / haar om deze zuivel?",

  alert_history_error: "Fout bij geschiedenis ophalen alert",
  can_not_login: "Kan niet inloggen?",
  new_password_required: "Nieuw wachtwoord vereist is.",
  old_password_required: "Oud wachtwoord is vereist.",
  re_enter_new_password_required: "Voer nieuw wachtwoord vereist is.",
  by_signing_up:
    "Door u aan te melden, gaat u akkoord met ons hieronder gelinkte privacybeleid.",
  resend_email_link_expired:
    "Uw link is verlopen neem dan gerust contact op met de beheerder",
  configure_alerts: "meldingen configureren",
  add_alert: "add alert",
  tank_name: "tank Naam",
  alert_deleted_successfully: "alert succesvol verwijderd",
  alert_not_found: "Geen aangepaste meldingen gevonden.",
  sensor_name: "naam sensor",
  sensor_value: "sensor waarde",
  duration: "Looptijd",
  tank_state: "tank staat",
  condition: "Staat",
  delay: "Vertraging",
  minutes: "Minuten",
  sensor_value_error: "Sensor is vereist.",
  delay_value_error: "Vertraging is vereist.",
  duration_value_error: "Duur is vereist.",
  duplicate_email: "Duplicate E-mail id.",
  invalid_email: "E-mail-formaat is verkeerd.",
  alert_added_successfully: "alert succesvol toegevoegd",
  maximum_email: "U kunt niet meer dan 10 e-mails toe te voegen.",
  contact_number_error: "Contact nummer is vereist.",
  duplicate_contact_number: "Duplicate Contact nummer",
  maximum_contact_number: "U kunt niet meer dan 10 nummers toe te voegen.",
  contact_digit: "Contact nummer moet 10 cijfers.",
  config_alert:
    "Vul tenminste één telefoonnummer of een e-mail om de waarschuwing te configureren.",
  update_form_button: "Bijwerken",
  live_alert_not_found: "Er zijn geen rechtstreekse alarm.",
  live_alerts: "live alerts",
  resolved_alerts: "Vastbesloten alerts",
  live_alert_history_error: "Fout bij het ophalen van live-alert geschiedenis",
  loading_text: "Please wait, loading ...",
  resolved_alert_not_found: "Er zijn geen opgelost waarschuwingen",
  triggered: "Veroorzaakt",
  resolved: "Vastbesloten",
  show_annotation: "Toon annotatie",
  hide_annotation: "verbergen annotatie",
  clear: "Doorzichtig",
  submit: "voorleggen",
  export_csv: "export CSV",
  add_annotation_instruction:
    "Plaats de muisaanwijzer op de grafiek en klik op de plaats waar u de annotatie wilt toevoegen.",
  from_date_time: "Van",
  to_date_time: "Naar",
  select_date: "Selecteer een datum.",
  select_from_date: "Selecteer Vanaf datum.",
  select_to_date: "Selecteer Tot op heden.",
  date_not_same: "Datum kan niet hetzelfde zijn.",
  to_date_greater_than_today: "Tot op heden kan niet groter zijn dan vandaag.",
  from_date_greater_than_today:
    "Vanaf datum kan niet groter zijn dan tot nu toe.",
  select_date_range: "Selecteer een datum bereik tot maximaal 31 dagen.",
  tank_data_not_available:
    "Tank apparaat heeft geen gegevens tussen deze data.",
  download_failed: "Download mislukt.",
  file_downloaded: "Uw bestand wordt gedownload.",
  select_date_time: "Selecteer een datum en tijd",
  regional_managers_error: "Regiomanagers is vereist.",
  independent_dealers: "Onafhankelijke dealer",
  cancel_re_register: "Annuleren Re-Register",
  re_registers_successfully: "Re-Register succes",
  evc_error: "EVC is vereist.",
  cancel_registers_successfully: "Annuleren Succesvol Re-register",
  lsp: "Lage kantdruk",
  sst: "Verzadigde zuigtemperatuur",
  hsp: "Hoge zijdruk",
  sct: "Condensatietemperatuur",
  no_dairy_farm_data_available:
    "Er zijn dergelijke gegevens niet voor deze melkveebedrijven.",
  add_custom_alert: "Voeg aangepaste waarschuwingen toe",
  farm_user: "Boerdergebruiker",
  calibration_charts: "Kalibratiekaarten",
  add_new_chart: "Voeg nieuwe grafiek toe",
  chart_name: "Grafieknaam",
  type: "Type",
  manufacturer: "Fabrikant",
  calibration_chart: "Kalibratiekaart",
  create_myMueller_chart: "Maak MyMueller-grafiek",
  horizontal_inside_diameter: "Tank horizontale binnendiameter",
  vertical_inside_diameter: "Tank verticale binnendiameter",
  shell_length: "Shell lengte (naad tot naad)",
  shell_material_thickness: "Shell materiaaldikte",
  tank_pitch_foot: "Tank toonhoogte per voet (inches)",
  head_material_thickness: "Hoofd materiaaldikte",
  head_inside_knuckle: "Hoofd binnenste knuckle straal",
  height_increment_value: "De hoogte-waarde van de hoogte",
  right_head_material_thickness: "Rechter hoofd materiaal dikte",
  right_head_inside_knuckle: "Rechter hoofd in de knuckle-straal",
  create_chart: "Grafiek maken",
  transducer_limit: "Transducer limiet",
  calibration_deleted_successfully: "Kalibratiekaart succesvol verwijderd.",
  chart_name_required: "Grafieknaam is vereist.",
  tank_type_required: "Type is vereist.",
  manufacture_required: "Vervaardiging is vereist.",
  transducer_limit_required: "Transducerlimiet is vereist.",
  transducer_limit_invalid: "Ongeldige transducerlimiet.",
  tank_detail: "Tankdetail",
  calibration: "Kalibratie",
  create_new_with: "Creëer nieuw met",
  tank_height: "Tankhoogte",
  inch: "inch",
  gal_usa: "Gal USA",
  volume_increment: "Incrementeel volume",
  accumulated_volume: "Totale volume",
  horizontal_inside_diameter_required:
    "Tank horizontale binnendiameter is vereist.",
  vertical_inside_diameter_required:
    "Tank verticale binnendiameter is vereist.",
  shell_length_required: "De lengte van de schaal is vereist.",
  shell_material_thickness_required: "Shell-materiaaldikte is vereist.",
  tank_pitch_foot_required: "Tankhoogte per voet (inch) is vereist.",
  head_material_thickness_required: "Hoofdmateriaaldikte is vereist.",
  head_inside_knuckle_required: "Hoofd binnen Knuckle-straal is vereist.",
  height_increment_value_required: "De waarde van de hoogte is vereist.",
  right_head_material_thickness_required:
    "Dikte van de rechter hoofdmateriaal is vereist.",
  right_head_inside_knuckle_required:
    "Rechtskop binnen Knuckle-straal is vereist.",
  drag_drop_file: "Sleep uw bestand hier of neer",
  just_click_browse_file: "Klik gewoon om bestanden te bladeren",
  chart_added_successfully: "Grafiek succesvol toegevoegd.",
  invalid_excel: "Geupload verkeerde bestandsindeling",
  calibration_chart_required: "Kalibratiekaart is vereist.",
  special_instruction: "Speciale instructies",
  accumulate_height_csv_text: "Hoogte (inch)",
  accumulate_volume_csv_text: "Geaccumuleerd volume (Gal US)",
  volume_increment_csv_text: "Volume-increment (GAL US)",
  sample_calibration_chart: "Download kalibratiekaartsjabloon",
  dairy_admin_exists: "Dairy Admin gaat al uit met deze zuivel.",
  all_tanks: "Alle tanks",
  producerId_max_length_error: "Producer ID mag niet langer zijn dan 50 tekens",
  producerId_alphanumeric_error: "Alleen alfanumerieke tekens toegestaan",
  invalid_email_error: 'Vul een geldig e-mailadres in',
  Agitating: 'Agitatie',
  AGITATE: "Agiteren",
  COOL: "KOEL",
  DIAGNOSTIC: "Diagnostisch",
  SETTINGS: "INSTELLINGEN",
  STANDBY: "STANDBY",
  WASH: "WASSEN",
  alert: "Alert",
  tank: "Tank",
  tanks: "Tanks",
  no_manufacturer: "Geen fabrikant",
  mueller: "Mueller",
  non_mueller: "Non-Mueller",
  no_type: "Geen type",
  horizontal: "Horizontaal",
  vertical: "Verticaal",
  dairy_owner: "Zuivelleigenaar",
  dairy_operator: "Zuiveloperator",
  milk_hauler: "Melkvervoerder",
  sanitarian: "Sanitair",
  HSP: "HSP",
  LSP: "LSP",
  valve_position: "Klepstand",
  less_than: "Minder dan",
  greater_than: "Groter dan",
  cool: "Koel",
  wash: "Wassen",
  standby: "Standby",
  BOTTOM_UNIT_COOL: "Onderste unit cool",
  ALL_UNIT_COOL: "Alle eenheid cool",
  BU_DELAY_BU_PULSE_COOL: "Bu delay bu pulse cool",
  AU_DELAY_BU_PULSED_COOL: "Au delay bu pulsed cool",
  WWB_FULL_WASH_RINSE_1: "WWB Volwassen Spoel 1",
  WWB_FULL_WASH_DELAY: "WWB volledige wasvertraging",
  WWB_FULL_WASH_RINSE_2: "WWB Volwassen Spoel 2",
  WWB_FULL_WASH_DETERGENT_WASH: "WWB volledige wasmiddel wassen",
  WWB_FULL_WASH_RINSE_3: "WWB volledige wasspoeling 3",
  WWB_FULL_WASH_ACID_RINSE: "WWB volledige waszuurspoeling",
  WWB_FULL_WASH_RINSE_4: "WWB Full Wash Rinse 4",
  WWB_SANITIZE_CYCLE: "WWB sanitize cyclus",
  WWB_ACID_CYCLE_PRE_RINSE: "WWB Acid Cycle Pre Rinse",
  WWB_ACID_CYCLE_ACID_RINSE: "WWB-zuurcycluszuurspoeling",
  WWB_ACID_CYCLE_POST_RINSE: "WWB Acid Cycle Post Rinse",
  WWB_FULL_WASH_COMPLETE: "WWB volledige was voltooid",
  WWB_ACID_CYCLE_COMPLETE: "WWB-zuurcyclus compleet",
  WWB_SANITIZE_CYCLE_COMPLETE: "WWB sanitize cyclus compleet",
  ADB_FULL_WASH_RINSE_1: "ADB Volwassen Spoel 1",
  ADB_FULL_WASH_DELAY: "ADB volledige wasvertraging",
  ADB_FULL_WASH_ACID_WASH: "ADB volledige wassen",
  ADB_FULL_WASH_RINSE_2: "ADB Volwassen Spoel 2",
  ADB_FULL_WASH_DETERGENT_WASH: "ADB volledige wasmiddel wassen",
  ADB_FULL_WASH_RINSE_3: "ADB Volwassen Spoel 3",
  ADB_FULL_WASH_ACID_RINSE: "ADB volledige waszuurspoeling",
  ADB_FULL_WASH_RINSE_4: "ADB Volwassen Spoel 4",
  ADB_SANITIZE_CYCLE: "ADB sanitize cyclus",
  ADB_ACID_CYCLE_PRE_RINSE: "Adb Acid Cycle Pre Rinse",
  ADB_ACID_CYCLE_ACID_RINSE: "ADB-zuurcycluszuurspoeling",
  ADB_ACID_CYCLE_POST_RINSE: "ADB ACID CYCLE POST RINSE",
  ADB_FULL_WASH_COMPLETE: "ADB volledige was voltooid",
  ADB_ACID_CYCLE_COMPLETE: "ADB ACID CYCLE COMPLETE",
  ADB_SANITIZE_CYCLE_COMPLETE: "Adb sanitize cyclus compleet",
  MMB_FULL_WASH_RINSE_1: "Mmb volledige wasspoeling 1",
  MMB_FULL_WASH_DELAY: "MMB volledige wasvertraging",
  MMB_FULL_WASH_RINSE_2: "Mmb volledige wasspoeling 2",
  MMB_FULL_WASH_DETERGENT_WASH: "MMB volledige wasmiddel wassen",
  MMB_FULL_WASH_RINSE_3: "MMB Volwassen Spoel 3",
  MMB_FULL_WASH_ACID_RINSE: "MMB volledige waszuurspoeling",
  MMB_SANITIZE_CYCLE: "Mmb sanitize cyclus",
  MMB_ACID_CYCLE_PRE_RINSE: "MMB ACID CYCLE PRE RINSE",
  MMB_ACID_CYCLE_ACID_RINSE: "MMB-zuurcycluszuurspoeling",
  MMB_FULL_WASH_COMPLETE: "MMB-zuurcyclus compleet",
  MMB_ACID_CYCLE_COMPLETE: "MMB ACID CYCLE COMPLETE",
  MMB_SANITIZE_CYCLE_COMPLETE: "Mmb sanitize cyclus compleet",
  EVC_INPUT_REG_TEMP_1: "EVC-temperatuursensor 1",
  EVC_INPUT_REG_TEMP_2: "EVC-temperatuursensor 2",
  EVC_INPUT_REG_TEMP_3: "EVC-temperatuursensor 3",
  EVC_INPUT_REG_SUBCOOL_TEMP: "Subcooltemperatuur",
  EVC_INPUT_REG_HIGH_SIDE_PRESSURE: "HSP",
  EVC_INPUT_REG_LOW_SIDE_PRESSURE: "LSP",
  EVC_INPUT_REG_HI_SAT_TEMP: "Hoge verzadigingstemperatuur",
  EVC_INPUT_REG_LO_SAT_TEMP: "Lage verzadigingstemperatuur",
  EVC_INPUT_REG_FAN_OUTPUT: "Ventilator snelheid",
  HPF_INPUT_REG_TEMP1: "Tanktemperatuur",
  HPF_INPUT_REG_TEMP2: "Melkpijpleidingtemperatuur",
  HPF_INPUT_REG_TEMP3: "Temperatuur 3",
  HPF_INPUT_REG_TEMP4: "Temperatuur 4",
  HPF_INPUT_REG_VOLUME_4TO20MA_READING: "Tank Volume",
};
