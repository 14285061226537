import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CountryPhoneInput from "../../components/CountryPhoneInput";
import {
  addRegionalManagers as addDealer,
  getUser,
} from "../../actions/actions";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../../context/auth";
import * as yup from "yup";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { useParams } from "react-router-dom";
const AddDealerForm = ({ showModel, closeModel, updateList, regions }) => {
  const { authUser } = useAuthContext();
  const { t } = useTranslation();
  const [contact, setContact] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const { regionID } = useParams();
  const user = getUser();
  const schema = yup.object().shape({
    name: yup.string().required(t("dealer_name")),
    email: yup.string().max(50).email(t('invalid_email_error')).required(t("dealer_email")),
    region: yup.string().required(t("region_error")),
    contact: yup.lazy((value) => {
      if (
        value &&
        Object.values(value).some(
          (v) => !(v === null || v === undefined || v === "")
        )
      ) {
        return yup.string().min(10, t("contact_digit"));
      }
      return yup.mixed().notRequired();
    }),
  });

  const { register, handleSubmit, errors, setValue, reset } = useForm({
    validationSchema: schema,
  });

  const postDealer = (user) => {
    let changedContact = selectedContact.trim();
    user.contact = changedContact === "" ? changedContact : contact;
    addDealer({
      ...user,
      createdBy: authUser.user.id,
      role: "Dealer",
    })
      .then((result) => {
        if (result.status === 200) {
          closeModel();
          updateList(result.data, "add");
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("dealer_added_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
        }
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status === 400 &&
          err.response.data &&
          err.response.data.code === "E_UNIQUE"
        ) {
          toast.dark(
            <Toaster icon="error" message={t("email_already_exists")} />
          );
        } else {
          toast.dark(
            <Toaster icon="error" message={t("something_went_wrong")} />
          );
        }
      });
  };

  /** Display only selected regions in regionalManager role */

  const regionalData = regions.find((i) => i.id === regionID);
  useEffect(() => {
    if (regionalData && user.role === "RegionalManager") {
      reset({
        region: regionalData?.id,
      });
    }
  }, []);

  return (
    <Modal
      show={showModel}
      onHide={closeModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Form onSubmit={handleSubmit(postDealer)}>
        <Modal.Header className="bg-primary" closeButton>
          <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
            {t("dealer")}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Row>
            <Col lg={6}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="text-capitalize" size="lg">
                  {t("name")} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="name"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.name}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-capitalize" size="lg">
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="email"
                  size="lg"
                  type="email"
                  ref={register()}
                  isInvalid={!!errors.email}
                />
                {errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="formBasicPhone">
                <Form.Label className="text-capitalize" size="lg">
                  {t("phone_number")}
                </Form.Label>

                <CountryPhoneInput
                  onChangeVal={(val, country, e, formattedValue) => {
                    let contactNumber =
                      formattedValue &&
                      formattedValue.slice(country.dialCode.length + 1);
                    setContact(formattedValue);
                    setSelectedContact(contactNumber);
                    let formContactVal =
                      val &&
                      val.match(/\d/g).join("").slice(country.dialCode.length);
                    setValue("contact", formContactVal, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                  phoneInputValue={contact}
                />
                <Form.Control
                  name="contact"
                  size="lg"
                  type="text"
                  ref={register()}
                  isInvalid={!!errors.contact}
                  className="d-none"
                />
              </Form.Group>
              {errors.contact && (
                <div className="custom-invalid-feedback text-danger">
                  {errors.contact.message}
                </div>
              )}
            </Col>
            <Col lg={6}>
              {user.role !== "RegionalManager" ? (
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("region")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="region"
                    size="lg"
                    ref={register()}
                    isInvalid={!!errors.region}
                  >
                    <option value="">No Region</option>
                    {regions.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}({item.regionId})
                      </option>
                    ))}
                  </Form.Control>
                  {errors.region && (
                    <Form.Control.Feedback type="invalid">
                      {errors.region.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              ) : (
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("region")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="region"
                    size="lg"
                    ref={register()}
                    disabled
                  >
                    <option value="">No Region</option>
                    {regions.map((item) => (
                      <option
                        value={item.id}
                        key={item.id}
                        defaultValue={regionalData?.id === item.id}
                      >
                        {item.name}({item.regionId})
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button
            variant="primary"
            type="submit"
            className="text-capitalize"
            size="lg"
          >
            {t("create")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

AddDealerForm.propTypes = {
  showModel: PropTypes.bool,
  closeModel: PropTypes.func.isRequired,
  updateList: PropTypes.any,
};

AddDealerForm.defaultProps = {
  showModel: false,
};
export default AddDealerForm;
