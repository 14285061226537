import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Redirect, Link } from "react-router-dom";
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  Image,
  Spinner,
} from "react-bootstrap";
import * as yup from "yup";
import MuellerLogo from "../assets/images/Mueller_dark_logo.svg";
import MilkSecureLogo from "../assets/images/MilkSecure_Logo_Two_Color.svg";
import { useAuthContext } from "../context/auth";
import { forgotPassword } from "../actions/actions";
import { toast } from "react-toastify";
import Toaster from "../components/Toaster";
import Slider from "../components/Slider";
import PrivacyPolicyLinks from "../components/PrivacyPolicyLinks";
import LanguageDropdown from "../components/LanguageDropdown";

const schema = yup.object().shape({
  email: yup.string().required().email(),
});

const ForgotPasswordPage = memo(() => {
  const [spinner, setSpinner] = useState(false);
  const { authUser, setAuthUser } = useAuthContext();
  const { t } = useTranslation();
  const { register, handleSubmit, errors, reset } = useForm({
    validationSchema: schema,
  });

  const postForgotPassword = ({ email }) => {
    setSpinner(true);
    forgotPassword(email)
      .then((result) => {
        setSpinner(false);
        if (result.status === 200) {
          setAuthUser(result.data.user, result.data.token);
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("link_sent_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
          reset();
        }
      })
      .catch((e) => {
        console.log(e);
        toast.dark(
          <Toaster
            className="text-capitalize"
            icon="error"
            message={t("user_not_registered")}
          />,
          { autoClose: 3000, hideProgressBar: true }
        );
        setSpinner(false);
      });
  };

  // Redirect to referrer link or "/"" if already logged in
  // TODO: Implement redirect to referer link after login
  // const referer = props.location.state.referer || "/";
  const referer = "/";
  if (authUser.token) {
    return <Redirect to={referer} />;
  }

  return (
    <div className="bg-login-light">
      <Container fluid="xl" fluid className="min-vh-100">
        <Row className="min-vh-100">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            xl={6}
            className="d-none d-sm-none d-md-none bg-dark-secondary align-items-start d-lg-flex justify-content-lg-start justify-content-xl-center"
          >
            <Slider />
          </Col>
          <Col
            className="m-xl-auto mx-lg-auto"
            xs={{ span: 10, offset: 1 }}
            sm={{ span: 8, offset: 2 }}
            md={{ span: 8, offset: 2 }}
            lg={{ span: 4, offset: 2 }}
            xl={{ span: 4, offset: 2 }}
          >
            <div className="text-center my-lg-4 my-md-5  logo-top">
              <Image src={MuellerLogo} fluid className="mueller-logo" />
            </div>
            <div className="shadow bg-white px-lg-5 px-4 py-1 mt-lg-5">
              <div className="lead text-center mt-4">{t("can_not_login")}</div>
              <div className="mx-lg-3 px-xl-5 px-lg-2  px-3 py-2 mb-3 text-dark-gray font-weight-normal text-center">
                {t("register_email")}
              </div>
              <Form
                className="mb-lg-5 mb-5"
                onSubmit={handleSubmit(postForgotPassword)}
              >
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    name="email"
                    size="lg"
                    type="email"
                    placeholder={t("enter_email")}
                    ref={register()}
                    isInvalid={!!errors.email}
                  />
                  {errors.email && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Button
                  className="mt-xl-5 mt-lg-4"
                  variant="primary"
                  size="lg"
                  type="submit"
                  block
                >
                  {spinner ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span>
                    </>
                  ) : (
                      t("send_a_link")
                    )}
                </Button>
                <Link to="/">
                  <Button
                    className="mt-4 back-arrow-hover border-primary text-primary"
                    variant="outline-none"
                    size="lg"
                    type="button"
                    block
                  >
                    {t("back_to_login")}
                  </Button>
                </Link>
              </Form>
            </div>
            <div className="text-center mt-lg-5 mt-3 mb-2">
              <p className="text-capitalize mb-3">{t("powered_by")}</p>
              <Image src={MilkSecureLogo} />
            </div>
            <div className="mt-4 text-center">
              <PrivacyPolicyLinks />
            </div>
            <div className="mt-0 text-center">
              <LanguageDropdown />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

export default ForgotPasswordPage;
