import React, { memo } from "react";
import PropTypes from "prop-types";
import { Form as BootstrapForm, Image } from "react-bootstrap";
import styled from "styled-components";
import SearchImage from "../../assets/images/search.svg";

const SearchIcon = styled.span`
  top: 6px;
  right: 28px;
  position: absolute;
`;
const Search = memo(({ placeholder, onChange, className }) => {
  return (
    <BootstrapForm.Group className="mb-0">
      <SearchIcon>
        <Image src={SearchImage} fluid />
      </SearchIcon>
      <BootstrapForm.Control
        type="text"
        name="search"
        className={className}
        placeholder={placeholder}
        onChange={onChange}
      />
    </BootstrapForm.Group>
  );
});

Search.propTypes = {
  /** Search field textbox placeholder */
  placeholder: PropTypes.string,
  /** Search field event function */
  onChange: PropTypes.func.isRequired,
  /** Search field className */
  className: PropTypes.string,
};

Search.defaultProps = {
  placeholder: "search",
  className: "",
};

export default Search;
