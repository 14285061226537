import React, { memo } from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

const GraphLoaderWrapper = styled.div`
  padding: 50px 30px 30px 30px;
  margin: 20px 0;
  border: 1px solid #ecebeb;
`;
const GraphLoader = memo(() => {
  return (
    <GraphLoaderWrapper>
      <ContentLoader
        speed={2}
        width={810}
        height={250}
        viewBox="0 0 810 250"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="10" y="50" rx="0" ry="0" width="760" height="2" />
        <rect x="10" y="80" rx="0" ry="0" width="760" height="2" />
        <rect x="10" y="110" rx="0" ry="0" width="760" height="2" />
        <rect x="10" y="140" rx="0" ry="0" width="760" height="2" />
        <rect x="10" y="170" rx="0" ry="0" width="760" height="2" />
      </ContentLoader>
    </GraphLoaderWrapper>
  );
});

export default GraphLoader;
