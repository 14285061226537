/* Spanish Language */
export default {
  email_address: "Correo electrónico",
  powered_by: "energizado por",
  send_a_link: "enviar un enlace",
  register_email:
    "introduzca su email registro, le enviaremos un enlace para verificar su identidad.",
  contact_administrator_text:
    "Su usuario myMueller todavía no se ha relacionado con la cuenta MilkSecure. Por favor, póngase en contacto con el administrador mediante el envío de un correo electrónico.",
  home: "Hogar",
  page_not_found: "Vaya, falta la página que está buscando no existe.",
  add_new_region: "añadir nueva región",
  search: "Buscar",
  add_new_regional_manager: "añadir nuevo gerente regional",
  data_not_available: "Informacion no disponible",
  regionId: "regionId",
  region_name: "nombre de la región",
  regional_managers: "directores regionales",
  user_detail: "Perfil preferencias",
  change_password: "cambiar la contraseña",
  region: "Región",
  phone_number: "número de teléfono",
  dealers: "distribuidores",
  independent_dairies: "lecherías independientes",
  add_new_dealer: "añadir nuevo distribuidor",
  add_new_super_admin: "añadir nueva Superadministrador",
  delete_record_msg:
    "¡¡Advertencia!! Eliminación de los datos del usuario pondrá en peligro toda la información de las industrias lácteas y los tanques instalados en sus instalaciones. Usted no será capaz de recuperar la parte posterior de la información. Estás seguro de que quieres continuar?",
  edit_super_admin: "editar Superadministrador",
  edit_region: "editar región",
  region_added_successfully: "región ha añadido correctamente",
  something_went_wrong: "algo salió mal",
  region_updated_successfully: "región actualizado correctamente",
  region_deleted_successfully: "región eliminada con éxito",
  super_admin_deleted_successfully:
    "Superadministrador eliminado correctamente",
  super_admin_added_successfully: "Superadministrador añadido correctamente",
  super_admin_updated_successfully:
    "Superadministrador actualizado correctamente",
  regional_manager_added_successfully: "gerente regional añadido correctamente",
  regional_manager_deleted_successfully:
    "gerente regional eliminado correctamente",
  regional_manager_updated_successfully:
    "gerente regional actualizado correctamente",
  edit_regional_manager: "editar gerente regional",
  dealer_added_successfully: "distribuidor añadido correctamente",
  dealer_deleted_successfully: "distribuidor eliminado correctamente",
  edit_dealer: "editar distribuidor",
  dealer_updated_successfully: "distribuidor actualizado correctamente",
  edit_independent_dairy: "editar lácteos independiente",
  independent_dairy_deleted_successfully:
    "lácteos independiente eliminado correctamente",
  independent_dairy_updated_successfully:
    "lácteos independiente actualizado correctamente",
  producer_id: "Identificación del productor",
  dairy_farm: "granja lechera",
  dairy_farms: "granjas lecheras",
  technicians: "Los técnicos",
  add_new_dairy_farm: "añadir nuevo tambo",
  technician_deleted_successfully: "técnico eliminado correctamente",
  add_new_technician: "añadir nuevo técnico",
  technician: "técnico",
  edit_technician: "editar técnico",
  technician_name: "nombre técnico",
  assign_dairies: "lecherías asignar",
  technician_added_successfully: "técnico ha añadido correctamente",
  dairy_updated_successfully: "lácteos actualizado correctamente",
  dairy_deleted_successfully: "lácteos eliminado correctamente",
  technician_updated_successfully: "técnico actualizado correctamente",
  old_password: "Contraseña anterior",
  re_enter_new_password: "re-ingrese nueva contraseña",
  changed_password_message: "contraseña establecida correctamente.",
  password_reset_link_invalid:
    "contraseña de enlace de restablecimiento no válido",
  required_field_error: "Este campo es requerido.",
  password_length_error: "La contraseña debe tener 8 caracteres de longitud",
  user_not_registered:
    "El usuario no se ha registrado en este correo electrónico",
  region_id: "Se requiere Identificación región.",
  region_name_error: "Se requiere el nombre región.",
  name_error: "Se requiere el nombre.",
  email_error: "Correo electronico es requerido.",
  region_error: "Se requiere región.",
  password_error: "Se requiere contraseña.",
  dealer_name: "Se requiere el nombre del distribuidor.",
  dealer_email: "Se requiere de correo electrónico distribuidor.",
  producer_id_error: "Se requiere Identificación del productor.",
  dairy_name_error: "Se requiere el nombre de lácteos.",
  dairy_address: "Se requiere dirección de la lechería.",
  dairy_city: "Se requiere la ciudad de Lechería.",
  dairy_state: "Se requiere el estado de lácteos.",
  technician_name_error: "Se requiere el nombre técnico.",
  technician_email: "Se requiere técnico de correo electrónico.",
  login_screen_text: "Ingrese a su cuenta",
  PMC_Login: "Iniciar con",
  forgot_password: "No puedes iniciar sesión?",
  or: "O",
  privacy_policy: "política de privacidad",
  terms_and_conditions: "Términos y condiciones",
  data_policy: "política de datos",
  end_user_license_agreement: "Acuerdo de licencia de usuario final",
  contact_administrator: "Contacto administrador",
  are_you_sure: "Estás seguro de que quieres continuar?",
  tank_mode: "Modo de tanque",
  last_agitated: "Última agitado",
  live_data: "Datos en tiempo real",
  re_register: "Re-Registro",
  tank_updated: "Tanque actualizado correctamente",
  tank_deleted: "Tanque eliminado correctamente",
  edit_dairy_farm: "Editar granja daity",
  create: "crear",
  update: "actualizar",
  regional_manager: "administrador regional",
  independent_dairy: "lácteos independiente",

  login: "Inicio de sesión",
  password: "Contraseña",
  enter_email: "Correo electrónico",
  actions: "Acciones",
  edit: "Editar",
  delete: "Eliminar",
  add: "Añadir",
  super_admin: "Súper admin",
  city: "Ciudad",
  state: "estado",
  county: "Condado / Distrito",
  address: "Dirección",
  new_password: "Nueva contraseña",
  reset_password: "Restablecer la contraseña",
  current_password_wrong: "La contraseña actual es incorrecta.",
  link_sent_successfully:
    "Enlace para restablecer contraseña enviado a su correo electrónico",
  no_data: "Sin datos disponibles.",
  back_to_login: "Regresar a Inicio de Sesión",
  tank_not_registered: "Tanque no registrado.",
  the_dairy_owner_has_not_registered: "El propietario de los lácteos no se ha registrado.",
  tank_added: "Tanque añadido con éxito.",
  language_preferences: "Preferencias de idioma",
  name: "Nombre",
  language_preferences: "Preferencias de idioma",
  unit_preference: "Preferencias unidad",
  temperature_unit_preference: "Unidad de temperatura",
  pressure_unit: "Unidad de presión",
  volume_unit: "Unidad de volumen",
  basic_details: "Detalles básicos",
  alert_preference: "Preferencias de alerta",
  language_dropdown: "Desplegable de idiomas",
  log_out: "Cerrar sesión",
  email: "Correo electrónico",
  details_updated_successfully: "Detalles de usuario actualizado correctamente",
  regions: "regiones",
  phone: "Teléfono",
  is_registered: "Registrado",
  dairies: "Lecherías",

  dairy_name: "Nombre de la Granja",
  unable_to_connect_server: "Usuario no encontrado.",
  loading: "Por favor espere, cargando…",
  username_not_found: "Usuario no encontrado.",
  tank_temperature: "Temperatura del tanque",
  tank_volume: "Volumen del tanque",
  pipeline_temperature: "Temperatura de tuberías",
  add_new_tank: "Añadir tanque nuevo",
  tank_name: "Nombre del tanque",
  num_evcs: "N.° de EVC",
  clear: "Claro",
  sms: "SMS",
  confirm_new_password: "Confirmar nueva contraseña",
  confirm_password_required: "Se requiere confirmación de contraseña",
  dairy_added_successfully: "Granja añadida con éxito.",
  dealer: "Distribuidor",
  enter_a_new_password: "Introduzca nueva contraseña",
  not_register: "No registrado",
  password_min: "La contraseña debe contener al menos de 8 a 16 caracteres",
  password_not_match:
    "La nueva contraseña y la confirmación de la contraseña no coinciden.",
  password_regex_message: "La contraseña no es válida",
  password_required: "Se requiere contraseña.",
  resend_link: "enlace de reenvío",
  send_invitation_link: "Enlace de activación de la cuenta enviado",
  sent: "enviado",
  set_password: "configurar la clave",
  set_validation_message:
    "Por favor utilice la contraseña entre 8 - 16 caracteres que deben incluir al menos 1 carácter numérico, 1 carácter especial, uno de mayúsculas y minúsculas uno sin ningún espacio.",
  user_has_been_not_registered: "El usuario no se ha registrado todavía",
  milk_pipeline_temperature: "La leche de la tubería de temperatura.",
  temperature_sensor_3: "Sensor de temperatura 3",
  temperature_sensor_4: "Sensor de temperatura 4",
  evc_temperature: "Temperatura EVC",
  subcool_temperature: "Temperatura de subenfriamiento",

  Cooling: "Enfriamiento",
  BU_Cool: "Enfriamiento de la Unidad Inferior",
  Washing: "Lavado",
  NULL: "NULL",
  INIT: "INIT",
  Power_Fail_Recovery: "Fallo de alimentación Recuperación",
  Standby: "En espera",
  Sample_Agitate: "Agitar Muestra",
  Editing_Parameters: "Editar Parámetros",
  Diagnostic: "Diagnóstico",
  Setup: "Ajuste",
  BU_Delay_Start: "Inicio del Retardo de la Unidad Inferior",
  AU_Bottom_Delay_Start: "AU Abajo Inicio Retardado",
  AU_Upper_Delay_Start: "AU superior de retardo de arranque",
  AU_Cool: "Enfriamiento de Todas las Unidades",
  BU_Delay_BU_Pulsed_Cool:
    "Retardo de la Unidad Inferior Enfriamiento pulsado de la Unidad Inferior",
  AU_Delay_BU_Pulsed_Cool:
    "Retardo de Todas las Unidades Enfriamiento pulsado de la Unidad Inferior",
  FW: "FW",
  AC: "AC",
  SC: "SC",
  Rinse_1_WWB: "Enjuague 1 (WWB)",
  Delay_WWB: "Retardo (WWB)",
  Rinse_2_WWB: "Enjuague 2 (WWB)",
  Detergent_Wash_WWB: "Lavado con detergente (WWB)",
  Rinse_3_WWB: "Enjuague 3 (WWB)",
  Acid_Rinse_WWB: "Enjuague ácido (WWB)",
  Post_Rinse_WWB: "Enjuague posterior (WWB)",
  Complete_WWB: "Completo (WWB)",
  Rinse_1_ADB: "Enjuague 1 (ADB)",
  Delay_ADB: "Retardo (ADB)",
  Acid_Wash_ADB: "Lavado ácido (ADB)",
  Rinse_2_ADB: "Enjuague 2 (ADB)",
  Detergent_Wash_ADB: "Lavado con detergente (ADB)",
  Rinse_3_ADB: "Enjuague 3 (ADB)",
  Acid_Rinse_ADB: "Enjuague ácido (ADB)",
  Post_Rinse_ADB: "Enjuague posterior (ADB)",
  Complete_ADB: "Completo (ADB)",
  SC_Complete_ADB: "Ciclo de desinfección (abreviado) completo (ADB)",
  Rinse_1_MMB: "Enjuague 1 (MMB)",
  Delay_MMB: "Retardo (MMB)",
  Rinse_2_MMB: "Enjuague 2 (MMB)",
  Detergent_Wash_MMB: "Lavado con detergente (MMB)",
  Rinse_3_MMB: "Enjuague 3 (MMB)",
  Acid_Rinse_MMB: "Enjuague ácido (MMB)",
  Sanitize_Cycle_MMB: "Ciclo de desinfección (MMB)",
  Pre_Rinse_MMB: "Enjuague previo (MMB)",
  Complete_MMB: "Completo (MMB)",
  SC_Complete_MMB: "Ciclo de desinfección (abreviado) completo (MMB)",
  english: "English",
  Spanish: "Español",
  Dutch: "Nederlands",
  CanadianFrench: "Français canadien",
  Japanese: "日本語",
  German: "Deutsche",
  version: "Versión",
  farm_users: "Los usuarios agrícolas",
  alerts: "Alertas",
  add_new_farm_users: "añadir nuevo usuario granja",
  role: "Papel",
  user_role: "Tipo de explotación usuario",
  farm_users_added_successfully: "granja usuario ha añadido correctamente.",
  farm_users_deleted_successfully: "granja de usuario eliminada con éxito",
  user_create_different_role_error:
    "Ya existe un usuario creado con un papel diferente. Por favor, póngase en contacto con el administrador del sitio para resolver esto.",
  farm_users_updated_successfully:
    "Granja de usuario actualizada correctamente.",
  close: "Cerrar",
  assign: "Asignar",
  email_already_exists:
    "Usuario con el ID de correo electrónico mencionada ya existe.",
  user_create_different_dairy_to_assign:
    "Ya existe un usuario creado de ese papel. ¿Quieres añadir el / ella a esta lácteos?",

  alert_history_error: "Error al ir a buscar el historial de alertas",
  can_not_login: "No puedes iniciar sesión?",
  new_password_required: "Se requiere nueva contraseña.",
  old_password_required: "Se requiere contraseña anterior.",
  re_enter_new_password_required:
    "Se requiere volver a entrar nueva contraseña.",
  by_signing_up:
    "Al registrarse, acepta nuestra política de privacidad vinculada a continuación.",
  resend_email_link_expired:
    "Su enlace ha caducado contacto por favor con el administrador",
  configure_alerts: "Configurar alertas",
  add_alert: "alerta complemento",
  tank_name: "Nombre del tanque",
  alert_deleted_successfully: "alerta eliminado correctamente",
  alert_not_found: "No se encontraron alertas personalizadas.",
  sensor_name: "nombre del sensor",
  sensor_value: "valor del sensor",
  duration: "Duración",
  tank_state: "El tanque",
  condition: "Condición",
  delay: "Demora",
  minutes: "Minutos",
  sensor_value_error: "Se requiere sensor.",
  delay_value_error: "Se requiere demora.",
  duration_value_error: "Se requiere duración.",
  duplicate_email: "Duplicar correo electrónico de identificación.",
  invalid_email: "formato de correo electrónico está mal.",
  alert_added_successfully: "alerta añadido correctamente",
  maximum_email:
    "No se puede agregar más de 10 mensajes de correo electrónico.",
  contact_number_error: "se requiere el contacto Número.",
  duplicate_contact_number: "número duplicado Contacto",
  maximum_contact_number: "No se puede agregar más de 10 números.",
  contact_digit: "número de contacto debe ser de 10 dígitos.",
  config_alert:
    "Por favor, introduzca al menos un número de teléfono o un correo electrónico para configurar la alerta.",
  update_form_button: "Actualizar",
  live_alert_not_found: "No hay alertas en vivo.",
  live_alerts: "alertas en vivo",
  resolved_alerts: "alertas resueltas",
  live_alert_history_error:
    "Error al ir a buscar el historial de alertas en vivo",
  loading_text: "Por favor espere, cargando ...",
  resolved_alert_not_found: "No hay alertas se resuelven",
  triggered: "Motivado",
  resolved: "Resuelto",
  show_annotation: "Mostrar Anotación",
  hide_annotation: "Ocultar Anotación",
  clear: "Claro",
  submit: "Enviar",
  export_csv: "exportación CSV",
  add_annotation_instruction:
    "Desplácese sobre el gráfico y haga clic en el lugar donde desea agregar la anotación.",
  from_date_time: "De",
  to_date_time: "A",
  select_date: "Por favor, seleccione una fecha.",
  select_from_date: "Por favor seleccione de la fecha.",
  select_to_date: "Por favor seleccione la fecha Hasta.",
  date_not_same: "La fecha no puede ser la misma.",
  to_date_greater_than_today:
    "Hasta la fecha no puede ser mayor que la actual.",
  from_date_greater_than_today:
    "A partir de la fecha no puede ser mayor que la fecha Hasta.",
  select_date_range:
    "Por favor, seleccione un intervalo de fechas dentro de los 31 días.",
  tank_data_not_available:
    "dispositivo tanque no tiene datos entre estas fechas.",
  download_failed: "Descarga fracasó.",
  file_downloaded: "Su archivo está siendo descargado.",
  select_date_time: "Seleccione la fecha y hora",
  regional_managers_error: "Se requiere gerentes regionales.",
  independent_dealers: "Distribuidores independientes",
  cancel_re_register: "Cancelar Re-Registro",
  re_registers_successfully: "Vuelva a registrar con éxito",
  evc_error: "Se requiere EVC.",
  cancel_registers_successfully: "Cancelar Vuelva a registrar con éxito",
  lsp: "Presión lateral baja",
  sst: "Temperatura de succión saturada",
  hsp: "Presión lateral alta",
  sct: "Temperatura de condensación",
  no_dairy_farm_data_available:
    "No hay tales datos para estas granjas lecheras.",
  add_custom_alert: "Añadir alertas personalizadas",
  farm_user: "Usuario agrícola",
  calibration_charts: "Gráficos de calibración",
  add_new_chart: "Añadir nueva tabla",
  chart_name: "Nombre del gráfico",
  type: "Tipo",
  manufacturer: "Fabricante",
  calibration_chart: "Carta de calibración",
  create_myMueller_chart: "Crea la carta de MyMueller",
  horizontal_inside_diameter: "Tanque horizontal interior de diámetro",
  vertical_inside_diameter: "Tanque vertical interior de diámetro",
  shell_length: "Longitud de la cáscara (de costura)",
  shell_material_thickness: "Espesor del material de la cáscara",
  tank_pitch_foot: "Pitch de tanque por pie (pulgadas)",
  head_material_thickness: "Espesor del material de la cabeza",
  head_inside_knuckle: "Cabeza dentro del radio nudillo",
  height_increment_value: "El valor de incremento de altura",
  right_head_material_thickness: "Espesor del material de la cabeza derecha",
  right_head_inside_knuckle: "Cabeza derecha dentro del radio de Knuckle",
  create_chart: "Crear gráficos",
  transducer_limit: "Límite del transductor",
  calibration_deleted_successfully:
    "Gráfico de calibración eliminado con éxito.",
  chart_name_required: "Se requiere nombre del gráfico.",
  tank_type_required: "Se requiere tipo.",
  manufacture_required: "Se requiere la fabricación.",
  transducer_limit_required: "Se requiere límite del transductor.",
  transducer_limit_invalid: "Límite de transductor no válido.",
  tank_detail: "Detalle del tanque",
  calibration: "Calibración",
  create_new_with: "Crear nuevos con",
  tank_height: "Altura del tanque",
  inch: "pulgada",
  gal_usa: "Gal",
  volume_increment: "Volumen incremental",
  accumulated_volume: "Volumen total",
  horizontal_inside_diameter_required:
    "Se requiere el diámetro interior horizontal del tanque.",
  vertical_inside_diameter_required:
    "El tanque vertical interior se requiere diámetro interior.",
  shell_length_required: "Se requiere la longitud de la cáscara.",
  shell_material_thickness_required:
    "Se requiere el grosor del material de la cáscara.",
  tank_pitch_foot_required:
    "Se requiere un tono del tanque por pie (pulgadas).",
  head_material_thickness_required:
    "Se requiere el grosor del material de la cabeza.",
  head_inside_knuckle_required:
    "Se requiere la cabeza dentro del radio de Knuckle.",
  height_increment_value_required:
    "Se requiere el valor de incremento de altura.",
  right_head_material_thickness_required:
    "Se requiere el grosor del material de la cabeza derecha.",
  right_head_inside_knuckle_required:
    "Se requiere la cabeza derecha dentro del radio de Knuckle.",
  drag_drop_file: "Arrastra y suelta tu archivo aquí o",
  just_click_browse_file: "Simplemente haga clic para buscar archivos",
  chart_added_successfully: "Gráfico agregado con éxito.",
  invalid_excel: "Formato de archivo incorrecto cargado",
  calibration_chart_required: "Se requiere carta de calibración.",
  special_instruction: "Instrucción especial",
  accumulate_height_csv_text: "Altura (pulgada)",
  accumulate_volume_csv_text: "Volumen acumulado (Gal EE. UU.)",
  volume_increment_csv_text: "Incremento de volumen (Gal EE. UU.)",
  sample_calibration_chart: "Descargar la plantilla de la tabla de calibración",
  dairy_admin_exists: "El administrador de lácteos ya está saliendo con esta lechería.",
  all_tanks: "Todos los tanques",
  producerId_max_length_error: "El ID de productor no debe exceder los 50 caracteres",
  producerId_alphanumeric_error: "Solo se permiten caracteres alfanuméricos",
  invalid_email_error: 'Introduzca un correo electrónico válido',
  Agitating: 'Agitando',
  AGITATE: "AGITAR",
  COOL: "FRIO",
  DIAGNOSTIC: "DIAGNÓSTICO",
  SETTINGS: "AJUSTES",
  STANDBY: "APOYAR",
  WASH: "LAVAR",
  alert: "Alerta",
  tank: "Tanque",
  tanks: "Tanques",
  no_manufacturer: "Sin fabricante",
  mueller: "Mueller",
  non_mueller: "Sin muelle",
  no_type: "Sin tipo",
  horizontal: "Horizontal",
  vertical: "Vertical",
  dairy_owner: "Dueño de lácteos",
  dairy_operator: "Dueño de lácteos",
  milk_hauler: "Transportista de leche",
  sanitarian: "Sanitario",
  HSP: "HSP",
  LSP: "LSP",
  valve_position: "Posición de válvula",
  less_than: "Menos que",
  greater_than: "Mas grande que",
  cool: "Frio",
  wash: "Lavar",
  standby: "Apoyar",
  BOTTOM_UNIT_COOL: "Unidad inferior fresca",
  ALL_UNIT_COOL: "Toda la unidad fresca",
  BU_DELAY_BU_PULSE_COOL: "BU retardo bu pulso fresco",
  AU_DELAY_BU_PULSED_COOL: "AU Delay Bu pulsó fresco",
  WWB_FULL_WASH_RINSE_1: "WWB LAVADO COMPLETO ENJUAGUE 1",
  WWB_FULL_WASH_DELAY: "WWB Retraso de lavado completo",
  WWB_FULL_WASH_RINSE_2: "WWB LAVADO COMPLETO ENJUAGUE 2",
  WWB_FULL_WASH_DETERGENT_WASH: "WWB Lavado de detergente de lavado completo",
  WWB_FULL_WASH_RINSE_3: "WWB LAVADO COMPLETO ENJUAGUE 3",
  WWB_FULL_WASH_ACID_RINSE: "Enjuague de ácido de lavado completo WWB",
  WWB_FULL_WASH_RINSE_4: "WWB LAVADO COMPLETO ENJUAGUE 4",
  WWB_SANITIZE_CYCLE: "Ciclo de desinfección WWB",
  WWB_ACID_CYCLE_PRE_RINSE: "Ciclo de ácido WWB Pre Enjuague",
  WWB_ACID_CYCLE_ACID_RINSE: "Enjuague del ácido del ciclo del ácido WWB",
  WWB_ACID_CYCLE_POST_RINSE: "Ciclo de ácido WWB Post Enjuague",
  WWB_FULL_WASH_COMPLETE: "WWB Lavado completo completo completo",
  WWB_ACID_CYCLE_COMPLETE: "Ciclo de ácido WWB completo",
  WWB_SANITIZE_CYCLE_COMPLETE: "Ciclo de desinfección WWB completa",
  ADB_FULL_WASH_RINSE_1: "ADB Lavado completo enjuague 1",
  ADB_FULL_WASH_DELAY: "ADB Lavado completo de retraso",
  ADB_FULL_WASH_ACID_WASH: "ADB Lavado de ácido de lavado completo",
  ADB_FULL_WASH_RINSE_2: "ADB LAVADO COMPLETO ENJUAGUE 2",
  ADB_FULL_WASH_DETERGENT_WASH: "Lavado de detergente de lavado completo de ADB",
  ADB_FULL_WASH_RINSE_3: "ADB Lavado completo enjuague 3",
  ADB_FULL_WASH_ACID_RINSE: "ADB Lavar el ácido enjuague",
  ADB_FULL_WASH_RINSE_4: "ADB Lavado completo enjuague 4",
  ADB_SANITIZE_CYCLE: "Ciclo de desinfección ADB",
  ADB_ACID_CYCLE_PRE_RINSE: "Ciclo de ácido ADB Pre Enjuague",
  ADB_ACID_CYCLE_ACID_RINSE: "Enjuague ácido de ciclo de ácido ADB",
  ADB_ACID_CYCLE_POST_RINSE: "Ciclo de ácido ADB enjuague",
  ADB_FULL_WASH_COMPLETE: "ADB Lavado completo completo",
  ADB_ACID_CYCLE_COMPLETE: "Ciclo de ácido ADB completo",
  ADB_SANITIZE_CYCLE_COMPLETE: "Ciclo de desinfección ADB completo",
  MMB_FULL_WASH_RINSE_1: "MMB Lavado completo enjuague 1",
  MMB_FULL_WASH_DELAY: "MMB de retraso de lavado completo",
  MMB_FULL_WASH_RINSE_2: "MMB Lavado completo enjuague 2",
  MMB_FULL_WASH_DETERGENT_WASH: "Lavado de detergente de lavado completo de MMB",
  MMB_FULL_WASH_RINSE_3: "MMB Lavado completo enjuague 3",
  MMB_FULL_WASH_ACID_RINSE: "MMB enjuague el ácido de lavado completo",
  MMB_SANITIZE_CYCLE: "MMB desinfectar el ciclo",
  MMB_ACID_CYCLE_PRE_RINSE: "Ciclo de ácido MMB Pre Enjuague",
  MMB_ACID_CYCLE_ACID_RINSE: "Enjuague del ácido del ciclo de ácido MMB",
  MMB_FULL_WASH_COMPLETE: "MMB Lavado completo completo",
  MMB_ACID_CYCLE_COMPLETE: "Ciclo de ácido MMB completo",
  MMB_SANITIZE_CYCLE_COMPLETE: "MMB desinfectar ciclo completo",
  EVC_INPUT_REG_TEMP_1: "Sensor de temperatura EVC 1",
  EVC_INPUT_REG_TEMP_2: "Sensor de temperatura EVC 2",
  EVC_INPUT_REG_TEMP_3: "Sensor de temperatura EVC 3",
  EVC_INPUT_REG_SUBCOOL_TEMP: "Temperatura de subcool",
  EVC_INPUT_REG_HIGH_SIDE_PRESSURE: "HSP",
  EVC_INPUT_REG_LOW_SIDE_PRESSURE: "LSP",
  EVC_INPUT_REG_HI_SAT_TEMP: "Alta temperatura de saturación",
  EVC_INPUT_REG_LO_SAT_TEMP: "Temperatura de baja saturación",
  EVC_INPUT_REG_FAN_OUTPUT: "Velocidad del ventilador",
  HPF_INPUT_REG_TEMP1: "Temperatura del tanque",
  HPF_INPUT_REG_TEMP2: "MTemperatura de la tubería de la leche",
  HPF_INPUT_REG_TEMP3: "Temperatura 3",
  HPF_INPUT_REG_TEMP4: "Temperatura 4",
  HPF_INPUT_REG_VOLUME_4TO20MA_READING: "Volumen del tanque",
};
