/* German Language */
export default {
  email_address: "Email",
  powered_by: "unterstützt von",
  send_a_link: "Bitte senden Sie einen Link",
  register_email:
    "geben Sie Ihre E-Mail-Register, werden wir Ihnen einen Link zu überprüfen, die Sie senden.",
  contact_administrator_text:
    "Ihr myMueller Benutzer wurde mit dem MilkSecure Konto noch nicht verbunden. Bitte kontaktieren Sie den Administrator per E-Mail zu senden.",
  home: "Zuhause",
  page_not_found: "Hoppla, die von Ihnen gesuchte Seite existiert nicht.",
  add_new_region: "fügen Sie neue Region",
  search: "Suche",
  add_new_regional_manager: "Hinzufügen neuer Regional Manager",
  data_not_available: "Keine Daten verfügbar",
  regionId: "regionId",
  region_name: "Region Name",
  regional_managers: "Regionalmanager",
  user_detail: "Profileinstellungen",
  change_password: "Passwort ändern",
  region: "Region",
  phone_number: "Telefonnummer",
  dealers: "Händler",
  independent_dairies: "unabhängige Molkereien",
  add_new_dealer: "Hinzufügen neuer Händler",
  add_new_super_admin: "neuen Super-Administrator hinzufügen",
  delete_record_msg:
    "Warnung!! Nutzerdaten löschen, werden Kompromisse alle Daten der Molkereien und in Ihren Räumlichkeiten installierten Tanks. Sie abrufen können, um die Informationen nicht zurück. Bist du dir sicher, dass du weitermachen willst?",
  edit_super_admin: "bearbeiten Super-Administrator",
  edit_region: "Bearbeitungsbereich",
  region_added_successfully: "Region erfolgreich hinzugefügt",
  something_went_wrong: "etwas ist schief gelaufen",
  region_updated_successfully: "Region erfolgreich aktualisiert",
  region_deleted_successfully: "Region erfolgreich gelöscht",
  super_admin_deleted_successfully: "Super-Administrator erfolgreich gelöscht",
  super_admin_added_successfully: "Super-Administrator erfolgreich hinzugefügt",
  super_admin_updated_successfully:
    "Super-Administrator aktualisiert erfolgreich",
  regional_manager_added_successfully:
    "Regionalmanager erfolgreich hinzugefügt",
  regional_manager_deleted_successfully: "Regionalmanager erfolgreich gelöscht",
  regional_manager_updated_successfully:
    "Regional Manager erfolgreich aktualisiert",
  edit_regional_manager: "bearbeiten Regional Manager",
  dealer_added_successfully: "Händler erfolgreich hinzugefügt",
  dealer_deleted_successfully: "Händler erfolgreich gelöscht",
  edit_dealer: "bearbeiten Händler",
  dealer_updated_successfully: "Händler erfolgreich aktualisiert",
  edit_independent_dairy: "bearbeiten unabhängige Milch",
  independent_dairy_deleted_successfully:
    "unabhängige Molkerei erfolgreich gelöscht",
  independent_dairy_updated_successfully:
    "unabhängige Molkerei erfolgreich aktualisiert",
  producer_id: "Hersteller-ID",
  dairy_farm: "Milchbauernhof",
  dairy_farms: "Milchhöfe",
  technicians: "Techniker",
  add_new_dairy_farm: "Hinzufügen neuer Meierei",
  technician_deleted_successfully: "Techniker erfolgreich gelöscht",
  add_new_technician: "Hinzufügen neuer Techniker",
  technician: "Techniker",
  edit_technician: "bearbeiten Techniker",
  technician_name: "Techniker Name",
  assign_dairies: "assign Molkereien",
  technician_added_successfully: "Techniker erfolgreich hinzugefügt",
  dairy_updated_successfully: "Molkerei erfolgreich aktualisiert",
  dairy_deleted_successfully: "Molkerei erfolgreich gelöscht",
  technician_updated_successfully: "Techniker erfolgreich aktualisiert",
  old_password: "Altes Passwort",
  re_enter_new_password: "neues Passwort erneut eingeben",
  changed_password_message: "Passwort erfolgreich gesetzt.",
  password_reset_link_invalid: "Passwort-Reset-Link ungültig",
  required_field_error: "Dieses Feld ist erforderlich.",
  password_length_error: "Das Passwort muss 8 Zeichen lang",
  user_not_registered: "Der Benutzer ist nicht mit dieser E-Mail registriert",
  region_id_required: "Region-ID erforderlich.",
  region_name_error: "Region Name ist erforderlich.",
  name_error: "Name ist erforderlich.",
  email_error: "E-Mail ist erforderlich.",
  region_error: "Region erforderlich ist.",
  password_error: "Passwort wird benötigt.",
  dealer_name: "Händlername ist erforderlich.",
  dealer_email: "Händler E-Mail erforderlich.",
  producer_id_error: "Hersteller-ID erforderlich.",
  dairy_name_error: "Dairy Name ist erforderlich.",
  dairy_address: "Dairy-Adresse ist erforderlich.",
  dairy_city: "Dairy Stadt erforderlich.",
  dairy_state: "Dairy Zustand erforderlich ist.",
  technician_name_error: "Techniker Name ist erforderlich.",
  technician_email: "Techniker E-Mail erforderlich.",
  login_screen_text: "Ins Konto einloggen",
  PMC_Login: "Einloggen mit",
  forgot_password: "Sie können nicht einloggen?",
  or: "oder",
  privacy_policy: "Datenschutz-Bestimmungen",
  terms_and_conditions: "Geschäftsbedingungen",
  data_policy: "Datenschutzrichtlinie",
  end_user_license_agreement: "Endbenutzer-Lizenzvertrag",
  contact_administrator: "Kontakt Administrator",
  are_you_sure: "Bist du dir sicher, dass du weitermachen willst?",
  tank_mode: "-Tank-Modus",
  last_agitated: "Letzter Agitated",
  live_data: "Lebensdaten",
  re_register: "Re-Register",
  tank_updated: "Tank aktualisiert Erfolgreich",
  tank_deleted: "Tank erfolgreich gelöscht",
  edit_dairy_farm: "Bearbeiten daity Bauernhof",
  create: "erstellen",
  update: "aktualisieren",
  regional_manager: "Regionalleiter",
  independent_dairy: "unabhängig Milch",
  language_preferences: "Spracheinstellungen",
  unit_preference: "Einheit Pregeferences",
  temperature_unit_preference: "Temperatureinheit",
  pressure_unit: "Druckeinheit",
  volume_unit: "Volumeneinheit",
  alert_preference: "Benachrichtigungseinstellungen",
  language_dropdown: "Language Dropdown",
  details_updated_successfully: "Benutzerdaten erfolgreich aktualisiert",
  regions: "Regionen",
  basic_details: "Grundlegende Details",
  log_out: "Ausloggen",

  login: "Anmelden",
  password: "Passwort",
  enter_email: "Email",
  actions: "Aktionen",
  edit: "Bearbeiten",
  delete: "löschen",
  add: "hinzufügen",
  super_admin: "Superadmin",
  city: "Stadt",
  state: "Status",
  county: "Bezirk / Bezirk",
  address: "Adresse",
  new_password: "Neues Passwort",
  reset_password: "Passwort zurücksetzen",
  current_password_wrong: "Das aktuelle Passwort ist falsch.",
  link_sent_successfully:
    "Link zum Zurücksetzen des Passworts an Ihre E-Mail gesendet",
  no_data: "Keine Daten vorhanden.",
  back_to_login: "Zurück zur Anmeldung",
  tank_not_registered: "Tank nicht registriert.",
  the_dairy_owner_has_not_registered: "Der Milchbesitzer hat sich nicht registriert.",
  tank_added: "Tank erfolgreich hinzugefügt.",
  name: "Name",
  email: "Email",
  phone: "Telefon",
  is_registered: "Eingetragen",
  dairies: "Molkereien",

  unable_to_connect_server:
    "Die Verbindung zum Server ist nicht möglich. Bitte versuchen Sie es später noch einmal.",
  loading: "Bitte warten, wird geladen...",
  username_not_found: "Benutzername nicht gefunden.",
  tank_temperature: "Tanktemperatur",
  tank_volume: "Tankvolumen",
  pipeline_temperature: "Pipeline Temperatur",
  add_new_tank: "Neuen Tank hinzufügen",
  num_evcs: "Nr. von EVC(s)",
  dairy_name: "Molkereiverband",
  tank_name: "Tankname",
  clear: "Klar",
  sms: "SMS",
  confirm_new_password: "Neues Passwort bestätigen",
  confirm_password_required: "Bestätigen Passwort erforderlich",
  dairy_added_successfully: "Molkerei erfolgreich hinzugefügt.",
  dealer: "Händler",
  enter_a_new_password: "Neues Passwort eingeben",
  not_register: "Nicht registriert",
  password_min: "Das Passwort muss mindestens 8 bis 16 Zeichen enthalten",
  password_not_match:
    "Neues Passwort und Passwort bestätigen stimmen nicht überein.",
  password_regex_message: "Kennwort ist ungültig",
  password_required: "Passwort wird benötigt.",
  resend_link: "resend Link",
  send_invitation_link: "Kontoaktivierungslink gesendet",
  sent: "geschickt",
  set_password: "Passwort festlegen",
  set_validation_message:
    "Bitte benutzen Sie das Kennwort zwischen 8-16 Zeichen, die mindestens 1 numerisches Zeichen, 1 Sonderzeichen, ein Groß- und einen Klein ohne Leerzeichen enthalten sollte.",
  user_has_been_not_registered: "Benutzer wurde noch nicht registriert",
  milk_pipeline_temperature: "Milch Pipeline Temp.",
  temperature_sensor_3: "Temperaturfühler-3",
  temperature_sensor_4: "Temperaturfühler-4",
  evc_temperature: "EVC-Temperatur",
  subcool_temperature: "Unterkühlungstemperatur",

  Cooling: "Kühlung",
  BU_Cool: "Kühlung untere Einheit (abgekürzt)",
  Washing: "Reinigung",
  NULL: "NULL",
  INIT: "INIT",
  Power_Fail_Recovery: "Power Fail Recovery-",
  Standby: "Standby",
  Sample_Agitate: "Probenrührung",
  Editing_Parameters: "Parameter bearbeiten",
  Diagnostic: "Diagnostik",
  Setup: "Einstellungen",
  BU_Delay_Start: "Verzögerungsstart untere Einheit (UE abgekürzt)",
  AU_Bottom_Delay_Start: "AU Bottom Startverzögerung",
  AU_Upper_Delay_Start: "AU Oberstartverzögerung",
  AU_Cool: "Alle Einheiten (AE abgekürzt) gekühlt",
  BU_Delay_BU_Pulsed_Cool:
    "Untere Einheit (UE abgekürzt) Verzögerung untere Einheit (UE abgekürzt) Pulskühlung",
  AU_Delay_BU_Pulsed_Cool:
    "Alle Einheiten (AE abgekürzt) Verzögerung untere Einheit (UE abgekürzt) Pulskühlung",
  FW: "FW",
  AC: "AC",
  SC: "SC",
  Rinse_1_WWB: "Spülen 1 (WWB)",
  Delay_WWB: "Verzögerung (WWB)",
  Rinse_2_WWB: "Spülen 2 (WWB)",
  Detergent_Wash_WWB: "Alkalische Reinigung (WWB)",
  Rinse_3_WWB: "Spülen 3 (WWB)",
  Acid_Rinse_WWB: "Säure Reinigung (WWB)",
  Post_Rinse_WWB: "Nachspülen (WWB)",
  Complete_WWB: "Abgeschlossen (WWB)",
  Rinse_1_ADB: "Spülen 1 (ADB)",
  Delay_ADB: "Verzögerung (ADB)",
  Acid_Wash_ADB: "Säure Reinigung (ADB)",
  Rinse_2_ADB: "Spülen 2 (ADB)",
  Detergent_Wash_ADB: "Alkalische Reinigung (ADB)",
  Rinse_3_ADB: "Spülen 3 (ADB)",
  Acid_Rinse_ADB: "Säurespülung (ADB)",
  Post_Rinse_ADB: "Nachspülen (ADB)",
  Complete_ADB: "Abgeschlossen (ADB)",
  SC_Complete_ADB: "Desinfektionszyklus (abgekürzt) abgeschlossen (ADB)",
  Rinse_1_MMB: "Spülen 1 (MMB)",
  Delay_MMB: "Verzögerung (MMB)",
  Rinse_2_MMB: "Spülen 2 (MMB)",
  Detergent_Wash_MMB: "Alkalische Reinigung (MMB)",
  Rinse_3_MMB: "Spülen 3 (MMB)",
  Acid_Rinse_MMB: "Säurespülung (MMB)",
  Sanitize_Cycle_MMB: "Desinfektionszyklus (MMB)",
  Pre_Rinse_MMB: "Vorspülen (MMB)",
  Complete_MMB: "Abgeschlossen (MMB)",
  SC_Complete_MMB: "Desinfektionszyklus (abgekürzt) abgeschlossen (MMB)",

  english: "English",
  Spanish: "Español",
  Dutch: "Nederlands",
  CanadianFrench: "Français canadien",
  Japanese: "日本語",
  German: "Deutsche",
  version: "Ausführung",
  farm_users: "Farm Benutzer",
  alerts: "Alarme",
  add_new_farm_users: "Hinzufügen neuer Benutzer Farm",
  role: "Rolle",
  user_role: "Art des Betriebs Benutzer",
  farm_users_added_successfully: "Farm Benutzer erfolgreich hinzugefügt.",
  farm_users_deleted_successfully: "Farm Benutzer erfolgreich gelöscht.",
  user_create_different_role_error:
    "Es gibt bereits einen Benutzer mit einer anderen Rolle erstellt. Bitte kontaktieren Sie den Site-Administrator diese zu lösen.",
  farm_users_updated_successfully: "Farm Benutzer erfolgreich aktualisiert.",
  close: "Schließen",
  assign: "Zuordnen",
  email_already_exists:
    "Benutzer mit der oben genannten E-Mail-ID bereits vorhanden sein.",
  user_create_different_dairy_to_assign:
    "Es gibt bereits einen Benutzer dieser Rolle erstellt. Haben Sie ihn / sie zu dieser Milch hinzufügen?",

  alert_history_error: "Fehler beim Abrufen Alert-Historie",
  can_not_login: "Sie können nicht einloggen?",
  new_password_required: "Neues Passwort ist nicht erforderlich.",
  old_password_required: "Altes Kennwort ist nicht erforderlich.",
  re_enter_new_password_required:
    "Geben Sie erneut neues Passwort ist nicht erforderlich.",
  by_signing_up:
    "Mit der Anmeldung stimmen Sie unserer unten verlinkten Datenschutzerklärung zu",
  resend_email_link_expired:
    "Ihr Link ist abgelaufen Bitte kontaktieren Sie Ihren Administrator",
  configure_alerts: "Benachrichtigungen konfigurieren",
  add_alert: "add alert",
  tank_name: "Tank-Name",
  alert_deleted_successfully: "Alarm erfolgreich gelöscht",
  alert_not_found: "Keine kundenspezifische Warnungen gefunden.",
  sensor_name: "Sensorname",
  sensor_value: "Sensorwert",
  duration: "Dauer",
  tank_state: "Tank Zustand",
  condition: "Bedingung",
  delay: "Verzögern",
  minutes: "Protokoll",
  sensor_value_error: "Sensor erforderlich ist.",
  delay_value_error: "Verzögerung erforderlich ist.",
  duration_value_error: "Dauer erforderlich ist.",
  duplicate_email: "Duplicate Email-ID.",
  invalid_email: "E-Mail-Format ist falsch.",
  alert_added_successfully: "Alarm erfolgreich hinzugefügt",
  maximum_email: "Sie können nicht mehr als 10 E-Mails hinzuzufügen.",
  contact_number_error: "Kontaktnummer ist nicht erforderlich.",
  duplicate_contact_number: "Doppelte Kontaktnummer",
  maximum_contact_number: "Sie können nicht mehr als 10 Zahlen addieren.",
  contact_digit: "Kontaktnummer muss 10-stellig sein.",
  config_alert:
    "Bitte geben Sie atleast eine Telefonnummer oder eine E-Mail um die Warnung zu konfigurieren.",
  update_form_button: "Aktualisieren",
  live_alert_not_found: "Es gibt keine Live-Warnungen.",
  live_alerts: "Live Alerts",
  resolved_alerts: "gelöst Alerts",
  live_alert_history_error: "Fehler beim Abrufen von Live-Alert-Historie",
  loading_text: "Bitte warten, lädt noch ...",
  resolved_alert_not_found: "Es werden keine Warnungen behoben",
  triggered: "Ausgelöst",
  resolved: "Behoben",
  show_annotation: "Anmerkung zeigen.",
  hide_annotation: "ausblenden Annotation",
  clear: "klar",
  submit: "einreichen",
  export_csv: "Export CSV",
  add_annotation_instruction:
    "Bewegen Sie den Mauszeiger über das Diagramm und klicken Sie auf die Stelle, an der Sie die Anmerkung hinzufügen möchten.",
  from_date_time: "Von",
  to_date_time: "Zu",
  select_date: "Bitte wählen Sie ein Datum.",
  select_from_date: "Bitte wählen Sie aus Datum.",
  select_to_date: "Bitte wählen Sie auf dem Laufenden.",
  date_not_same: "Datum kann nicht gleich sein.",
  to_date_greater_than_today: "Bis heute kann nicht größer als heute.",
  from_date_greater_than_today: "sein kann, nicht größer als Von-Datum.",
  select_date_range:
    "Bitte wählen Sie einen Datumsbereich innerhalb von 31 Tagen.",
  tank_data_not_available: "Tank Gerät hat keine Daten zwischen diesen Daten.",
  download_failed: "Herunterladen fehlgeschlagen.",
  file_downloaded: "Die Datei wird heruntergeladen.",
  select_date_time: "Wählen Sie Datum und Uhrzeit",
  regional_managers_error: "Regional Manager erforderlich.",
  independent_dealers: "unabhängige Händler",
  cancel_re_register: "Abbrechen Re-Register",
  re_registers_successfully: "Re-Register erfolgreich",
  evc_error: "EVC erforderlich.",
  cancel_registers_successfully: "Abbrechen Re-Register erfolgreich",
  lsp: "Niedriger Seitendruck.",
  sst: "Kondensatortemperatur.",
  hsp: "Hoher Seitendruck",
  sct: "Unterkühltemperatur",
  no_dairy_farm_data_available:
    "Es gibt keine solchen Daten für diese Milchviehbetriebe.",
  add_custom_alert: "Fügen Sie benutzerdefinierte Alarme hinzu",
  farm_user: "Bauernhof Benutzer",
  calibration_charts: "Kalibrierungsdiagramme",
  add_new_chart: "Neues Diagramm hinzufügen",
  chart_name: "Diagrammname",
  type: "Art",
  manufacturer: "Hersteller",
  calibration_chart: "Kalibrierdiagramm",
  create_myMueller_chart: "MyMueller-Chart erstellen",
  horizontal_inside_diameter: "Tank Horizontaler Innendurchmesser",
  vertical_inside_diameter: "Tank vertikaler Innendurchmesser",
  shell_length: "Schalenlänge (Naht zur Naht)",
  shell_material_thickness: "Mantelmaterialdicke.",
  tank_pitch_foot: "Tankplatz pro Fuß (Zoll)",
  head_material_thickness: "Kopfmaterialdicke.",
  head_inside_knuckle: "Kopf in den Knöchelradius",
  height_increment_value: "Der Höhewert der Höhe",
  right_head_material_thickness: "Rechte Kopfmaterialdicke",
  right_head_inside_knuckle: "Rechter Kopf im Knöchelradius",
  create_chart: "Diagramm erstellen",
  transducer_limit: "Wandlerlimit",
  calibration_deleted_successfully: "Kalibrierdiagramm erfolgreich gelöscht.",
  chart_name_required: "Diagrammname ist erforderlich.",
  tank_type_required: "Typ ist erforderlich.",
  manufacture_required: "Die Herstellung ist erforderlich.",
  transducer_limit_required: "Die Transducer-Grenze ist erforderlich.",
  transducer_limit_invalid: "Ungültige Messwandlergrenze.",
  tank_detail: "Tank Detail",
  calibration: "Kalibrierung",
  create_new_with: "Neue erstellen mit",
  tank_height: "Tankhöhe",
  inch: "Zoll",
  gal_usa: "in den USA",
  volume_increment: "Inkrementales Volumen.",
  accumulated_volume: "Volle Lautstärke",
  horizontal_inside_diameter_required:
    "Horizontaler Tank-Innendurchmesser ist erforderlich.",
  vertical_inside_diameter_required:
    "Tank vertikaler Innendurchmesser ist erforderlich.",
  shell_length_required: "Shell Länge ist erforderlich.",
  shell_material_thickness_required: "Mantelmaterialdicke ist erforderlich.",
  tank_pitch_foot_required: "Tankplatz pro Fuß (Zoll) ist erforderlich..",
  head_material_thickness_required: "Kopfmaterialdicke ist erforderlich.",
  head_inside_knuckle_required:
    "Kopf innerhalb des Knöchelradius ist erforderlich.",
  height_increment_value_required: "Der Höhewert der Höhe ist erforderlich.",
  right_head_material_thickness_required:
    "Die rechte Kopfmaterialdicke ist erforderlich.",
  right_head_inside_knuckle_required:
    "Der rechte Kopf im Knöchelradius ist erforderlich.",
  drag_drop_file: "Ziehen Sie Ihre Datei hier oder legen Sie sie ab",
  just_click_browse_file: "Klicken Sie einfach auf, um Dateien zu durchsuchen",
  chart_added_successfully: "Grafik erfolgreich hinzugefügt.",
  invalid_excel: "Falsches Dateiformat hochgeladen",
  calibration_chart_required: "Kalibrierdiagramm ist erforderlich.",
  special_instruction: "Spezielle Anleitung",
  accumulate_height_csv_text: "Höhe (Zoll).",
  accumulate_volume_csv_text: "Kumuliertes Volumen (GAL USA)",
  volume_increment_csv_text: "Volumeninkrement (GAL US)",
  sample_calibration_chart: "Kalibrierungsdiagrammvorlage herunterladen",
  dairy_admin_exists: "Dairy Admin ist bereits mit dieser Molkerei.",
  all_tanks: "Alle Tanks",
  producerId_max_length_error: "Die Hersteller ID sollte 50 Zeichen nicht überschreiten",
  producerId_alphanumeric_error: "Nur alphanumerische Zeichen erlaubt",
  invalid_email_error: 'gib eine gültige E-Mail ein',
  Agitating: 'Agitierend',
  AGITATE: "Agitieren",
  COOL: "COOL",
  DIAGNOSTIC: "Diagnose",
  SETTINGS: "DIE EINSTELLUNGEN",
  STANDBY: "BEREITHALTEN",
  WASH: "WASCHEN",
  alert: "Alerta",
  tank: "Tanque",
  tanks: "Tanks",
  no_manufacturer: "Kein Hersteller",
  mueller: "Müller",
  non_mueller: "Nicht-Müller",
  no_type: "Kein Typ",
  horizontal: "Horizontal",
  vertical: "Vertikal",
  dairy_owner: "Milchinhaber",
  dairy_operator: "Molkereibetreiber",
  milk_hauler: "Milchvertrieb",
  sanitarian: "Sanitär",
  HSP: "HSP",
  LSP: "LSP",
  valve_position: "Ventilposition",
  less_than: "Weniger als",
  greater_than: "Größer als",
  cool: "Cool",
  wash: "Waschen",
  standby: "Bereithalten",
  BOTTOM_UNIT_COOL: "Bodeneinheit Cool",
  ALL_UNIT_COOL: "Alle Einheit Cool",
  BU_DELAY_BU_PULSE_COOL: "Bu Verzögerung BU Puls Cool",
  AU_DELAY_BU_PULSED_COOL: "AU Verzögerung BU gepulst kühl",
  WWB_FULL_WASH_RINSE_1: "WWB-Vollwasch-Spülen 1",
  WWB_FULL_WASH_DELAY: "WWB-Vollwaschverzögerung",
  WWB_FULL_WASH_RINSE_2: "WWB-Vollwasch-Spülen 2",
  WWB_FULL_WASH_DETERGENT_WASH: "WWB-Waschmittelwaschmittelwaschmittel",
  WWB_FULL_WASH_RINSE_3: "WWB Full Waschen-Spülen 3",
  WWB_FULL_WASH_ACID_RINSE: "WWB-Vollwaschsäure-Spülen",
  WWB_FULL_WASH_RINSE_4: "WWB Full Wash-Spülen 4",
  WWB_SANITIZE_CYCLE: "WWB desinfizierte Zyklus",
  WWB_ACID_CYCLE_PRE_RINSE: "WWB-Säure-Zyklus Pre-Spülen",
  WWB_ACID_CYCLE_ACID_RINSE: "WWB Säure-Zyklus-Säure-Spülen",
  WWB_ACID_CYCLE_POST_RINSE: "WWB Säure-Zyklus-Post-Spülen",
  WWB_FULL_WASH_COMPLETE: "WWB Full Waschen vollständig",
  WWB_ACID_CYCLE_COMPLETE: "WWB Säurezyklus abgeschlossen",
  WWB_SANITIZE_CYCLE_COMPLETE: "WWB sanitize Zyklus abgeschlossen",
  ADB_FULL_WASH_RINSE_1: "ADB volle waschen spülen 1",
  ADB_FULL_WASH_DELAY: "ADB volle Waschverzögerung",
  ADB_FULL_WASH_ACID_WASH: "ADB volle waschen säurewäsche",
  ADB_FULL_WASH_RINSE_2: "ADB volle waschen spülen 2",
  ADB_FULL_WASH_DETERGENT_WASH: "ADB volle Waschmittelwaschmittel",
  ADB_FULL_WASH_RINSE_3: "ADB volle waschen spülen 3",
  ADB_FULL_WASH_ACID_RINSE: "ADB volle waschen säure spülen",
  ADB_FULL_WASH_RINSE_4: "ADB volle waschen spülen 4",
  ADB_SANITIZE_CYCLE: "ADB sanitize Cycle",
  ADB_ACID_CYCLE_PRE_RINSE: "Adb säurezyklus vor spülen",
  ADB_ACID_CYCLE_ACID_RINSE: "Adb-Säure-Zyklus-Säure-Spülen",
  ADB_ACID_CYCLE_POST_RINSE: "Adb-Säure-Zyklus-Post-Spülen",
  ADB_FULL_WASH_COMPLETE: "ADB Full Waschen vollständig",
  ADB_ACID_CYCLE_COMPLETE: "Adb säurezyklus abgeschlossen",
  ADB_SANITIZE_CYCLE_COMPLETE: "ADB desinfiziert den Zyklus abgeschlossen",
  MMB_FULL_WASH_RINSE_1: "MMB Full Waw Spinse 1",
  MMB_FULL_WASH_DELAY: "MMB-Vollwaschverzögerung",
  MMB_FULL_WASH_RINSE_2: "MMB Full Waschen-Spülen 2",
  MMB_FULL_WASH_DETERGENT_WASH: "MMB Volle Waschmittelwaschmittelwaschmittel",
  MMB_FULL_WASH_RINSE_3: "MMB Full Waschen-Spülen 3",
  MMB_FULL_WASH_ACID_RINSE: "MMB-Vollwaschsäure-Spülen",
  MMB_SANITIZE_CYCLE: "Mmb sanitize Zyklus",
  MMB_ACID_CYCLE_PRE_RINSE: "MMB Säurekreislauf Pre Spülen",
  MMB_ACID_CYCLE_ACID_RINSE: "MMB Säure-Zyklus-Säure-Spülen",
  MMB_FULL_WASH_COMPLETE: "MMB Full Waschen vollständig",
  MMB_ACID_CYCLE_COMPLETE: "MMB Säurezyklus abgeschlossen",
  MMB_SANITIZE_CYCLE_COMPLETE: "MMB sanitize Zyklus abgeschlossen",
  EVC_INPUT_REG_TEMP_1: "EVC-Temperatursensor 1",
  EVC_INPUT_REG_TEMP_2: "EVC-Temperatursensor 2",
  EVC_INPUT_REG_TEMP_3: "EVC-Temperatursensor 3",
  EVC_INPUT_REG_SUBCOOL_TEMP: "Unterkollegen Temperatur",
  EVC_INPUT_REG_HIGH_SIDE_PRESSURE: "HSP",
  EVC_INPUT_REG_LOW_SIDE_PRESSURE: "LSP",
  EVC_INPUT_REG_HI_SAT_TEMP: "Hohe Sättigungstemperatur",
  EVC_INPUT_REG_LO_SAT_TEMP: "Niedrige Sättigungstemperatur",
  EVC_INPUT_REG_FAN_OUTPUT: "Lüftergeschwindigkeit",
  HPF_INPUT_REG_TEMP1: "Tanktemperatur",
  HPF_INPUT_REG_TEMP2: "Temperatur der Milchpipeline",
  HPF_INPUT_REG_TEMP3: "Temperatur 3",
  HPF_INPUT_REG_TEMP4: "Temperatur 4",
  HPF_INPUT_REG_VOLUME_4TO20MA_READING: "Tankvolumen",
};
