import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Container, Image } from "react-bootstrap";
import Error from "../assets/images/Infographic.svg";

const ErrorPage = memo(() => {
  const { t } = useTranslation();

  return (
    <Container fluid className="pt-5 mt-5">
      <Row className="justify-content-center m-5">
        <Col sm={6} md={6} lg={6}>
          <Image src={Error} fluid />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm={6} md={6} lg={4}>
          <Col>
            <h5 className="text-secondary text-capitalize text-center mt-4 font-weight-bold">
              {t("page_not_found")}
            </h5>
          </Col>

          <Col className="mt-5">
            <Button
              href="/login"
              variant="primary"
              size="lg"
              block
              type="submit"
            >
              {t("home")}
            </Button>
          </Col>
        </Col>
      </Row>
    </Container>
  );
});

export default ErrorPage;
