import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as EnvelopeIcon } from "../assets/images/Icon material-email.svg";
import { Col, Row, Container, Form, Button, Image } from "react-bootstrap";
import MuellerLogo from "../assets/images/Mueller_dark_logo.svg";
import MilkSecureLogo from "../assets/images/MilkSecure_Logo_Two_Color.svg";
import Slider from "../components/Slider";

import PrivacyPolicyLinks from "../components/PrivacyPolicyLinks";
import LanguageDropdown from "../components/LanguageDropdown";

const ContactAdministrator = memo(() => {
  const { t } = useTranslation();
  return (
    <div className="bg-login-light min-vh-100">
      <Container fluid="xl" fluid className="min-vh-100">
        <Row className="min-vh-100">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            xl={6}
            className="d-none d-sm-none d-md-none bg-dark-secondary align-items-start d-lg-flex justify-content-lg-start justify-content-xl-center"
          >
            <Slider />
          </Col>
          <Col
            className="m-xl-auto mx-lg-auto"
            xs={{ span: 10, offset: 1 }}
            sm={{ span: 8, offset: 2 }}
            md={{ span: 8, offset: 2 }}
            lg={{ span: 4, offset: 2 }}
            xl={{ span: 4, offset: 2 }}
          >
            <div className="text-center my-lg-4 my-md-5  logo-top">
              <Image src={MuellerLogo} fluid className="mueller-logo" />
            </div>
            <div className="shadow bg-white px-lg-5 py-lg-5 px-md-4 py-md-4 px-4 py-4 my-5">
              <p className="text-center pb-3 text-dark">
                {t("contact_administrator_text")}
              </p>
              <p>
                <a
                  className="btn btn-primary btn-lg mt-4 text-capitalize btn-block"
                  href="mailto:dfetechservice@paulmueller.com"
                >
                  <EnvelopeIcon className="mr-2 mb-1" />
                  {t("contact_administrator")}
                </a>
              </p>
              <Link to="/">
                <Button
                  className="mt-4 mb-2 back-arrow-hover border-primary text-primary"
                  variant="outline-none"
                  size="lg"
                  type="button"
                  block
                >
                  {t("back_to_login")}
                </Button>
              </Link>
            </div>
            <div className="text-center mt-lg-5 mt-3 mb-2">
              <p className="text-capitalize mb-3">{t("powered_by")}</p>
              <Image src={MilkSecureLogo} />
            </div>
            <div className="mt-4 text-center">
              <PrivacyPolicyLinks />
            </div>
            <div className="mt-0 text-center">
              <LanguageDropdown />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

export default ContactAdministrator;
