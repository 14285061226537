import React, { memo, useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import DairyCard from "../../components/DairyCard";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  deleteDairy,
  DisplayDairyData,
  getUser,
  fetchUsers,
} from "../../actions/actions";
import Search from "../../components/Search";
import PromptModel from "components/PromptModel/PromptModel";
import AddDairyFarmForm from "../../forms/DairyForm/AddDairyFarmForm";
import EditDairyFarmForm from "../../forms/DairyForm/EditDairyFarmForm";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import CardViewLoader from "../../components/CardViewLoader";
import { useBreadcrumbContext } from "../../context/breadcrumbContext";

const DairyFarmTab = memo(() => {
  const { t } = useTranslation();
  const { dealerID } = useParams();
  const [show, setShow] = useState(false);
  /* set searchResult data with useState */
  const [dairyFarms, setDairyFarms] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectId, setSelectedId] = useState(null);
  const [display, setDisplay] = useState(false);
  const [user, setUser] = useState(null);
  const [dairyData, setDairyData] = useState([]);
  /* set searchTerm data with useState */
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();
  const [cardViewLoader, setCardViewLoader] = useState(false);
  const { setBreadcrumbDairyFarm } = useBreadcrumbContext();

  const users = getUser();
  /** Display Region Listing */
  const getDairyFarmListing = () => {
    setCardViewLoader(true);
    if (
      users.role === "DairyOperator" ||
      users.role === "Sanitarian" ||
      users.role === "MilkHauler" ||
      users.role === "DairyAdmin"
    ) {
      fetchUsers({ id: users.id })
        .then((response) => {
          setCardViewLoader(false);
          setDairyFarms(response?.data[0]?.farmUserOf);
        })
        .catch((err) => {
          toast.dark(
            <Toaster
              icon="error"
              message={err?.response?.message ?? t("something_went_wrong")}
            />
          );
        });
    } else {
      DisplayDairyData(dealerID)
        .then((response) => {
          setCardViewLoader(false);
          setDairyFarms(response.data);
        })
        .catch((err) => {
          toast.dark(
            <Toaster
              icon="error"
              message={err?.response?.message ?? t("something_went_wrong")}
            />
          );
        });
    }
  };
  /** Delete Region api call */
  const deleteRecord = (id) => {
    deleteDairy(id)
      .then((response) => {
        if (response.status === 200) {
          setSelectedId(null);
          setOpen(false);
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("dairy_deleted_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
          getDairyFarmListing();
        }
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response?.message ?? t("something_went_wrong")}
          />
        );
      });
  };

  useEffect(() => {
    getDairyFarmListing();
  }, [dealerID]);
  /** Added and update data after update state data */

  const updatedList = (item, action) => {
    if (action === "add") {
      setDairyFarms([item, ...dairyFarms]);
    }
    if (action === "edit") {
      setDairyFarms(dairyFarms.map((i) => (i.id === item.id ? item : i)));
    }
  };

  const results = dairyFarms.filter((card) => {
    return card.name.toLowerCase().includes(searchTerm.toLowerCase()) || (card.county ? card.county.toLowerCase().includes(searchTerm.toLowerCase()) : null);
  });

  return (
    <>
      <Row>
        <Col xs={12} lg={3} md={8} sm={7} className="mb-4">
          <Search
            placeholder={t("search")}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Col>

        {users.role === "Technician" ||
          users.role === "DairyOperator" ||
          users.role === "DairyAdmin" ||
          users.role === "Sanitarian" ||
          users.role === "MilkHauler" ? (
            ""
          ) : (
            <Col
              xs={12}
              lg={9}
              md={4}
              sm={5}
              className="d-inline-flex justify-content-end mb-4"
            >
              <Button
                className="text-capitalize"
                variant="primary"
                onClick={() => setShow(true)}
              >
                {t("add_new_dairy_farm")}
              </Button>
            </Col>
          )}
      </Row>
      {cardViewLoader ? (
        <CardViewLoader />
      ) : (
          results.length < 1 ? (
            <div className="text-center lead">{t("data_not_available")}</div>
          ) : (
              <Row>
                {results.map((item, index) => {
                  return (
                    <Col lg={3} md={4} className="mb-4" key={index}>
                      <DairyCard
                        dairy={item}
                        key={"dairy" + item.id}
                        users={users}
                        editHandlerEvent={(dairyId) => {
                          setDisplay(true);
                          setUser(dairyId);
                          setDairyData(item);
                        }}
                        deleteHandlerEvent={(dairyId) => {
                          setSelectedId(dairyId);
                          setOpen(true);
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            )
        )}

      {show && (
        <AddDairyFarmForm
          showModel={show}
          closeModel={() => setShow(false)}
          updateList={updatedList}
        />
      )}
      {display && (
        <EditDairyFarmForm
          showModel={display}
          closeModel={() => setDisplay(false)}
          dairyId={user}
          updateList={updatedList}
          dairyData={dairyData}
        />
      )}
      <PromptModel
        open={open}
        closeAlert={() => {
          setOpen(false);
        }}
        modelContent={t("delete_record_msg")}
        callback={() => deleteRecord(selectId)}
      />
    </>
  );
});

export default DairyFarmTab;
