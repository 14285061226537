import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PropTypes from "prop-types";

const NoTankData = styled.div`
  margin-top: 20px;
  padding: 20px 10px 20px 10px;
  border-radius: 10px;
  border: solid 2px #d6e6ff;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NoDataAvailable = memo(({ title }) => {
  const { t } = useTranslation();
  return (
    <NoTankData>
      <span>{title}</span>
    </NoTankData>
  );
});

NoDataAvailable.propTypes = {
  /** No available title name */
  title: PropTypes.string,
};
NoDataAvailable.defaultProps = {
  title: "",
};
export default NoDataAvailable;
