import React, { memo } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";

const GlobalInput = styled.div`
   {
    .react-tel-input .form-control {
      width: 80%;
      height: 45px;
      border: 1px solid #e2e2e2;
      border-radius: 4px;
      &:focus {
        border: 1px solid #76a2da;
      }
    }
    .react-tel-input .flag-dropdown {
      width: 15%;
      border: 1px solid #e2e2e2;
      border-radius: 4px;
    }
    .react-tel-input .selected-flag {
      padding: 0 0 0 13px;
    }
    .react-tel-input .country-list .country {
      &:focus {
        outline: none;
      }
    }
  }
`;

const CountryPhoneInput = memo(
  ({ phoneInputValue, onChangeVal, inputRef, onKeyDown }) => {
    return (
      <GlobalInput>
        <PhoneInput
          prefix={"+"}
          containerClass="phone d-inline-block"
          inputClass="contact-number-field float-right pl-3"
          buttonClass="rounded country-flag-icon mr-3 bg-white"
          enableSearch
          preferredCountries={["us", "ca", "jp"]}
          preserveOrder={["preferredCountries"]}
          excludeCountries="al"
          value={phoneInputValue}
          onChange={onChangeVal}
          name="contact"
          inputRef={inputRef}
          countryCodeEditable={false}
          onKeyDown={onKeyDown}
        />
      </GlobalInput>
    );
  }
);

CountryPhoneInput.propTypes = {
  /** Phone field value */
  phoneInputValue: PropTypes.string,
  /** Onchange phone field function */
  onChangeVal: PropTypes.func.isRequired,
  inputRef: PropTypes.any,
  onKeyDown: PropTypes.func,
};

CountryPhoneInput.defaultProps = {
  inputRef: "",
  onChangeVal: () => {},
};

export default CountryPhoneInput;
