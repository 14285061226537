import React, { memo } from "react";
import { Row, Col } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const tmparray = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
const TableViewLoader = memo(() => {
  return (
    <tbody className="mb-2">
      {tmparray.map(element => {
        return (

          <tr>
            <td style={{ maxWidth: "150px" }}>
              <Skeleton count={1} height={25} width={150} /></td>
            <td style={{ maxWidth: "150px" }}><Skeleton count={1} height={25} width={150} /></td>
            <td style={{ maxWidth: "150px" }}> <Skeleton count={1} height={25} width={150} /></td>
            <td style={{ maxWidth: "150px" }}> <Skeleton count={1} height={25} width={400} /></td>
          </tr>

        )
      })}


    </tbody>
  );
});

export default TableViewLoader;