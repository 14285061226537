import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  Spinner,
  Image,
  Badge,
} from "react-bootstrap";
import * as yup from "yup";
import EyeIcon from "../assets/images/BlueEyesIcon.svg";
import CloseEyeIcon from "../assets/images/close-eye-icon.svg";
import { changePassword } from "../actions/actions";
import { toast } from "react-toastify";
import Toaster from "../components/Toaster";
import BackArrow from "../assets/images/back-arrow.svg";

import { useHistory } from "react-router";

const ChangedPasswordPage = memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [spinner, setSpinner] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState(false);
  const [reEnterPassword, setReEnterPassword] = useState(false);

  const PASSWORD_REGEXP = new RegExp(
    "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=])(?=\\S+$).{8,16}$"
  );

  const schema = yup.object().shape({
    oldPassword: yup
      .string()
      .required(t("password_required"))
      .min(8, t("password_min")),
    password: yup
      .string()
      .required(t("new_password_required"))
      .min(8, t("password_min"))
      .matches(PASSWORD_REGEXP, t("password_regex_message")),
    rePassword: yup
      .string()
      .required(t("re_enter_new_password_required"))
      .min(8, t("password_min"))
      .oneOf([yup.ref("password")], t("password_not_match")),
  });

  const { register, handleSubmit, errors, reset } = useForm({
    validationSchema: schema,
    mode: "onSubmit",
  });

  const postChangedPassword = (data) => {
    if (data.oldPassword !== "") {
      setSpinner(true);
      changePassword({ oldPassword: data.oldPassword, password: data.password })
        .then((result) => {
          setSpinner(false);
          if (result.status === 200) {
            reset();
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={result.data.msg}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
          }
          history.push("/");
        })
        .catch((e) => {
          toast.dark(
            <Toaster
              icon="error"
              message={e?.result?.message ?? t("current_password_wrong")}
            />,
            {
              autoClose: 3000,
              hideProgressBar: true,
            }
          );
          setSpinner(false);
        });
    }
  };
  return (
    <Container>
      <Row className="mt-4 mb-3">
        <Col lg={12} className="pl-0">
          <Badge
            className="px-3 py-2 back-arrow-hover"
            onClick={history.goBack}
          >
            <Image src={BackArrow} />
          </Badge>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col
          className="my-auto"
          xs={{ span: 10, offset: 1 }}
          sm={{ span: 8, offset: 2 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 6, offset: 3 }}
        >
          <div className="px-lg-2 px-xs-4">
            <div className="lead mb-4 pb-2 text-primary font-weight-bold text-capitalize">
              {t("change_password")}
            </div>
            <Form className="mb-5" onSubmit={handleSubmit(postChangedPassword)}>
              <Form.Group controlId="formBasicPassword">
                <Form.Label className="text-capitalize" size="lg">
                  {t("old_password")}
                </Form.Label>
                <Form.Control
                  name="oldPassword"
                  size="lg"
                  type={oldPassword === true ? "text" : "password"}
                  ref={register()}
                  isInvalid={!!errors.oldPassword}
                />
                <Button
                  variant="link"
                  className="eye-icon"
                  aria-label="Eye Icon"
                  onClick={() => setOldPassword(!oldPassword)}
                >
                  {oldPassword === false ? (
                    <Image src={EyeIcon} />
                  ) : (
                      <Image src={CloseEyeIcon} />
                    )}
                </Button>
                {errors.oldPassword && (
                  <Form.Control.Feedback type="invalid">
                    {errors.oldPassword.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label className="text-capitalize" size="lg">
                  {t("new_password")}
                </Form.Label>
                <Form.Control
                  name="password"
                  size="lg"
                  type={isPassword === true ? "text" : "password"}
                  ref={register()}
                  isInvalid={!!errors.password}
                />
                <Button
                  variant="link"
                  className="eye-icon"
                  aria-label="Eye Icon"
                  onClick={() => setIsPassword(!isPassword)}
                >
                  {isPassword === false ? (
                    <Image src={EyeIcon} />
                  ) : (
                      <Image src={CloseEyeIcon} />
                    )}
                </Button>
                {errors.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label className="text-capitalize" size="lg">
                  {t("re_enter_new_password")}
                </Form.Label>
                <Form.Control
                  name="rePassword"
                  size="lg"
                  type={reEnterPassword === true ? "text" : "password"}
                  ref={register()}
                  isInvalid={!!errors.rePassword}
                />
                <Button
                  variant="link"
                  className="eye-icon"
                  aria-label="Eye Icon"
                  onClick={() => setReEnterPassword(!reEnterPassword)}
                >
                  {reEnterPassword === false ? (
                    <Image src={EyeIcon} />
                  ) : (
                      <Image src={CloseEyeIcon} />
                    )}
                </Button>
                {errors.rePassword && (
                  <Form.Control.Feedback type="invalid">
                    {errors.rePassword.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Button
                className="mt-5 text-capitalize"
                variant="primary"
                size="lg"
                type="submit"
                block
              >
                {spinner ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                  </>
                ) : (
                    t("reset_password")
                  )}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
});

export default ChangedPasswordPage;
